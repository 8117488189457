import React, {useState} from 'react';
import AlertMessage, {AlertMessageInterface} from '../../presentations/AlertMessage';
import {useTranslation} from 'react-i18next';

const AlertUnknownErrorMessage: React.FC = () => {
  const {t}       = useTranslation();
  const [message] = useState<AlertMessageInterface>({
    level: 'error',
    body : t('A problem has occurred. Please try again in a few minutes.')
  });

  return (
          <AlertMessage {...message}/>
  );
}

export default AlertUnknownErrorMessage;
