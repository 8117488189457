import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface ModalConfirmBodyInterface {
  children: React.ReactNode
}

const ModalConfirmBody: React.FC<ModalConfirmBodyInterface> = (props) => {
  return (
          <Wrapper>{props.children}</Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 20px 0;
  font-weight: bold;
  ${fontSize(18)}
`;

export default ModalConfirmBody;
