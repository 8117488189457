import React, {useEffect} from 'react';
import Header from '../presentations/Header';
import FullScreenContainer from '../presentations/FullScreenContainer';
import ForgotPasswordForm from '../containers/ForgotPasswordForm';
import Cookies from 'js-cookie';
import store from '../stores';
import {loggedOut} from '../stores/authenticated';
import {useTranslation} from 'react-i18next';

const ForgotPassword = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${t('Forgot your password')}`;
  }, []);

  return (
          <>
            <Header/>

            <FullScreenContainer centering={true}>
              <main>
                <ForgotPasswordForm/>
              </main>
            </FullScreenContainer>
          </>
  );
};

export default ForgotPassword;
