import * as React from 'react';
import {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/reject-message-modal';
import RejectMessage from './RejectMessage';
import ModalBody from '../../presentations/ModalBody';
import Padding from '../../presentations/Padding';
import Button from '../../presentations/Button';
import ModalFooterSimple from '../../presentations/ModalFooterSimple';
import ModalHeaderSimple from '../../presentations/ModalHeaderSimple';

const RejectMessageModal: React.FC = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.rejectMessageModal.open);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <>
              <ModalHeaderSimple>拒否</ModalHeaderSimple>
              <ModalBody>
                <Padding padding={'20px'}>
                  <RejectMessage/>
                </Padding>
              </ModalBody>
              <ModalFooterSimple>
                <Button type={'button'} color={'secondary'} onClick={handleClose}>閉じる</Button>
              </ModalFooterSimple>
            </>
          </Modal>
  );
}

export default RejectMessageModal;

