import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface ModalHeaderSimpleInterface {
  children: React.ReactElement | React.ReactNode
}

const ModalHeaderSimple: React.FC<ModalHeaderSimpleInterface> = ({children}) => {
  return (
          <Wrapper>{children}</Wrapper>
  );
}


export const Wrapper = styled.header`
  padding: 20px 20px 30px;
  ${fontSize(20)};
  font-weight: 700;
`;

export default ModalHeaderSimple;
