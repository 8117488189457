import React, {useEffect, useState} from 'react';
import {flatMap, map} from 'lodash-es';
import AlertMessage, {AlertMessageInterface} from '../../presentations/AlertMessage';
import {useTranslation} from 'react-i18next';

export interface AlertValidationErrorMessageInterface {
  message: any
}

const AlertValidationErrorMessage: React.FC<AlertValidationErrorMessageInterface> = (props) => {
  const [message, setMessage] = useState<AlertMessageInterface>({});
  const {t}                   = useTranslation();

  useEffect(() => {
    const errors = flatMap(props.message, message => {
      return map(message.constraints, msg => {
        return `・${t(msg)}`;
      });
    });

    setMessage({
      level: 'error',
      body : errors.join('\n')
    })

  }, [props.message]);

  return (
          <AlertMessage {...message}/>
  );
}

export default AlertValidationErrorMessage;
