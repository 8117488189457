import React, {useEffect} from 'react';
import ModalHeaderSimple from './ModalHeaderSimple';
import styled from 'styled-components';
import Button from './Button';
import Spinner1 from './Spinner1';
import Avatar from './Avatar';
import {SendingTalkingRequestInterface as SendingTalkingRequestWebsocketInterface} from '../stores/sending-talking-request-modal';
import {fontSize} from '../components/utility/Mixin';
import {Sound} from '../services/sound';

interface SendingTalkingRequestInterface {
  onClose: () => void
  sound?: boolean
  data?: SendingTalkingRequestWebsocketInterface | null
}

const SendingTalkingRequest: React.FC<SendingTalkingRequestInterface> = (props) => {
  const getName = () => {
    return `${props.data?.guestUser.firstName} ${props.data?.guestUser.lastName}`;
  }

  useEffect(()=>{
    if(props.sound) {
      Sound.outgoing.play();

      return () => {
        Sound.outgoing.stop();
      }
    }
  }, []);

  return (
          <>
            <ModalHeaderSimple>ビデオ通話 発信中</ModalHeaderSimple>
            <Loading><Spinner1/></Loading>
            <Body>
              {props.data?.guestUser ? <Avatar user={props.data.guestUser} size={56}/> : null}
              <Text>
                {getName()}さんに話しかけています。<br/>
                <Sub>相手が応答すると通話が開始されます。</Sub>
              </Text>
            </Body>
            <Action>
              <Button type={'button'} color={'danger'} onClick={props.onClose}>中止</Button>
            </Action>
          </>
  )
}

SendingTalkingRequest.defaultProps = {
  sound: true,
}

const Loading = styled.div`
  padding-bottom: 30px;
  text-align: center;
`;

const Body = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin-left: 10px;
  ${fontSize(18)};
  font-weight: 700;
`;

const Sub = styled.small`
  margin-top: 10px;
  display: block;
  ${fontSize(12)};
  font-weight: 400;
`;

const Action = styled.div`
  margin-top: 25px;
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

export default SendingTalkingRequest;
