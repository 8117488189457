import React from 'react';
import styled from 'styled-components';

interface RoomLockedStatusInterface {
  isLocked: boolean
}

const RoomLockedStatus: React.FC<RoomLockedStatusInterface> = (props) => {
  return (
          <>
            {props.isLocked && <Wrapper><i className={'soi-lock'}></i></Wrapper>}
          </>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: -4px;
  top: -4px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #F0B000;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export default RoomLockedStatus;
