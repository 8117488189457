import React, {ChangeEvent, useEffect, useState} from 'react';
import styled from 'styled-components';

// @ts-ignore
import loadImage from 'blueimp-load-image';

interface FormSelectPreviewInterface {
  name: string
  preview?: string
  register: any
}

const FormSelectPreview: React.FC<FormSelectPreviewInterface> = (props) => {
  const [preview, setPreview] = useState(props.preview);

  useEffect(() => {
    console.log(props.preview);
    setPreview(props.preview);
  }, []);

  const handleChangeAvatar = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as FileList;

    if (files.length) {
      const options = {
        canvas  : true,
        maxWidth: 600,
      };

      loadImage(files[0], options).then((data: any) => {
        setPreview(data.image.toDataURL('image/jpeg'))
      }).catch((error: any) => {
        console.log(error);
      });
    }
  }

  return (
          <>
            {preview && <Preview preview={preview}/>}
            <Wrapper>
              <>
                選択
                <Input type="file" name={props.name} accept="image/*" ref={props.register} onChange={handleChangeAvatar}/>
              </>
            </Wrapper>
          </>
  );
};


const Wrapper = styled.label`
  padding: .8em 1.5em;
  min-width: 6.75em;
  max-width: 100%;
  background-color: #F9F9FA;
  border-radius: calc(1em + .96em);
  border: 1px solid #D5D5D5;
  display: inline-block;
  align-items: baseline;
  justify-content: center;
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    background-color: #fff;
  }
`;

const Input = styled.input`
  display: none;
`;

const Preview = styled.div<{ preview?: string }>`
  margin-bottom: 10px;
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.preview});
`;

export default FormSelectPreview;
