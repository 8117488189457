import React, {useEffect, useRef, useState} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/room-password-modal';
import ModalHeaderSimple from '../../presentations/ModalHeaderSimple';
import ModalBody from '../../presentations/ModalBody';
import Button from '../../presentations/Button';
import ModalFooter from '../../presentations/ModalFooter';
import RoomPasswordForm from './RoomPasswordForm';
import Padding from '../../presentations/Padding';
import Repository from '../../services/repository';
import {AxiosError, AxiosResponse} from 'axios';
import {unlock} from '../../stores/room-password';
import AlertMessage from '../../presentations/AlertMessage';

const customStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
    zIndex         : 1000,
  },
  content: {
    padding     : '0',
    width       : 'calc(100% - 30px)',
    maxWidth    : '350px',
    height      : 'auto',
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    transform   : 'translate(-50%, -50%)',
    border      : 'none',
    borderRadius: '6px',
    boxShadow   : '0 4px 20px rgba(0, 0, 0, .32)',
  }
}

const RoomPasswordModal = () => {
  const ref: any                     = useRef();
  const formRef: any                 = useRef();
  const open                         = useSelector((state: AppState) => state.roomPasswordModal.open);
  const {participantId, moderatorId} = useSelector((state: AppState) => state.roomPasswordModal);
  const roomId                       = Number(new URLSearchParams(document.location.search).get('roomId') as string);
  const roomPassword                 = useSelector((state: AppState) => state.roomPassword);
  const userId                       = useSelector((state: AppState) => state.authenticated.userId);

  useEffect(() => {
    if (open) {
      ref.current.open();
      setTimeout(() => {
        formRef?.current?.input?.focus();
      });
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  const handleSubmit = () => {
    formRef.current.submitButton.click();
  }

  const handleDelete = () => {
    formRef.current.deleteButton.click();
  }

  const isHost = () => {
    return participantId === moderatorId;
  }

  return (
          <Modal ref={ref} style={customStyle} onClose={handleClose}>
            <>
              <ModalHeaderSimple>パスワードの設定</ModalHeaderSimple>
              <ModalBody>
                <Padding padding={'0 20px 20px'}>
                  <>
                    {isHost() ? <RoomPasswordForm ref={formRef} roomId={roomId}/> : '権限がありません'}
                  </>
                </Padding>
              </ModalBody>
              <ModalFooter>
                <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} onClick={handleClose}>閉じる</Button>
                {isHost() && <>
                  {roomPassword.isLocked ?
                          <Button type={'button'} color={'danger'} width={'130px'} onClick={handleDelete}>削除</Button> :
                          <Button type={'button'} color={'secondary'} width={'130px'}
                                  onClick={handleSubmit}>設定</Button>}
                </>}
              </ModalFooter>
            </>
          </Modal>
  );
};

export default RoomPasswordModal;
