import styled from 'styled-components';
import React, {ForwardRefExoticComponent, useImperativeHandle, useState} from 'react';
import {fontSize} from '../components/utility/Mixin';
import OutsideClickHandler from 'react-outside-click-handler';

interface DropdownInterface {
  children: React.ReactElement[]
  ref?: any
}

const Dropdown: ForwardRefExoticComponent<DropdownInterface> = React.forwardRef((props, ref) => {
  const [show, changeShow] = useState(false);

  const handleToggle = () => {
    changeShow(!show);
  };

  const handleClose = () => {
    changeShow(false);
  }

  useImperativeHandle(ref, () => {
    return {
      close: handleClose
    };
  });

  return (
          <div ref={ref} {...props}>
            <OutsideClickHandler onOutsideClick={handleClose}>
              <Wrapper>
                {React.cloneElement(props.children[0], {onClick: handleToggle})}
                <DropdownMenuWrapper data-show={show}>
                  {props.children[1]}
                </DropdownMenuWrapper>
              </Wrapper>
            </OutsideClickHandler>
          </div>
  );
});

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const DropdownTrigger = styled.button`
  min-height: 30px;
  display: flex;
  align-items: center;
  
  &::after {
    margin-left: .5em;
    font-family: 'smart-o';
    content: '\\e90b';
  }
`;

const DropdownMenuWrapper = styled.div`
  &:not([data-show='true']){
    display: none;
  }
`;

export const DropdownMenu = styled.div`
  width: 180px;
  background-color: #fff;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  position: absolute;
  top: 100%;
  left: 0;
`;

export const DropdownMenuItem = styled.button`
  padding: 10px 30px;
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;
  ${fontSize(12)};
  color: #262F40;
  font-weight: 700;
  line-height: 1.3;

  &[data-selected='true'],
  &:hover {
    background-color: #4E6693;
    color: #fff;
  }
  
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export default Dropdown;
