import React, {useContext, useEffect} from 'react';
import PageContainer from '../presentations/PageContainer';
import SystemManagerHeader from '../presentations/SystemManagerHeader';
import SystemManagerMenu from '../presentations/SystemManagerMenu';
import SystemManagerLayout from '../presentations/SystemmanagerLayout';
import styled from 'styled-components';
import Button from '../presentations/Button';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import TableSimple from '../presentations/Table/TableSimple';
import {each, filter, orderBy} from 'lodash-es';
import SystemManagerMainLayout from '../presentations/SystemManagerMainLayout';
import {PopupContext} from '../containers/PopupStoreContext';
import {UserType} from '../stores/authenticated';
import SystemManagerRecordAction from '../presentations/SystemManagerRecordAction';
import SelectList, {SelectListItem} from '../presentations/SelectList';
import {open as openModalConfirmUserProfileRemove} from '../stores/modal-confirm-user-profile-remove';
import UserProfileRemoveConfirmModal from '../containers/UserProfileRemoveConfirmModal';
import UserProfileCreaterModal from '../containers/UserProfileCreaterModal';
import {open as openUserProfileCreaterModal} from '../stores/user-profile-creater-modal';
import Avatar from '../presentations/Avatar';

interface SystemManagerUsersInterface {
  match: any
}

const SystemManagerUsers: React.FC<SystemManagerUsersInterface> = (props) => {
  const systemName = useSelector((state: AppState) => state.system.systemName);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${systemName}`;
  }, [systemName]);

  return (
          <PageContainer>
            <>
              <SystemManagerHeader/>
              <SystemManagerLayout sidebar={<SystemManagerMenu match={props.match}/>}
                                   main={<SystemManagerUserMain match={props.match}/>}/>
              <UserProfileRemoveConfirmModal/>
              <UserProfileCreaterModal/>
            </>
          </PageContainer>
  );
};

export default SystemManagerUsers;

interface SystemManagerUserMainInterface {
  match: any
}

const SystemManagerUserMain: React.FC<SystemManagerUserMainInterface> = (props) => {
  return (<SystemManagerMainLayout header={<SystemManagerUserMainHeader/>}
                                   body={<SystemManagerUserMainBody match={props.match}/>}/>);
}

const SystemManagerUserMainHeader: React.FC = () => {
  const handleOpen = () => {
    store.dispatch(openUserProfileCreaterModal(null));
  }

  return (
          <>
            <Button icon={'soi-plus'} fontSize={14} onClick={handleOpen}>ユーザーを新規作成</Button>
          </>
  )
}

interface SystemManagerUserMainInterface {
  match: any
}

const SystemManagerUserMainBody: React.FC<SystemManagerUserMainInterface> = (props) => {
  let users               = orderBy(useSelector((state: AppState) => state.users), ['id'], ['asc']);
  const [, setPopupState] = useContext(PopupContext);

  if (props.match.params.departmentId) {
    users = filter(users, user => {
      return Number(user.department.id) === Number(props.match.params.departmentId);
    });
  }

  const handleProfileAction = (event: React.MouseEvent<HTMLButtonElement>, user: UserType) => {
    event.persist();

    const list = <SelectList>
      <>
        <SelectListItem onClick={() => handleEditProfile(user)} icon={'soi-edit'}>編集</SelectListItem>
        <SelectListItem onClick={() => handleRemoveProfile(user)} icon={'soi-delete'}>削除</SelectListItem>
      </>
    </SelectList>;

    setPopupState(() => {
      return {
        open    : true,
        children: <SystemManagerRecordAction list={list}/>,
        event   : event,
        design  : 'simple',
        width   : 'auto'
      }
    });
  }

  const handleEditProfile = (user: UserType) => {
    store.dispatch(openUserProfileCreaterModal(user));
  }

  const handleRemoveProfile = (user: UserType) => {
    store.dispatch(openModalConfirmUserProfileRemove(user));
  }

  // const handleEditDepartment = () => {
  //
  // }
  //
  // const handleEditRole = () => {
  //
  // }

  const createRows = () => {
    const rows: React.ReactElement[] = [];
    each(users, (user, index) => {
      rows.push(<tr key={index}>
        <td>
          <Edit type={'button'} onClick={(event) => handleProfileAction(event, user)}>
            <UserIcon><Avatar user={user} size={34} showStatus={false} /></UserIcon>
          </Edit>
        </td>
        <td>
          <Edit type={'button'} onClick={(event) => handleProfileAction(event, user)}>{user.firstName} {user.lastName}</Edit>
        </td>
        <td>{user.username}</td>
        <td>{user.department.title}</td>
        <td>{user.roles[0].name}</td>
        {/*<td><Edit type={'button'} onClick={handleEditDepartment}>{user.department.title}</Edit></td>*/}
        {/*<td><Edit type={'button'} onClick={handleEditRole}>{user.roles[0].name}</Edit></td>*/}
      </tr>);
    });

    return rows;
  }

  return (
          <TableSimple>
            <>
              <colgroup>
                <col width={34} span={1}/>
                <col width={250} span={1}/>
                <col width={200} span={1}/>
                <col width={200} span={1}/>
              </colgroup>
              <thead>
              <tr>
                <th colSpan={2}>表示名</th>
                <th>ユーザーID</th>
                <th>部署</th>
                <th>権限</th>
              </tr>
              </thead>
              <tbody>
              {createRows()}
              </tbody>
            </>
          </TableSimple>
  );
};

const UserIcon = styled.div`
  margin-top: -10px;
  margin-bottom: -10px;
  max-width: none;
`;

const Edit = styled.button`
  text-decoration: underline;
`;
