import * as React from 'react';
import styled from 'styled-components';
import {UserType} from '../../stores/authenticated';

interface MyProfileAvatarSelectPreviewInterface {
  user: UserType
  avatar: string
  onClick?: () => void
}

const MyProfileAvatarSelectPreview: React.FC<MyProfileAvatarSelectPreviewInterface> = (props) => {
  const getName = () => {
    if (props.avatar) {
      return null;
    } else {
      return `${props.user.firstName.charAt(0)}${props.user.lastName.charAt(0)}`;
    }
  }

  return (
          <Wrapper onClick={props.onClick}>
            <Preview image={props.avatar}>{getName()}</Preview>
          </Wrapper>
  );
}

const getPointer = (onClick: any) => {
  if (onClick) {
    return `cursor: pointer;`;
  } else {
    return ``;
  }
}

const Wrapper = styled.div`
  position: relative;
  ${props => getPointer(props.onClick)}
`;

const Preview = styled.div<{ image?: string }>`
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #7759F4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image});
  box-shadow: inset 0 0 0 1px #D5D5D5, 0 1px 2px rgba(0, 0, 0, .16);
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  color: #fff;
  font-size: ${120/2.5}px;
  line-height: ${120}px;
  white-space: nowrap;
  text-align: center;
`;

export default MyProfileAvatarSelectPreview;

