import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type Data = {
  title: string
  roomId: string
}

export interface RoomShareModalInterface {
  open: boolean
  data: Data | null
}

// Stateの初期状態
const initialState: RoomShareModalInterface = {
  open: false,
  data: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'room-share-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<Data>) => {
      return {...state, open: true, data: action.payload};
    },
    close: (state) => {
      return {...state, open: false, data: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
