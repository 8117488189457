import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SelectOptionInterface} from '../types/select-option.interface';

export interface UserStatusFilterInterface {
  options: SelectOptionInterface[]
  selected: SelectOptionInterface
}

const options: SelectOptionInterface[] = [
  {id: 'all', name: 'All'},
  {id: 'login', name: 'Login'},
  {id: 'logout', name: 'Logout'},
  {id: 'privacy', name: 'Privacy'},
];

// Stateの初期状態
const initialState: UserStatusFilterInterface = {
  options,
  selected: options[0]
};

// Sliceを生成する
const slice = createSlice({
  name    : 'user-status-filter',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<SelectOptionInterface>) => {
      return {...state, selected: action.payload};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {select} = slice.actions;
