import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../components/utility/Mixin';
import {SelectOptionInterface} from '../../types/select-option.interface';
import {map} from 'lodash-es';

interface FormSelectProps {
  options: SelectOptionInterface[]
  defaultValue?: string | undefined
  name: string
  value: string
  register?: any
  error?: any
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  width?: 'full' | 'auto'
}

export const FormSelect = (props: FormSelectProps) => {
  const hasError = (error: any) => {
    return error !== 'false';
  }

  const options = map(props.options, (option, index) => {
    return <option key={index} value={option.id}>{option.name}</option>;
  })

  return (
          <>
            <Select name={props.name}
                    ref={props.register}
                    data-error={hasError(props.error)}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    data-width={props.width}
            >{options}</Select>
          </>
  );
};

// Propsのデフォルト値
FormSelect.defaultProps = {
  type       : 'text',
  name       : '',
  value      : '',
  placeholder: '',
  error      : 'false',
  width      : 'full'
};

const Select = styled.select`
  padding: 2px 30px 0 10px;
  max-width: 100%;
  height: 40px;
  background: #F9F9FA url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFKADAAQAAAABAAAADQAAAAD85Sc0AAABHElEQVQ4EWNkgAJVbQcDBhaG+YwMDAYwMWLo/wwMFxj+MCTevnrgAkg9E0wTIwvDAlINA+kF6wE6BGYO3ECYADk00FAgggCEgf8YGmCCJNNIeplhmt++enBDSFxBkJGRwQImRgz9/z/DxNuXD0yAqYU7FSagqudwAGioPYyPjwYadvD2pQMOyGoQXoaK/mZiCADG3EdkRdjYIDUgtehyGAY+uHDgw/+/mArRNYLUgNSii8PDEFni3asHDwTFFT4Cve6BLA5j//vPUHjn8oEVMD4yjRGGyJJqeg4bgAnCH1mM4T/DxluXDmB4FaYGw8swCRD9i4khAUg9BLGh4CFUDMbHoPG6EKRawcBBgPUfAzhZACOhAFu4YZhKTQEA4ZdLWxqY1wUAAAAASUVORK5CYII=') no-repeat right 10px center;
  background-size: 10px auto;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  ${fontSize(14)};
  text-align: left;
  ::placeholder {
    color: rgba(38, 47, 64, 0.3);
  }
  &[data-error="true"]{
    border: 2px solid #EA4949;
  }
  &[data-width="full"]{
    width: 100%;
  }
`;
