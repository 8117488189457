import React from 'react';
import Button from '../presentations/Button';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {UserType} from '../stores/authenticated';
import {Websocket} from '../services/websocket';

interface CallingInterface {
  toUser: UserType
  disabled?: boolean
}

const Calling: React.FC<CallingInterface> = ({toUser, disabled = false}) => {
  const room   = useSelector((state: AppState) => state.authenticated.user?.room);
  const inRoom = useSelector((state: AppState) => state.authenticated.inRoom);
  const userId = useSelector((state: AppState) => state.authenticated.userId as number);

  const handleCall = () => {
    if (inRoom) {
      Websocket.socket?.emit('sendInvitationRequest', {
        hostUserId : userId,
        guestUserId: toUser.id,
        room
      });
    } else {
      Websocket.socket?.emit('sendTalkingRequest', {
        hostUserId : userId,
        guestUserId: toUser.id
      });
      window.open(`/room-connecting`, '_room');
    }
  }

  const isDisabled = (): boolean => {
    return !toUser.isActive || toUser.privacyStatus || toUser.room;
  }

  return (
          <Button type={'button'} color={'primary'} fontSize={12} width={'100%'} icon={'soi-video'} disabled={isDisabled()} onClick={handleCall}>
            {inRoom ? '招待する' : '話しかける'}
          </Button>
  );
};

export default Calling;
