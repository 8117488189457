import styled from 'styled-components';
import React from 'react';

interface FormFieldInterface {
  label: React.ReactElement | React.ReactNode
  children: React.ReactElement | React.ReactNode
}

const FormField: React.FC<FormFieldInterface> = (props) => {
  return (
          <Wrapper>
            <Label>{props.label}</Label>
            <Body>{props.children}</Body>
          </Wrapper>
  );
}

const Wrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
`;

const Body = styled.div`
`;

export default FormField;
