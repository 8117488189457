import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import {UserType} from '../stores/authenticated';

interface AvatarInterface {
  size?: number
  showStatus?: boolean
  user: UserType
  isMe?: boolean
}

const Avatar: React.FC<AvatarInterface> = ({user, size = 40, showStatus = true, isMe = false}) => {


  const getStatus = () => {
    if (user?.isActive && user?.privacyStatus) {
      return 'privacy';
    } else if (user?.isActive) {
      return 'online';
    } else {
      return 'offline';
    }
  }

  const getName = () => {
    if (user.avatar) {
      return null;
    } else {
      return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    }
  }

  return (
          <Wrapper data-status={getStatus()} data-show-status={showStatus} data-is-me={isMe} size={size} data-has-image={!!user?.avatar} image={user?.avatar}>
            {getName()}
          </Wrapper>
  );
}


const Wrapper = styled.div<{ size: number, image?: string }>`
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  background-color: #7759F4;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1px #D5D5D5, 0 1px 2px rgba(0, 0, 0, .16);
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  color: #fff;
  font-size: ${(props) => props.size/2.5}px;
  line-height: ${(props) => props.size}px;
  white-space: nowrap;
  text-align: center;
  
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    ${(props) => fontSize(props.size / 2)}
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'smart-o';
    transform: scale(.35);
    transform-origin: right bottom;
  }
  
  &[data-has-image='true'] {
    background-color: #fff;
  }
  
  &[data-is-me='true'] {
    box-shadow: inset 0 0 0 3px #76FF00, 0 1px 2px rgba(0, 0, 0, .16);
    pointer-events: none;
  }
  
  &[data-show-status='false']::after {
    display: none;
  }

  &[data-status='online']::after {
    padding-top: .2em;
    content: "\\e900";
    background-color: #62C100;
  }

  &[data-status='offline']::after {
    background-color: #A7AFBC;
  }

  &[data-status='privacy']::after {
    content: "\\e908";
    background-color: #F0B000;
  }
`;

export default Avatar;
