import React, {useContext} from 'react';
import styled from 'styled-components';
import UserList from './UserList';
import Button from './Button';
import {MeetingType} from '../stores/meetings';
import {PopupContext} from '../containers/PopupStoreContext';
import RoomLockedStatus from '../components/RoomLockedStatus';

interface MeetingListItemInterface {
  meeting: MeetingType
}

const MeetingListItem: React.FC<MeetingListItemInterface> = (props) => {
  const [, setPopupState] = useContext(PopupContext);

  const handleOpenPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.persist();

    const children = <Button type={'button'} color={'primary'} width={'100%'} icon={'soi-video'} onClick={handleJoin}>
      参加する
    </Button>;

    setPopupState(() => {
      return {
        open : true,
        children,
        event: event
      }
    });
  }

  const handleJoin = () => {
    console.log('handleJoin', props.meeting);
    window.open(`/room?title=${props.meeting.title}&roomId=${props.meeting.id}`, '_room');
  }

  return (
          <Wrapper>
            <Room onClick={handleOpenPopup}>
              <UserList users={props.meeting.users}/>
            </Room>
            <RoomLockedStatus isLocked={props.meeting.isLocked}/>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Room = styled.div`
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #DFEAFA;
  border-radius: 6px;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .16);
  cursor: pointer;
`;

export default MeetingListItem;
