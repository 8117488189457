import React from 'react';
import styled from 'styled-components';

interface ModalConfirmActionInterface {
  children: React.ReactElement
}

const ModalConfirmAction: React.FC<ModalConfirmActionInterface> = (props) => {
  return (
          <Wrapper>{props.children}</Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px 20px 40px;
  text-align: right;
`;

export default ModalConfirmAction;
