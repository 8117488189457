import {FormFieldset} from '../Form/FormFieldset';
import {FormInput} from '../Form/FormInput';
import React from 'react';
import FormErrorText from '../Form/FormErrorText';
import {FieldError} from 'react-hook-form';

interface LoginFormUsernameFieldProps {
  register: any;
  error: FieldError | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginFormUsernameField = (props: LoginFormUsernameFieldProps) => {
  return (
          <FormFieldset>
            {props.error && <FormErrorText text="このフィールドを入力してください"/>}
            <FormInput name={'loginId'}
                       placeholder="メールアドレスまたはユーザー名"
                       register={props.register}
                       error={props.error}
            />
          </FormFieldset>
  );
}

LoginFormUsernameField.defaultProps = {
  value: '',
}

export default LoginFormUsernameField;
