import React from 'react';
import UserMenu from './UserMenu';
import {fontSize} from '../components/utility/Mixin';
import styled from 'styled-components';
import mediaQuery from 'styled-media-query';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {userMenuToggle, userMenuClose} from '../stores/user-menu';
import OutsideClickHandler from 'react-outside-click-handler';
import Avatar from './Avatar';
import {UserType} from '../stores/authenticated';
import {cloneDeep} from 'lodash-es';

const UserGroup = () => {
  const user          = cloneDeep(useSelector((state: AppState) => state.authenticated.user as UserType));
  const privacyStatus = useSelector((state: AppState) => state.authenticated.privacyStatus);

  if (user) {
    user.privacyStatus = privacyStatus;
  }

  const getUsername = (): string => {
    return user?.username;
  }

  return (
          <Wrapper>
            <OutsideClickHandler onOutsideClick={() => store.dispatch(userMenuClose())}>
              <Button type={'button'} onClick={() => store.dispatch(userMenuToggle())}>
                <Avatar user={user} size={30}/>
                <Name>
                  {getUsername()}
                </Name>
              </Button>
            </OutsideClickHandler>
            <UserMenu/>
          </Wrapper>
  );
};

// style
const Wrapper = styled.div`
  margin-right: -14px;
  color: inherit;

  ${mediaQuery.greaterThan('medium')`
    width: 200px;
  `}
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery.greaterThan('medium')`
    padding: 0 14px;
  `}
`;

const Name = styled.div`
  margin-left: 10px;
  padding-right: 20px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  color: #FFF;
  ${fontSize(14)}
  font-weight: bold;
  
  &::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 10px;
  }

  ${mediaQuery.lessThan('medium')`
    display: none;
  `}
`;

export default UserGroup;
