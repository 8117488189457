import * as React from 'react';
import {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/history-modal';
import TalkingHistory from './TalkingHistory';

const HistoryModal: React.FC = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.historyModal.open);
  const tab     = useSelector((state: AppState) => state.historyModal.tab);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <TalkingHistory tab={tab}/>
          </Modal>
  );
}

export default HistoryModal;

