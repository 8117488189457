import React, {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/receive-invitation-request-modal';
import {Websocket} from '../../services/websocket';
import ReceiveInvitationRequest from './ReceiveInvitationRequest';
import {find} from 'lodash-es';

const ReceiveInvitationRequestModal = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.receiveInvitationRequestModal.open);
  const data     = useSelector((state: AppState) => state.receiveInvitationRequestModal.data);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = (rejectMessage: string) => {
    store.dispatch(close());
    Websocket.socket?.emit('invitationResponse', {
      sendInvitationDto        : data?.sendInvitationDto,
      callHistory              : data?.callHistory,
      invitationRequestAccepted: false,
      rejectMessage            : rejectMessage
    });
  }

  const handleAccept = () => {
    store.dispatch(close());
    Websocket.socket?.emit('invitationResponse', {
      sendInvitationDto: data?.sendInvitationDto,
      callHistory: data?.callHistory,
      invitationRequestAccepted: true
    });
    window.open(`/room-connect`, '_room');
  }

  return (
          <Modal ref={ref} closeButton={false}>
            <ReceiveInvitationRequest data={data} onClose={handleClose} onAccept={handleAccept}/>
          </Modal>
  );
};

export default ReceiveInvitationRequestModal;
