import React, {useEffect} from 'react';
import Header from '../presentations/Header';
import FullScreenContainer from '../presentations/FullScreenContainer';
import ResetPasswordForm from '../containers/ResetPasswordForm';
import {useTranslation} from 'react-i18next';

const ResetPassword = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${t('Password Reset')}`;
  }, []);

  return (
          <>
            <Header/>

            <FullScreenContainer centering={true}>
              <main>
                <ResetPasswordForm/>
              </main>
            </FullScreenContainer>
          </>
  );
};

export default ResetPassword;
