import React, {ForwardRefExoticComponent, useImperativeHandle, useRef, useState} from 'react';
import styled from 'styled-components';

interface PopupInterface {
  children: React.ReactElement
  width?: string
  x?: number
  y?: number
  position?: 'left-top' | 'right-top' | 'left-bottom' | 'right-bottom' | string
  design?: 'normal' | 'simple' | string
  ref?: any
}

const Popup: ForwardRefExoticComponent<PopupInterface> = React.forwardRef((props, ref) => {
  const [show, changeShow] = useState(false);
  const clientRef          = useRef<any>();

  const handleToggle = () => {
    changeShow(!show);
  };

  const handleOpen = () => {
    changeShow(true);
  }

  const handleClose = () => {
    changeShow(false);
  }

  useImperativeHandle(ref, () => {
    return {
      toggle      : handleToggle,
      open        : handleOpen,
      close       : handleClose,
      offsetWidth : clientRef.current.offsetWidth,
      offsetHeight: clientRef.current.offsetHeight,
    };
  });

  // console.log(clientRef?.current?.offsetHeight);

  return (
          <Wrapper ref={clientRef} data-show={show} width={props.width} x={props.x} y={props.y} data-design={props.design}>
            <Body data-design={props.design}>
              {props.children}
              <Close type={'button'} onClick={handleClose} data-design={props.design}><i className={'soi-close'}></i></Close>
            </Body>
            <Arrow data-position={props.position} data-design={props.design}/>
          </Wrapper>
  );
});

Popup.defaultProps = {
  width   : '200px',
  x       : 0,
  y       : 0,
  position: 'left-top',
  design  : 'normal'
}

const Wrapper = styled.div<{ width?: string, x?: number, y?: number }>`
  width: ${(props) => props?.width};
  position: absolute;
  top: ${(props) => props?.y}px;
  left: ${(props) => props?.x}px;
  z-index: 100;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 41, 0.4));

  &:not([data-show='true']) {
    pointer-events: none;
    z-index: -1;
    visibility: hidden;
  }
`;

const Body = styled.div`
  padding: 28px 20px 22px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  
  &[data-design='simple'] {
    padding: 0;
  }
`;

const Arrow = styled.div`
  &[data-design='simple'] {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 101;
  }

  &[data-position='left-top']{
    &:before,
    &:after {
      border-bottom: 10px solid #D5D5D5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      top: -10px;
      left: 20px;
    }

    &:after {
      border-bottom: 10px solid #FFFFFF;
      top: -9px;
    }
  }
  
  &[data-position='right-top']{
    &:before,
    &:after {
      border-bottom: 10px solid #D5D5D5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      top: -10px;
      right: 20px;
    }

    &:after {
      border-bottom: 10px solid #FFFFFF;
      top: -9px;
      right: 20px;
    }
  }
  
  &[data-position='left-bottom']{
    &:before,
    &:after {
      border-top: 10px solid #D5D5D5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -10px;
      left: 20px;
    }

    &:after {
      border-top: 10px solid #FFFFFF;
      bottom: -9px;
      left: 20px;
    }
  }
  
  &[data-position='right-bottom']{
    &:before,
    &:after {
      border-top: 10px solid #D5D5D5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -10px;
      right: 20px;
    }

    &:after {
      border-top: 10px solid #FFFFFF;
      bottom: -9px;
      right: 20px;
    }
  }
`;

const Close = styled.button`
  position: absolute;
  top: 11px;
  right: 14px;

  &[data-design='simple'] {
    display: none;
  }
`;

export default Popup;
