export class Image {
  static toBase64Url(url: string) {
    return new Promise((resolve, reject) => {
      const xhr  = new XMLHttpRequest();
      xhr.onload = function () {
        const reader     = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
        console.log(xhr);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onerror      = () => {
        reject(new Error(xhr.statusText));
      };
      xhr.send();
    });
  }

  static toBlob(url: string): any {
    return new Promise((resolve, reject) => {
      const xhr  = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onerror      = () => {
        reject(new Error(xhr.statusText));
      };
      xhr.send();
    });

  }
}
