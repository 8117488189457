import React, {useState} from 'react';
import ModalHeader from '../presentations/ModalHeader';
import Repository, {ChangePasswordRepositoryInterface} from '../services/repository';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import ChangePasswordForm from '../presentations/ChangePasswordForm';
import {useForm} from 'react-hook-form';
import {AlertMessageInterface} from '../presentations/AlertMessage';
import {close as closeModalChangePassword} from '../stores/modal-change-password';
import {AxiosResponse} from 'axios';
import {UserType} from '../stores/authenticated';
import {isArray, map} from 'lodash-es';

const ChangePassword: React.FC = (props) => {
  const repository            = new Repository();
  const userId                = useSelector((state: AppState) => state.authenticated.userId as number);
  const [message, setMessage] = useState<AlertMessageInterface>({});
  const [sending, setSending] = useState(false);

  const {register, handleSubmit, errors} = useForm<ChangePasswordRepositoryInterface>({
    defaultValues: {
      oldPassword       : '',
      newPassword       : '',
      newPasswordConfirm: '',
    }
  });

  const onSubmit = (post: ChangePasswordRepositoryInterface) => {
    setSending(true);

    repository.changePassword(userId, post)
            .then(({data}: AxiosResponse<UserType>) => {
              console.log(data);
              setMessage({
                level: 'success',
                body : '更新しました'
              });
            })
            .catch(({response}) => {
              console.log(response);
              let errorBody: any = '';

              if (isArray(response.data.message)) {
                const errorItems = map(response.data.message, message => {
                  return <li>{message}</li>
                });
                errorBody = <><p>更新に失敗しました。</p><ul>{errorItems}</ul></>;

              } else {
                errorBody = response.data.message;
              }

              setMessage({
                level: 'error',
                body : errorBody
              })
            })
            .finally(()=>{
              setSending(false);
            });
  };

  const handleChancel = () => {
    store.dispatch(closeModalChangePassword());
  };

  return (
          <>
            <ModalHeader><h3>パスワード変更</h3></ModalHeader>
            <form noValidate method={'post'} onSubmit={handleSubmit(onSubmit)}>
              <ChangePasswordForm register={register} errors={errors} message={message} onCancel={handleChancel} sending={sending}/>
            </form>
          </>
  );
}

export default ChangePassword;
