import React, {useContext, useRef} from 'react';
import styled from 'styled-components';
import UserList from './UserList';
import {TalkingType} from '../stores/talkings';
import Popup from './Popup';
import Button from './Button';
import {PopupContext} from '../containers/PopupStoreContext';
import RoomLockedStatus from '../components/RoomLockedStatus';

interface TalkingListItemInterface {
  talking: TalkingType
}

const TalkingListItem: React.FC<TalkingListItemInterface> = (props) => {
  const popupRef: any     = useRef();
  const [, setPopupState] = useContext(PopupContext);

  const handleOpenPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.persist();

    const children = <Button type={'button'} color={'primary'} width={'100%'} icon={'soi-video'} onClick={handleJoin}>
      参加する
    </Button>;

    setPopupState(() => {
      return {
        open : true,
        children,
        event: event
      }
    });
  }

  const handleJoin = () => {
    console.log('handleJoin', props.talking);
    window.open(`/room?title=${props.talking.title}&roomId=${props.talking.id}`, '_room');
  }

  return (
          <Wrapper>
            <Room onClick={handleOpenPopup}>
              <UserList users={props.talking.users}/>
            </Room>
            <RoomLockedStatus isLocked={props.talking.isLocked}/>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Room = styled.div`
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #D3E6E1;
  border-radius: 6px;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .16);
  cursor: pointer;
`;

const Lock = styled.div`
  position: absolute;
  right: -4px;
  top: -4px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #F0B000;
  border: 1px solid #D5D5D5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export default TalkingListItem;
