import React, {useRef, useState} from 'react';
import ModalHeaderSimple from '../../presentations/ModalHeaderSimple';
import styled from 'styled-components';
import {fontSize} from '../utility/Mixin';
import Button from '../../presentations/Button';
import {AppState} from '../../stores';
import {useSelector} from 'react-redux';
import AlertMessage, {AlertMessageInterface} from '../../presentations/AlertMessage';

interface RoomShareInterface {
  onClose?: () => void
}

const RoomShare: React.FC<RoomShareInterface> = (props) => {
  const data                  = useSelector((state: AppState) => state.roomShareModal.data);
  const linkRef               = useRef<any>();
  const [message, setMessage] = useState<AlertMessageInterface>();

  const createRoomLink = () => {
    return `${window.location.protocol}//${window.location.host}/room?title=${data?.title}&roomId=${data?.roomId}`;
  }

  const handleCopyLink = () => {
    linkRef.current.select();
    document.execCommand('copy');
    setMessage({
      level: 'success',
      body : 'リンクをコピーしました'
    });
  }

  return (
          <>
            <ModalHeaderSimple>Meetingの共有</ModalHeaderSimple>
            <Body>
              <AlertMessage {...message}/>
              <LinkCopy>
                <LinkCopyText type="text" value={createRoomLink()} readOnly ref={linkRef}/>
                <LinkCopyButton type="button" onClick={handleCopyLink}>リンクをコピー</LinkCopyButton>
              </LinkCopy>
              <Sub>このリンクからMeetingに参加することができます。</Sub>
            </Body>
            <Action>
              <Button type={'button'} color={'secondary'} onClick={props.onClose}>閉じる</Button>
            </Action>
          </>
  )
}

export const Body = styled.div`
  padding: 0 20px;
`;

export const LinkCopy = styled.div`
  display: flex;
`;

export const LinkCopyText = styled.input`
  flex: 1;
  ${fontSize(18)};
  font-weight: 700;
  line-height: 1.3;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: 6px;
`;

export const LinkCopyButton = styled.button`
  margin-left: 15px;
  color: #4390D9;
  text-decoration: underline;
`;

export const Sub = styled.small`
  margin-top: 10px;
  display: block;
  ${fontSize(12)};
  font-weight: 400;
`;

export const Action = styled.div`
  margin-top: 25px;
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

export const LinkCopied = styled.div`
`;

export default RoomShare;
