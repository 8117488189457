import React, {ForwardRefExoticComponent, useImperativeHandle, useRef, useState} from 'react';
import AlertMessage from '../../presentations/AlertMessage';
import {useForm} from 'react-hook-form';
import {FormInput} from '../../presentations/Form/FormInput';
import FormErrorText from '../../presentations/Form/FormErrorText';
import Repository from '../../services/repository';
import {AxiosError, AxiosResponse} from 'axios';
import store, {AppState} from '../../stores';
import {lock, unlock} from '../../stores/room-password';
import {useSelector} from 'react-redux';

interface RoomPasswordFormInterface {
  roomId: number
  ref?: any
}

interface RoomPasswordFormFieldInterface {
  password: string
}

const RoomPasswordForm: ForwardRefExoticComponent<RoomPasswordFormInterface> = React.forwardRef((props, ref) => {
  const submitButtonRef                  = useRef<any>();
  const deleteButtonRef                  = useRef<any>();
  const inputRef                         = useRef<any>();
  const [alert, setAlert]                = useState<any>(null);
  const roomPassword                     = useSelector((state: AppState) => state.roomPassword);
  const {register, errors, handleSubmit} = useForm<RoomPasswordFormFieldInterface>({
    defaultValues: {
      password: roomPassword.password,
    }
  });
  const repository                       = new Repository();

  const onSubmit = (post: RoomPasswordFormFieldInterface) => {
    repository.roomLock(props.roomId, {isLocked: true})
            .then(({data}: AxiosResponse) => {
              console.log(data)
              store.dispatch(lock(post.password));
              setAlert(<AlertMessage level={'success'} body={'パスワードを設定しました'}/>);
            })
            .catch((error: AxiosError) => {
              console.log(error);
              setAlert(<AlertMessage level={'error'} body={'設定に失敗しました'}/>);
            });
  }

  const handleDelete = () => {
    repository.roomLock(props.roomId, {isLocked: false})
            .then(({data}: AxiosResponse) => {
              console.log(data)
              store.dispatch(unlock());
              setAlert(<AlertMessage level={'success'} body={'パスワードを削除しました'}/>);
            })
            .catch((error: AxiosError) => {
              console.log(error);
              setAlert(<AlertMessage level={'error'} body={'削除に失敗しました'}/>);
            });
  }

  useImperativeHandle(ref, () => {
    return {
      input       : inputRef.current.input,
      submitButton: submitButtonRef.current,
      deleteButton: deleteButtonRef.current,
    };
  });

  return (
          <form noValidate method={'post'} onSubmit={handleSubmit(onSubmit)}>
            {alert}
            {errors.password && <FormErrorText text="必須入力です"/>}
            <FormInput name={'password'} register={register({required: true})} placeholder={'パスワード'} disabled={roomPassword.isLocked} ref={inputRef}/>
            <button type={'submit'} ref={submitButtonRef} style={{display: 'none'}}>設定</button>
            <button type={'button'} ref={deleteButtonRef} style={{display: 'none'}} onClick={handleDelete}>削除</button>
          </form>
  )
});

export default RoomPasswordForm;
