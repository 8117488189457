import React from 'react';
import styled from 'styled-components';

interface HeaderIndicatorIconInterface extends IconStyleProps {
  children: React.ReactNode
}

const HeaderIndicatorIcon: React.FC<HeaderIndicatorIconInterface> = props => {
  return (
          <Icon styleProps={props.styleProps}>{props.children}</Icon>
  );
};

type IconStyleProps = { styleProps: { color: string } };
const Icon = styled.div`
  color: ${(props: IconStyleProps) => props.styleProps.color};;
`;

export default HeaderIndicatorIcon;
