import React from 'react';
import ModalHeaderSimple from './ModalHeaderSimple';
import styled from 'styled-components';
import Button from './Button';
import Spinner1 from './Spinner1';

interface CreateRoomNowInterface {
  onClose: () => void
}

const CreateRoomNow: React.FC<CreateRoomNowInterface> = (props) => {
  return (
          <>
            <ModalHeaderSimple>部屋を作成中です...</ModalHeaderSimple>
            <Body>
              <Spinner1/>
            </Body>
            <Action>
              <Button type={'button'} color={'danger'} onClick={props.onClose}>キャンセル</Button>
            </Action>
          </>
  )
}

export const Body = styled.div`
  text-align: center;
`;

export const Action = styled.div`
  margin-top: 25px;
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

export default CreateRoomNow;
