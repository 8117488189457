import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import Repository from '../services/repository';
import {privacyStatusToggle, UserType} from '../stores/authenticated';
import {Websocket} from '../services/websocket';
import {open as openModalChangePassword} from '../stores/modal-change-password'
import {open as openMyProfileModal} from '../stores/my-profile-modal'
import {open as openHistoryModal} from '../stores/history-modal'
import {find} from 'lodash-es';

const UserMenu = () => {
  const user                        = useSelector((state: AppState) => state.authenticated.user as UserType);
  const userId                      = useSelector((state: AppState) => state.authenticated.userId as number);
  const open                        = useSelector((state: AppState) => state.userMenu.open);
  const talkingHistoryModalRef: any = useRef();

  const isOpen = () => {
    return open;
  }

  const handleCreateRoom = () => {
    Websocket.socket?.emit('createRoom', {
      hostUserId: userId
    });
  }

  const handleOpenHistory = () => {
    store.dispatch(openHistoryModal('ALL'));
  }

  const handleProfile = () => {
    store.dispatch(openMyProfileModal());
  }

  const handleChangePassword = () => {
    store.dispatch(openModalChangePassword());
  }

  const isAdmin = () => {
    return !!find(user.roles, {name: 'admin'}) || !!find(user.roles, {name: 'moderator'});
  }

  return (
          <>
            <Wrapper data-open={isOpen()}>
              <li>
                <PrivacySwitch/>
              </li>
              <li>
                <button type={'button'} className={'menu-button'} onClick={handleCreateRoom}>部屋作成</button>
              </li>
              <li>
                <button type={'button'} className={'menu-button'} onClick={handleOpenHistory}>履歴</button>
              </li>
              <li>
                <button type={'button'} className={'menu-button'} onClick={handleProfile}>プロフィール</button>
              </li>
              <li>
                <button type={'button'} className={'menu-button'} onClick={handleChangePassword}>パスワード変更</button>
              </li>
              {isAdmin() ? <li>
                <Link to="/system-manager" className={'menu-button'} target={'_blank'}>システム管理</Link>
              </li> : null}
              <li>
                <Link to="/logout" className={'menu-button'}>ログアウト</Link>
              </li>
            </Wrapper>
          </>
  );
};

const PrivacySwitch = () => {
  const privacyStatus = useSelector((state: AppState) => state.authenticated.privacyStatus);
  const userId        = useSelector((state: AppState) => state.authenticated.userId as number);
  const repository    = new Repository();

  const isPrivate = () => {
    return privacyStatus;
  }

  const getLabel = (): string => {
    return isPrivate() ? 'ON' : 'OFF';
  }

  const handlePrivacyStatusToggle = () => {
    store.dispatch(privacyStatusToggle());
    repository.changePrivacy(userId, !isPrivate()).then(() => {
      Websocket.socket?.emit('getInfo', {});
    });
  }

  return (
          <Switch onClick={handlePrivacyStatusToggle}>
            <SwitchStatus>Privacy</SwitchStatus>
            <SwitchToggle data-private={isPrivate()}>
              <SwitchLabel>{getLabel()}</SwitchLabel>
            </SwitchToggle>
          </Switch>
  );
}

// style
const Wrapper = styled.ul`
  width: 200px;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 10;
  color: #fff;

  visibility: hidden;
  pointer-events: none;
  
  &[data-open='true']{
    visibility: visible;
    pointer-events: auto;
  }

  > li {
    background-color: #262F40;

    &:hover {
      background-color: #4E6693;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
    }

    .menu-button {
      border-top: 1px solid #707070;
      padding-left: 53px;
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: inherit;
      font-weight: 700;
      text-decoration: none;
    }
  }
`;

const Switch = styled.button`
  padding-left: 53px;
  width: 100%;
  height: 36px;
  border-top: 1px solid #707070;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const SwitchStatus = styled.div``;

const SwitchToggle = styled.div`
  margin-left: 20px;
  padding-left: 6px;
  padding-right: 6px;
  width: 46px;
  height: 20px;
  background-color: #636C7C;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  transition: background-color 100ms ease-out;
  
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    left: 2px;
    top: 2px;
    
    transition: transform 150ms ease-out;
  }
  
  &[data-private='true'] {
    background-color: #F0B000;
    justify-content: flex-start;
  }
  
  &[data-private='true']::before {
    transform: translateX(26px);
  }

`;

const SwitchLabel = styled.div`
  font-size: 10px;
`;

export default UserMenu;
