import FormField from './Form/FormField';
import FormErrorText from './Form/FormErrorText';
import {FormInput} from './Form/FormInput';
import Grid from './Grid';
import {FormSelect} from './Form/FormSelect';
import React from 'react';
import {SelectOptionInterface} from '../types/select-option.interface';
import {find, map, remove} from 'lodash-es';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {UserType} from '../stores/authenticated';

interface UserProfileManagerFormFieldsetInterface {
  errors: any
  register: any
  registerSetting: {
    username    : Object
    firstName   : Object
    lastName    : Object
    departmentId: Object
    role        : Object
    email       : Object
    password    : Object
    phone       : Object
  }
}

const UserProfileManagerFormFieldset: React.FC<UserProfileManagerFormFieldsetInterface> = (props) => {
  const user = useSelector((state: AppState) => state.authenticated.user as UserType);

  const departments: SelectOptionInterface[] = map(useSelector((state: AppState) => state.departments), department => {
    return {
      id  : String(department.id),
      name: department.title,
    }
  });

  departments.unshift({
    id  : '',
    name: '選択してください',
  });

  const isAdmin = () => {
    return find(user.roles, {name: 'admin'});
  }

  const roles: SelectOptionInterface[] = map(useSelector((state: AppState) => state.roles), role => {
    return {
      id  : role.name,
      name: role.name,
    }
  });

  roles.unshift({
    id  : '',
    name: '選択してください',
  });

  if (!isAdmin()) {
    remove(roles, {name: 'admin'});
  }

  return (
          <>
            <FormField label={'ユーザーID'}>
              {props.errors.username && <FormErrorText text="必須入力です"/>}
              <FormInput name={'username'}
                         register={props.register(props.registerSetting.username)}
                         error={props.errors.username}/>
            </FormField>

            <FormField label={'氏名'}>
              {(props.errors.firstName || props.errors.lastName) && <FormErrorText text="必須入力です"/>}
              <Grid columnGap={'10px'}>
                <>
                  <FormInput name={'firstName'}
                             register={props.register(props.registerSetting.firstName)}
                             error={props.errors.firstName}/>
                </>
                <>
                  <FormInput name={'lastName'}
                             register={props.register(props.registerSetting.lastName)}
                             error={props.errors.lastName}/>
                </>
              </Grid>

            </FormField>

            <FormField label={'部署'}>
              {props.errors.departmentId && <FormErrorText text="必須入力です"/>}
              <FormSelect options={departments}
                          name={'departmentId'}
                          register={props.register(props.registerSetting.departmentId)}
                          error={props.errors.departmentId}/>
            </FormField>

            <FormField label={'権限'}>
              {props.errors.role && <FormErrorText text="必須入力です"/>}
              <FormSelect options={roles}
                          name={'role'}
                          register={props.register(props.registerSetting.role)}
                          error={props.errors.role}/>
            </FormField>

            <FormField label={'電話番号'}>
              {props.errors.phone && <FormErrorText text="必須入力です"/>}
              <FormInput name={'phone'}
                         register={props.register(props.registerSetting.phone)}
                         error={props.errors.phone}/>
            </FormField>

            <FormField label={'メールアドレス'}>
              {props.errors.email && <FormErrorText text="必須入力です"/>}
              <FormInput name={'email'}
                         register={props.register(props.registerSetting.email)}
                         error={props.errors.email}/>
            </FormField>

            <FormField label={'パスワード'}>
              {props.errors.password && <FormErrorText text="必須入力です"/>}
              <FormInput name={'password'}
                         type={'password'}
                         register={props.register(props.registerSetting.password)}
                         error={props.errors.password}/>
                         <small>大小英数字を含めた6〜20文字を設定してください</small>
            </FormField>
          </>
  );
}

export default UserProfileManagerFormFieldset
