import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import Repository from '../../services/repository';
import {AxiosResponse} from 'axios';
import {updateUser, UserType} from '../../stores/authenticated';
import Loader from '../Loader';
import styled from 'styled-components';
import UserProfileView from '../UserProfileView';
import Button from '../../presentations/Button';
import {change} from '../../stores/my-profile-switch';

const MyProfileView: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(true);
  const userId              = useSelector((state: AppState) => state.authenticated.userId as number);
  const user                = useSelector((state: AppState) => state.authenticated.user as UserType);

  useEffect(() => {
    const repository = new Repository();
    repository.getUser(userId)
            .then(({data}: AxiosResponse<UserType>) => {
              store.dispatch(updateUser(data));
              setLoaded(false);
            })
            .catch(error => {

            });
  }, [userId]);

  const handleEdit = () => {
    store.dispatch(change('EDIT'));
  }

  return (
          <>
            {loaded ? <Loader/> :
                    <>
                      <UserProfileView user={user}/>
                      <Action>
                        <Button type={'button'} color={'secondary'} width={'130px'} onClick={handleEdit}>編集する</Button>
                      </Action>
                    </>}
          </>
  );
}

const Action = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export default MyProfileView;

