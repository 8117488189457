import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Websocket} from '../../services/websocket';
import store, {AppState} from '../../stores';
import {useSelector} from 'react-redux';
import Logo from '../../presentations/Logo';
import mediaQuery from 'styled-media-query';
import {open as openRoomShareModal} from '../../stores/room-share-modal';
import {open as openPasswordModal} from '../../stores/room-password-modal';
import RoomShareModal from './RoomShareModal';
import {InfoType} from '../../App';
import {find} from 'lodash-es';
import {UserType} from '../../stores/authenticated';

// @ts-ignore
import Favicon2 from '../../assets/favicon/favicon2.ico';
import RoomPasswordModal from './RoomPasswordModal';

declare const JitsiMeetExternalAPI: any;

const Room = () => {
  const connected                         = useSelector((state: AppState) => state.receiver.connected);
  const userId                            = useSelector((state: AppState) => state.authenticated.userId as number);
  const user                              = useSelector((state: AppState) => state.authenticated.user as UserType);
  const title                             = new URLSearchParams(document.location.search).get('title') as string;
  const roomId                            = new URLSearchParams(document.location.search).get('roomId') as string;
  const hostUserId                        = new URLSearchParams(document.location.search).get('hostUserId') as string;
  const [meet, setMeet]                   = useState<any>(null);
  const [lock, setLock]                   = useState<any>(null);
  const [participantId, setParticipantId] = useState<string>('');
  const [moderatorId, setModeratorId]     = useState<string>('');
  const meetRef                           = useRef(null);
  const systemName                        = useSelector((state: AppState) => state.system.systemName);
  const roomPassword                      = useSelector((state: AppState) => state.roomPassword);

  useEffect(() => {
    const faviconElm = document.querySelector('#favicon') as HTMLLinkElement;
    faviconElm.setAttribute('href', Favicon2);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', JitsiOut);
  }, []);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${systemName}`;
  }, [systemName]);

  useEffect(() => {
    console.log('connected', connected);
    if (connected) {
      Websocket.socket?.emit('jitsiIn', {
        roomId,
        userId,
        isGuest: !userId
      });

      Websocket.socket?.off('getInfo');
      Websocket.socket?.on('getInfo', ({data}: { data: InfoType }) => {
        console.log('websocket getInfo', data);
        const rooms = data.departments.concat(data.talkings) as any[];
        const room  = find(rooms, {id: Number(roomId)});
        if (room) {
          setLock(room.isLocked);
        }
      });
    }
  }, [connected, roomId, userId]);

  const createUserInfo = () => {
    const info: any = {}

    if (user) {
      info.displayName = `${user.firstName} ${user.lastName}`;
      info.email       = user.email;
      info.avatarUrl   = user.avatar;
    }

    return info;
  }

  useEffect(() => {
    const domain  = process.env.REACT_APP_WEB_RTC_DOMAIN;
    const options = {
      roomName  : title,
      width     : '100%',
      height    : '100%',
      parentNode: meetRef.current,
      configOverwrite         : {
        disableInviteFunctions: true,
        startWithVideoMuted   : true,
      },
      interfaceConfigOverwrite: {
        HIDE_INVITE_MORE_HEADER: true,
      },
      userInfo  : createUserInfo()
    };

    console.log(options);

    const meet = new JitsiMeetExternalAPI(domain, options);

    meet.on('participantJoined', (event: any) => {
      console.log('participantJoined', event);
      setTimeout(() => {
        console.log(meet._myUserID);
        setParticipantId(meet._myUserID);
      });
    });

    meet.on('participantRoleChanged', (event: { id: string, role: string }) => {
      console.log('participantRoleChanged', event);
      setTimeout(() => {
        console.log(meet._myUserID);
        setParticipantId(meet._myUserID);
        if (event.role === 'moderator') {
          setModeratorId(event.id);
        }
      });
    });


    setMeet(meet);
  }, []);

  useEffect(() => {
    meet?.executeCommand('password', roomPassword.password);
    setLock(roomPassword.isLocked);
  }, [roomPassword]);

  const JitsiOut = () => {
    Websocket.socket?.emit('jitsiOut', {
      roomId,
      userId
    });
  }

  const handleClose = () => {
    JitsiOut();
    window.close();
  }

  const handleClickLink = () => {
    const data = {
      title,
      roomId,
    }
    store.dispatch(openRoomShareModal(data));
  }

  const handleClickLock = () => {
    // console.log('participantId & moderatorId', participantId, moderatorId);
    store.dispatch(openPasswordModal([participantId, moderatorId]));
  }

  return (
          <Wrapper>
            {participantId && <>
              <Header>
                <HeaderLeft>
                  <Logo width={104}/>
                </HeaderLeft>
                <HeaderRight>
                  <ToolButton type={'button'} onClick={handleClickLink} title={'共有'}>
                    <i className={'soi-link'}/>
                  </ToolButton>
                  <ToolButton type={'button'} onClick={handleClickLock} title={'ロック'} data-lock={lock}>
                    <i className={'soi-lock'}/>
                  </ToolButton>
                </HeaderRight>
              </Header>
            </>}
            <Meet ref={meetRef}/>
            <Close onClick={handleClose}/>
            <RoomShareModal/>
            <RoomPasswordModal/>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Header = styled.header`
    padding: 0 14px;
    width: 100%;
    height: 40px;
    background: #262F40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
`;

const HeaderLeft = styled.div`
  min-width: 104px;
  ${mediaQuery.lessThan('medium')`
    width: 23px;
    min-width: 23px;
    overflow: hidden;
    img {
      min-width: 104px;
    }
  `}
`;

const HeaderRight = styled.div`
`;

const ToolButton = styled.button`
  margin: 0 5px;
  padding: 10px;
  
  &:hover {
    opacity: .7;
  }
  
  &[data-lock='true'] {
    color: #F0B000;
  }
`;

const Meet = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const Close = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

export default Room;
