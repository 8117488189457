import FormField from './Form/FormField';
import FormErrorText from './Form/FormErrorText';
import {FormInput} from './Form/FormInput';
import React from 'react';

interface DepartmentManagerFormFieldsetInterface {
  errors: any
  register: any
}

const DepartmentManagerFormFieldset: React.FC<DepartmentManagerFormFieldsetInterface> = (props) => {
  return (
          <>
            <FormField label={'部署名'}>
              {props.errors.username && <FormErrorText text="必須入力です"/>}
              <FormInput name={'title'}
                         register={props.register({required: true})}
                         error={props.errors.title}/>
            </FormField>
          </>
  );
}

export default DepartmentManagerFormFieldset
