import io from 'socket.io-client';

export class Websocket {
  private static _socket: SocketIOClient.Socket;

  static connect(accessToken: string): SocketIOClient.Socket {
    this._socket?.disconnect();
    this._socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}`, {
      path: '/ws',
      query: {
        token: accessToken
      }
    });

    return this.socket as SocketIOClient.Socket;
  }

  static get socket(): SocketIOClient.Socket {
    return this._socket;
  }

  // if(accessToken) {
  //   socket = io('http://210.152.117.140/', {
  //     query: {
  //       token: accessToken
  //     }
  //   });
  //
  //   socket.on('connect', () => {
  //     console.log('websocket connected');
  //     socket.emit('getInfo', {});
  //   });
  //
  //   socket.on('getInfo', ({data}: any) => {
  //     console.log('websocket getInfo', data);
  //     // AppInitializer.initAppState(data.data);
  //   });
  //
  //   socket.on('disconnect', () => {
  //     console.log('websocket disconnect');
  //   });
  //
  //   // in
  //   // socket.emit('jitsiIn', room);
  //
  //   // leave
  //   // socket.emit('jitsiOut');
  //
  // }
}
