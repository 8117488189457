import {createSlice} from '@reduxjs/toolkit';

export interface ReceiverInterface {
  connected: boolean
}

// Stateの初期状態
const initialState: ReceiverInterface = {
  connected: false
};

// Sliceを生成する
const slice = createSlice({
  name    : 'receiver',
  initialState,
  reducers: {
    connected   : (state) => {
      return {...state, connected: true};
    },
    disconnected: (state) => {
      return {...state, connected: false};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {connected, disconnected} = slice.actions;
