import React, {useState} from 'react';
import styled from 'styled-components';
import {fontSize} from '../utility/Mixin';
import {UserType} from '../../stores/authenticated';
import {compact, map} from 'lodash-es';
import Avatar from '../../presentations/Avatar';
import TalkingHistory, {HistoryInterface} from './TalkingHistory';
import Calling from '../../containers/Calling';
import {differenceInDays, format, formatDistanceToNow} from 'date-fns';
import ja from 'date-fns/locale/ja';
import {HistoryModalTabType} from '../../stores/history-modal';

interface TalkingHistoryBodyInterface {
  histories: HistoryInterface[]
  tab?: HistoryModalTabType
}

const TalkingHistoryBody: React.FC<TalkingHistoryBodyInterface> = (props) => {
  const [tabId, setTabId] = useState<HistoryModalTabType>(props.tab as HistoryModalTabType);

  const handleChangeTab = (event: React.MouseEvent<HTMLAnchorElement>, changeTabId: HistoryModalTabType) => {
    event.preventDefault();
    setTabId(changeTabId);
  }

  const isSelected = (diffTabId: HistoryModalTabType) => {
    return tabId === diffTabId;
  }

  const getAllHistory = () => {
    return compact(map(props.histories, (history, index: number) => {
      return historyItem(history, index);
    }));
  }

  const getMissedCallHistory = () => {
    return compact(map(props.histories, (history, index: number) => {
      if (history.responseStatus === 2 && history.direction === 'incoming') {
        return historyItem(history, index);
      }
    }));
  }

  const createName = (user: UserType) => {
    return `${user?.firstName} ${user?.lastName}`;
  }

  const createDate = (date: string) => {
    const diff = differenceInDays(new Date(), new Date(date));
    if (diff) {
      return format(new Date(date), 'yyyy/MM/dd HH:ii:ss');
    } else {
      return formatDistanceToNow(new Date(date), {locale: ja, addSuffix: true});
    }
  }

  const historyItem = (history: HistoryInterface, index: number) => {
    return <HistoryItem key={index}>
      <HistoryItemIcon>
        <Avatar user={history.user}/>
      </HistoryItemIcon>
      <HistoryItemBody>
        <HistoryItemUsername>{createName(history.user)}</HistoryItemUsername>
        <HistoryItemDate>{createDate(history.datetime)}</HistoryItemDate>
      </HistoryItemBody>
      <HistoryItemAction>
        <Calling toUser={history.user}/>
      </HistoryItemAction>
    </HistoryItem>;
  }

  return (
          <Wrapper>
            <TabContainer>
              <Tab>
                <TabItem data-selected={isSelected('ALL')}>
                  <a href="#talkingHistoryAll" onClick={(event) => handleChangeTab(event, 'ALL')}>すべて</a>
                </TabItem>
                <TabItem data-selected={isSelected('MISSED')}>
                  <a href="#talkingHistoryMissedCall" onClick={(event) => handleChangeTab(event, 'MISSED')}>不在着信</a>
                </TabItem>
              </Tab>
              <TabContents>
                <TabContent data-show={isSelected('ALL')}>
                  <History>
                    {getAllHistory()}
                  </History>
                </TabContent>
                <TabContent data-show={isSelected('MISSED')}>
                  <History>
                    {getMissedCallHistory()}
                  </History>
                </TabContent>
              </TabContents>
            </TabContainer>
          </Wrapper>
  );
}

TalkingHistoryBody.defaultProps = {
  tab: 'ALL'
}

const Wrapper = styled.div`
`;

const TabContainer = styled.nav`
  margin-top: -12px;
`;

const Tab = styled.ul`
  padding-left: 10px;
  padding-right: 10px;
  background-color: #F7FAFC;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  position: relative;
  z-index: 1; 
`;

const TabItem = styled.li`
  margin-bottom: -1px;
  a {
    height: 42px;
    background-color: #E1E2E8;
    border: 1px solid #E1E2E8;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ${fontSize(14)};
    color: #262F40;
    text-decoration: none;
  }
  
  &[data-selected='true'] a {
    border-color: #D5D5D5;
    background-color: #fff;
  }
`;

const TabContents = styled.div`
  border-top: 1px solid #D5D5D5;
`;

const TabContent = styled.div`
  padding: 10px 30px;
  max-height: 340px;
  overflow-y: auto;

  &:not([data-show='true']) {
    display: none;
  }
`;

const History     = styled.ul``;
const HistoryItem = styled.li`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: 1px solid #E1E2E8;
  }
`;

const HistoryItemIcon = styled.div`
  margin-right: 26px;
  width: 40px;
`;

const HistoryItemBody = styled.div`
  flex: 1;
`;

const HistoryItemUsername = styled.div`
  ${fontSize(14)};
  font-weight: bold;
`;

const HistoryItemDate = styled.div`
  margin-top: .25em;
  ${fontSize(10)};
`;

const HistoryItemAction = styled.div`
`;

export default TalkingHistoryBody;
