import React, {useEffect} from 'react';
import Cookies from 'js-cookie';
import store from '../stores';
import {loggedOut} from '../stores/authenticated';

const Logout: React.FC = () => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ログアウト`;
    Cookies.remove('accessToken');
    Cookies.remove('userId');
    store.dispatch(loggedOut());
    window.location.href = process.env.REACT_APP_DIR as string;
  }, []);

  return (null);
}

export default Logout;
