import React, {useState} from 'react';
import ModalHeader from '../presentations/ModalHeader';
import Repository, {UpdateDepartmentRepositoryInterface} from '../services/repository';
import {useForm} from 'react-hook-form';
import ModalFooter from '../presentations/ModalFooter';
import Button from '../presentations/Button';
import Spinner2 from '../presentations/Spinner2';
import ModalBody from '../presentations/ModalBody';
import {AxiosError, AxiosResponse} from 'axios';
import Padding from '../presentations/Padding';
import {Websocket} from '../services/websocket';
import AlertApiErrorMessage from '../presentations/AlertApiErrorMessage';
import {DepartmentType, insert} from '../stores/departments';
import DepartmentManagerFormFieldset from '../presentations/DepartmentManagerFormFieldset';
import store from '../stores';

interface DepartmentCreatorInterface {
  onClose: () => void
}

const DepartmentCreator: React.FC<DepartmentCreatorInterface> = (props) => {
  const repository                       = new Repository();
  const [sending, setSending]            = useState<boolean>(false);
  const [alertMessage, setAlertMessage]  = useState<React.ReactElement | null>(null);
  const {register, handleSubmit, errors} = useForm<UpdateDepartmentRepositoryInterface>({
    defaultValues: {
      title: '',
    }
  });

  const onSubmit = (post: UpdateDepartmentRepositoryInterface) => {
    setSending(true);

    post.memberLimit = 100;

    console.log(post);

    repository.createDepartment(post)
            .then(({data}: AxiosResponse<DepartmentType>) => {
              console.log(data);
              data.users = [];
              store.dispatch(insert(data));
              Websocket.socket?.emit('getInfo', {});
              props.onClose();
            })
            .catch(({response}: AxiosError) => {
              console.log(response);
              setSending(false);
              setAlertMessage(<AlertApiErrorMessage response={response} title={<p>更新に失敗しました。</p>}/>);

            });
  }

  return (
          <form noValidate method={'post'} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader><h3>部署を作成</h3></ModalHeader>
            <ModalBody>
              <Padding padding={'30px 40px 40px'}>
                <>
                  {alertMessage}
                  <DepartmentManagerFormFieldset register={register} errors={errors}/>
                </>
              </Padding>
            </ModalBody>
            <ModalFooter>
              <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} fontSize={12} onClick={props.onClose}>
                キャンセル
              </Button>
              <Button type={'submit'} color={'secondary'} width={'130px'} fontSize={12} disabled={sending}>
                {sending ? <Spinner2/> : null} 作成
              </Button>
            </ModalFooter>
          </form>
  );
}

export default DepartmentCreator;
