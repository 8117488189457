export default {
  header: {
    meeting: {
      iconColor              : '#4390D9',
      baseBackgroundColor    : '#A3AEC2',
      valueBackgroundColor   : '#4390D9',
      maxValueBackgroundColor: '#CC2A2A',
      fontColor              : '#FFF',
    },
    talking: {
      iconColor           : '#1AA872',
      baseBackgroundColor : '#A3AEC2',
      valueBackgroundColor: '#1AA872',
      fontColor           : '#FFF',
    },
    privacy: {
      iconColor           : '#F0B000',
      baseBackgroundColor : '#A3AEC2',
      valueBackgroundColor: '#F0B000',
      fontColor           : '#FFF',
    },
  },
}
