import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';
import {find, remove as _remove} from 'lodash-es';

export type DepartmentType = {
  id: number;
  title: string;
  memberLimit?: number;
  users: UserType[];
};

export type DepartmentsType = DepartmentType[];

// Stateの初期状態
const initialState: DepartmentType[] = [];

// Sliceを生成する
const slice = createSlice({
  name    : 'departments',
  initialState,
  reducers: {
    set   : (state, action: PayloadAction<DepartmentType[]>) => {
      return action.payload;
    },
    insert: (state, action: PayloadAction<DepartmentType>) => {
      if (!find(state, {id: action.payload.id})) {
        state = state.concat([action.payload]);
      }

      return state;
    },
    remove: (state, action: PayloadAction<DepartmentType>) => {
      _remove(state, {id: action.payload.id});
      return state;
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set, insert, remove} = slice.actions;
