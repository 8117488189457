import React, {useState} from 'react';
import LoginFormLayout from '../presentations/Login/LoginFormLayout';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import FormErrorText from '../presentations/Form/FormErrorText';
import {FormFieldset} from '../presentations/Form/FormFieldset';
import {FormInput} from '../presentations/Form/FormInput';
import Button from '../presentations/Button';
import Repository, {RecoverPasswordRepositoryInterface} from '../services/repository';
import ForgotPasswordFormReadText from '../presentations/ForgotPasswordReadText';
import {AxiosError, AxiosResponse} from 'axios';
import AlertMessage, {AlertMessageInterface} from '../presentations/AlertMessage';
import Spinner2 from '../presentations/Spinner2';
import AlertValidationErrorMessage from '../components/Alert/AlertValidationErrorMessage';
import AlertUnknownErrorMessage from '../components/Alert/AlertUnknownErrorMessage';
import {useTranslation} from 'react-i18next';

const ForgotPasswordForm: React.FC = () => {
  const {register, handleSubmit, errors} = useForm<RecoverPasswordRepositoryInterface>();
  const [alertMessage, setAlertMessage]  = useState<React.ReactElement>();
  const [sending, setSending]            = useState(false);
  const history                          = useHistory();
  const repository                       = new Repository();
  const {t}                              = useTranslation();

  const onSubmit = (post: RecoverPasswordRepositoryInterface): void => {
    console.log(post)

    setSending(true);

    repository.recoverPassword(post)
            .then(({data}: AxiosResponse<any>) => {
              console.log(data);
              history.push('forgot-password-sended');
            })
            .catch((error: AxiosError) => {
              if (error.response?.status === 400 && error.response.data.message.length) {
                setAlertMessage(<AlertValidationErrorMessage message={error.response.data.message}/>);

              } else {
                setAlertMessage(<AlertUnknownErrorMessage/>);

              }

              setSending(false);
            });
  };

  return (
          <LoginFormLayout title={t('Password Reset')} onSubmit={handleSubmit(onSubmit)}>
            <ForgotPasswordFormReadText>
              {t('Reset your password.')}<br/>
              {t('Please enter the email address you have registered with us.')}
            </ForgotPasswordFormReadText>
            {alertMessage}
            <FormFieldset>
              {errors.email && <FormErrorText text={t('Please enter this field.')}/>}
              <FormInput name={'email'}
                         placeholder={t('email address')}
                         register={register({required: true})}
                         error={errors.email}
              />
            </FormFieldset>

            <Button type={'submit'} width={'100%'} color={'secondary'} fontSize={14} disabled={sending}>
              {sending ? <Spinner2/> : null} {t('Send')}
            </Button>
          </LoginFormLayout>
  );
};

export default ForgotPasswordForm;
