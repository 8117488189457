import React from 'react';
import Spinner1 from '../presentations/Spinner1';
import styled from 'styled-components';

const Loader: React.FC = () => {
  return (
          <Wrapper>
            <Spinner1/>
          </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 30px 0;
  text-align: center;
`;

export default Loader;
