import React from 'react';
import logo from '../assets/img/logo.svg';
import {Link} from 'react-router-dom';

const Logo = (props: { width: number }) => {
  return (
          <Link to={'/'}>
            <img src={logo} width={props.width} alt="Smart all in"/>
          </Link>
  );
};

Logo.defaultProps = {
  width: 198,
}

export default Logo;
