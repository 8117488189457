import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface Headline1Interface {
  icon: string
  color: string;
  children: React.ReactNode
}

const Headline1: React.FC<Headline1Interface> = (props: Headline1Interface) => {
  return (
          <Wrapper color={props.color}>
            <Icon className={props.icon}/>
            {props.children}
          </Wrapper>
  );
};

const Wrapper = styled.h2<{ color: string }>`
  margin-bottom: 10px;
  padding-bottom: 6px;
  min-height: 34px;
  background: #F7FAFC;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  color: ${props => props.color};
  font-family: "Roboto";
  ${fontSize(14)};
  font-weight: 700;

 &::after {
  content: '';
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${props => props.color};
  position: absolute;
  left: 0;
  bottom: 0;
 }
`;

const Icon = styled.i`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Headline1;
