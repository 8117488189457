import React from 'react';
import styled from 'styled-components';
import mediaQuery from 'styled-media-query';

interface HeaderIndicatorInterface {
  children: React.ReactNode[];
}

const HeaderIndicator: React.FC<HeaderIndicatorInterface> = ({children: [icon, body]}) => {
  return (
          <Indicator>
            <IndicatorIcon>{icon}</IndicatorIcon>
            {body}
          </Indicator>
  );
};

const Indicator = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  ${mediaQuery.lessThan('medium')`
    padding-left: 10px;
    padding-right: 10px;
  `}
`;

const IndicatorIcon = styled.div`
  margin-right: 6px;
  font-size: 18px;
  line-height: 1;
`;

export default HeaderIndicator;
