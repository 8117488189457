import React, {useEffect, useState} from 'react';
import ModalHeader from '../presentations/ModalHeader';
import Repository, {SignupRepositoryInterface} from '../services/repository';
import store from '../stores';
import {useForm} from 'react-hook-form';
import UserProfileLayout from '../presentations/UserProfileLyaout';
import ModalFooter from '../presentations/ModalFooter';
import Button from '../presentations/Button';
import Spinner2 from '../presentations/Spinner2';
import ModalBody from '../presentations/ModalBody';
import {AxiosResponse} from 'axios';
import {UserType} from '../stores/authenticated';
import Padding from '../presentations/Padding';
import {Websocket} from '../services/websocket';
import {insert} from '../stores/users';
import AlertApiErrorMessage from '../presentations/AlertApiErrorMessage';
import UserProfileManagerFormFieldset from '../presentations/UserProfileManagerFormFieldset';
import {RoleType, set as setRoles} from '../stores/roles';

interface CreateUserProfileInterface {
  onClose: () => void
}

interface CreateUserProfileFormInterface {
  username: string
  firstName: string
  lastName: string
  departmentId: string
  role: string
  email: string
  password: string
  phone: string
}

const UserProfileCreater: React.FC<CreateUserProfileInterface> = (props) => {
  const repository                                  = new Repository();
  const [sending, setSending]                       = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage]   = useState<React.ReactElement | null>(null);
  const {register, handleSubmit, errors, getValues, setValue} = useForm<CreateUserProfileFormInterface>({
    defaultValues: {
      username    : '',
      firstName   : '',
      lastName    : '',
      departmentId: '',
      role        : 'user',
      email       : '',
      password    : '',
      phone       : '',
    }
  });

  const isRequiredName = (value: any) => {
    const values = getValues();
    return !!values.firstName || !!values.lastName;
  }

  const registerSetting = {
    username    : {required: true},
    firstName   : {validate: isRequiredName},
    lastName    : {validate: isRequiredName},
    departmentId: {required: true},
    role        : {required: true},
    email       : {required: true},
    password    : {required: true},
    phone       : {required: false},
  };

  useEffect(() => {
    repository.getRoles()
            .then(({data}: AxiosResponse<RoleType[]>) => {
              store.dispatch(setRoles(data));
              setValue('role', 'user');
            });
  }, []);

  const onSubmit = (formValue: CreateUserProfileFormInterface) => {
    const post: any   = formValue;
    post.departmentId = Number(post.departmentId);
    post.roles        = ['user'];
    delete post.role;

    setSending(true);

    console.log(post);

    repository.signup(post as SignupRepositoryInterface)
            .then(({data}: AxiosResponse<UserType>) => {
              store.dispatch(insert(data));
              Websocket.socket?.emit('getInfo', {});
              props.onClose();
            })
            .catch(({response}) => {
              console.log(response);
              setSending(false);
              setErrorAlertMessage(<AlertApiErrorMessage response={response} title={<p>登録に失敗しました。</p>}/>);

            })
            .finally(() => {
            });
  }

  const column2 = <UserProfileManagerFormFieldset register={register} registerSetting={registerSetting} errors={errors}/>;

  return (
          <form noValidate method={'post'} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader><h3>ユーザーを新規作成</h3></ModalHeader>
            <ModalBody>
              <Padding padding={'30px 40px 40px'}>
                <>
                  {errorAlertMessage}
                  <UserProfileLayout column2={column2}/>
                </>
              </Padding>
            </ModalBody>
            <ModalFooter>
              <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} fontSize={12} onClick={props.onClose}>
                キャンセル
              </Button>
              <Button type={'submit'} color={'secondary'} width={'130px'} fontSize={12} disabled={sending}>
                {sending ? <Spinner2/> : null} 作成
              </Button>
            </ModalFooter>
          </form>
  );
}

export default UserProfileCreater;
