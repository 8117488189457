import React from 'react';
import styled from 'styled-components';
import Headline1 from './Headline1';

const TalkingListHeader = () => {
  return (
          <Wrapper>
            <Headline1 icon={'soi-talking'} color={'#1AA872'}>Talking</Headline1>
            {/*<NewMeeting />*/}
          </Wrapper>
  );
};

const Wrapper = styled.header`
`;

export default TalkingListHeader;
