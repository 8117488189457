import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {compact, map} from 'lodash-es';
import MeetingListItem from './MeetingListItem';
import styled from 'styled-components';

const MeetingList: React.FC = () => {
  const meetings = useSelector((state: AppState) => state.meetings);

  const meetingList = compact(map(meetings, (meeting, index) => {
    if (meeting.users.length) {
      return <MeetingListItem key={index} meeting={meeting}/>
    }
  }));

  return (
          <Wrapper>
            {meetingList}
          </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export default MeetingList;
