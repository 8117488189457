import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type RoleType = {
  id: number
  name: string
};

// Stateの初期状態
const initialState: RoleType[] = [];

// Sliceを生成する
const slice = createSlice({
  name    : 'roles',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<RoleType[]>) => {
      return action.payload;
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set} = slice.actions;
