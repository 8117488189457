import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export interface ReceiveTalkingRequestInterface {
  createRoomDto: { guestUserId: number, hostUserId: number }
  hostUser: UserType
  guestUser: UserType
  callHistory: any
}

export interface ModalReceiveTalkingRequestInterface {
  state: 'open' | 'close' | ''
  data: ReceiveTalkingRequestInterface | null
}

// Stateの初期状態
const initialState: ModalReceiveTalkingRequestInterface = {
  state: '',
  data : null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'modal-receive-talking-request',
  initialState,
  reducers: {
    open     : (state) => {
      return {...state, state: 'open'};
    },
    close    : (state) => {
      return {...state, state: 'close'};
    },
    setData  : (state, action: PayloadAction<ReceiveTalkingRequestInterface>) => {
      return {...state, data: action.payload};
    },
    clearData: (state) => {
      return {...state, data: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close, setData, clearData} = slice.actions;
