import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {compact, map} from 'lodash-es';
import TalkingListItem from './TalkingListItem';
import styled from 'styled-components';

const TalkingList: React.FC = () => {
  const talkings = useSelector((state: AppState) => state.talkings);

  const talkingList = compact(map(talkings, (talking, index) => {
    if (talking.users.length) {
      return <TalkingListItem key={index} talking={talking}/>
    }
  }));

  return (
          <Wrapper>
            {talkingList}
          </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export default TalkingList;
