import {createSlice} from '@reduxjs/toolkit';

export interface UserMenuInterface {
  open: boolean
}

// Stateの初期状態
const initialState: UserMenuInterface = {
  open: false
};

// Sliceを生成する
const slice = createSlice({
  name    : 'userMenu',
  initialState,
  reducers: {
    userMenuOpen  : (state) => {
      return {...state, open: true};
    },
    userMenuClose : (state) => {
      return {...state, open: false};
    },
    userMenuToggle: (state) => {
      return {...state, open: !state.open};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {userMenuOpen, userMenuClose, userMenuToggle} = slice.actions;
