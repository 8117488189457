import ChangePassword from '../containers/ChangePassword';
import Modal from './Modal';
import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {close as closeModalChangePassword} from '../stores/modal-change-password';

const customStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
    zIndex         : 1000,
  },
  content: {
    padding     : '0',
    width       : 'calc(100% - 30px)',
    maxWidth    : '350px',
    height      : 'auto',
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    transform   : 'translate(-50%, -50%)',
    border      : 'none',
    borderRadius: '6px',
    boxShadow   : '0 4px 20px rgba(0, 0, 0, .32)',
  }
}

const ModalChangePassword: React.FC = () => {
  const ref: any   = useRef();
  const modalState = useSelector((state: AppState) => state.modalChangePassword.state);

  useEffect(() => {
    if (modalState === 'open') {
      ref.current.open();
    } else if (modalState === 'close') {
      ref.current.close();
    }
  }, [modalState]);

  const handleClose = () => {
    store.dispatch(closeModalChangePassword());
  }

  return (
          <Modal ref={ref} style={customStyle} onClose={handleClose}><ChangePassword/></Modal>
  )
}

export default ModalChangePassword
