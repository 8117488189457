import React, {ForwardRefExoticComponent, useImperativeHandle} from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

interface ModalInterface {
  closeButton?: boolean
  style?: Object
  children: React.ReactElement
  ref?: any
  onClose?: () => void
  maxWidth?: string
}

ReactModal.setAppElement('#root');
const Modal: ForwardRefExoticComponent<ModalInterface> = React.forwardRef((props, ref) => {
  const [modalProps, setModalProps] = React.useState({});
  const [modalIsOpen, setIsOpen]    = React.useState(false);

  const handleOpen = (props: any) => {
    setModalProps(props);
    setIsOpen(true);
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      close();
    }
  }

  const close = () => {
    setIsOpen(false);
  }

  useImperativeHandle(ref, () => {
    return {
      open : handleOpen,
      close: close
    };
  });

  return (
          <ReactModal isOpen={modalIsOpen} ref={ref} {...props}>
            <Wrapper>
              {props.closeButton ? <Close type={'button'} onClick={handleClose}><i className={'soi-close'}></i></Close> : false}
              {React.cloneElement(props.children, {...modalProps})}
            </Wrapper>
          </ReactModal>
  );
});

Modal.defaultProps = {
  closeButton: true,
  style      : {
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, .8)',
      zIndex         : 1000,
    },
    content: {
      padding     : '0',
      width       : 'calc(100% - 30px)',
      maxWidth    : '550px',
      height      : 'auto',
      top         : '50%',
      left        : '50%',
      right       : 'auto',
      bottom      : 'auto',
      transform   : 'translate(-50%, -50%)',
      border      : 'none',
      borderRadius: '6px',
      boxShadow   : '0 4px 20px rgba(0, 0, 0, .32)',
      overflow    : 'hidden',
    }
  },
}

const Wrapper = styled.section`
  min-height: 100px;
  position: relative;
`;

const Close = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
`;

export default Modal;
