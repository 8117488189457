import {createSlice} from '@reduxjs/toolkit';

export interface ModalCreateRoomNowInterface {
  open: boolean
}

// Stateの初期状態
const initialState: ModalCreateRoomNowInterface = {
  open: false
};

// Sliceを生成する
const slice = createSlice({
  name    : 'modal-create-room-now',
  initialState,
  reducers: {
    open : (state) => {
      return {...state, open: true};
    },
    close: (state) => {
      return {...state, open: false};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
