import * as React from 'react';
import {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import ModalHeader from '../../presentations/ModalHeader';
import ModalBody from '../../presentations/ModalBody';
import store, {AppState} from '../../stores';
import {close} from '../../stores/my-profile-avatar-select-modal';
import {useSelector} from 'react-redux';
import ModalFooter from '../../presentations/ModalFooter';
import Button from '../../presentations/Button';
import MyProfileAvatarSelect from './MyProfileAvatarSelect';
import Padding from '../../presentations/Padding';
import {
  setCurrent as setCurrentMyProfileAvatarSelect,
  setSelect as setSelectMyProfileAvatarSelect,
  setPreview as setPreviewMyProfileAvatarSelect,
} from '../../stores/my-profile-avatar-select';

const MyProfileAvatarSelectModal: React.FC = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.myProfileAvatarSelectModal.open);
  const preview  = useSelector((state: AppState) => state.myProfileAvatarSelect.preview);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    // store.dispatch(setPreviewMyProfileAvatarSelect(''));
    store.dispatch(setSelectMyProfileAvatarSelect(''));
    store.dispatch(close());
  }

  const handleConfirm = () => {
    if (preview) {
      store.dispatch(setSelectMyProfileAvatarSelect(preview));
    } else {
      store.dispatch(setCurrentMyProfileAvatarSelect(''));
    }

    store.dispatch(close());
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <>
              <ModalHeader><h3>プロフィール画像の選択</h3></ModalHeader>
              <ModalBody>
                <Padding padding={'30px 40px 40px'}>
                  <MyProfileAvatarSelect/>
                </Padding>
              </ModalBody>
              <ModalFooter>
                <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} onClick={handleClose}>
                  キャンセル
                </Button>
                <Button type={'submit'} color={'secondary'} width={'130px'} onClick={handleConfirm}>
                  確定
                </Button>
              </ModalFooter>
            </>
          </Modal>

  );
}

export default MyProfileAvatarSelectModal;

