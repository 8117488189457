import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {DepartmentType} from './departments';

type Role = {
  id: number
  name: string
}
export type AccessTokenType = string;
export type UserType = {
  id?: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  department: DepartmentType;
  privacyStatus: boolean,
  isActive: boolean,
  avatar: string;
  roles: Role[];
  room: any;
};

export type AuthenticatedType = {
  accessToken: AccessTokenType | null;
  userId: number | null;
  user: UserType | null;
  privacyStatus: boolean;
  inRoom: boolean;
}

// Stateの初期状態
const initialState: AuthenticatedType = {
  accessToken  : Cookies.get('accessToken') || '',
  userId       : parseInt(Cookies.get('userId') as string) || null,
  user         : null,
  privacyStatus: false,
  inRoom       : false,
};

export interface LoggedInPayloadActionInterface {
  accessToken: AccessTokenType,
  userId: number | null,
  user: UserType,
  privacyStatus: boolean
}

// Sliceを生成する
const slice = createSlice({
  name    : 'authenticated',
  initialState,
  reducers: {
    loggedIn           : (state, action: PayloadAction<LoggedInPayloadActionInterface>) => {
      return {
        ...state,
        accessToken  : action.payload.accessToken,
        userId       : action.payload.userId,
        user         : action.payload.user,
        privacyStatus: action.payload.user?.privacyStatus as boolean,
      };
    },
    loggedOut          : (state) => {
      return {...state, accessToken: null, user: null, privacyStatus: false, inRoom: false};
    },
    privacyStatusOn    : (state) => {
      return {...state, privacyStatus: true};
    },
    privacyStatusOff   : (state) => {
      return {...state, privacyStatus: false};
    },
    privacyStatusToggle: (state) => {
      return {...state, privacyStatus: !state.privacyStatus};
    },
    updateUser         : (state, action: PayloadAction<UserType>) => {
      const inRoom = !!action.payload.room;
      return {...state, user: action.payload, inRoom}
    }
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {loggedIn, loggedOut, privacyStatusOn, privacyStatusOff, privacyStatusToggle, updateUser} = slice.actions;
