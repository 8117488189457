import React from 'react';
import styled, {keyframes} from 'styled-components';

interface Spinner1Interface {

}

const Spinner1: React.FC<Spinner1Interface> = (props) => {
  return (
          <Wrapper>
            <div></div><div></div><div></div>
          </Wrapper>
  );
}

const Animation = keyframes`
  0%, 80%, 100% { 
    transform: scale(.75);
    opacity: .25;
  } 40% { 
    transform: scale(1);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  text-align: center;

  > div {
    margin-left: 2px;
    margin-right: 2px;
    width: 10px;
    height: 10px;
    background-color: #4E6693;
    border-radius: 100%;
    display: inline-block;
    animation: ${Animation} 1.4s infinite ease-in-out both;
  }
  
  > :nth-child(1) {
    animation-delay: -0.32s;
  }
  
  > :nth-child(2) {
    animation-delay: -0.16s;
  }
  
  > :nth-child(3) {
  }
`;

export default Spinner1;
