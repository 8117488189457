import React, {useEffect, useRef} from 'react';
import Modal from '../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {close} from '../stores/user-profile-creater-modal';
import {UserType} from '../stores/authenticated';
import UserProfileUpdater from './UserProfileUpdater';
import UserProfileCreater from './UserProfileCreater';

const customStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
    zIndex         : 1000,
  },
  content: {
    padding     : '0',
    width       : 'calc(100% - 30px)',
    maxWidth    : '350px',
    height      : 'auto',
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    transform   : 'translate(-50%, -50%)',
    border      : 'none',
    borderRadius: '6px',
    boxShadow   : '0 4px 20px rgba(0, 0, 0, .32)',
  }
}

const UserProfileCreaterModal: React.FC = () => {
  const ref  = useRef<any>();
  const open = useSelector((state: AppState) => state.userProfileCreaterModal.open);
  const user = useSelector((state: AppState) => state.userProfileCreaterModal.user as UserType);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} style={customStyle} onClose={handleClose}>
            {user ? <UserProfileUpdater user={user} onClose={handleClose}/> : <UserProfileCreater onClose={handleClose}/>}
          </Modal>
  )
}

export default UserProfileCreaterModal;
