import styled from 'styled-components';
import React from 'react';

export const FormFieldset = (props: { children: React.ReactNode }) => {
  return (
          <Fieldset>
            {props.children}
          </Fieldset>
  );
}

const Fieldset = styled.fieldset`
  &:not(:first-child) {
    margin-top: 20px;
  }
  
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
