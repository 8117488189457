import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type SystemType = {
  systemName: string
  bgImage: string
  meetingsLimit: number
  usersLimit: number
}

// Stateの初期状態
const initialState: SystemType = {
  systemName   : '',
  bgImage      : '',
  meetingsLimit: 0,
  usersLimit   : 0,
};

// Sliceを生成する
const slice = createSlice({
  name    : 'system',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<SystemType>) => {
      return {
        ...state,
        systemName   : action.payload.systemName,
        bgImage      : action.payload.bgImage,
        usersLimit   : action.payload.usersLimit,
        meetingsLimit: action.payload.meetingsLimit,
      };
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set} = slice.actions;
