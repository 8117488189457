import React from 'react';
import LoginFormLayout from '../presentations/Login/LoginFormLayout';
import {useHistory} from 'react-router';
import Button from '../presentations/Button';
import ForgotPasswordFormReadText from '../presentations/ForgotPasswordReadText';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const ForgotPasswordFormSended: React.FC = () => {
  const {handleSubmit} = useForm();
  const history        = useHistory();
  const {t}            = useTranslation();

  const onSubmit = (): void => {
    history.push('login');
  };

  return (
          <LoginFormLayout title={t('Sent')} onSubmit={handleSubmit(onSubmit)}>
            <ForgotPasswordFormReadText>
              {t('Password reset instructions have been sent to the specified email address.')}{t('Please check your email address.')}
            </ForgotPasswordFormReadText>

            <Button type={'submit'} width={'100%'} color={'secondary'} fontSize={14}>{t('Go to login page')}</Button>
          </LoginFormLayout>
  );
};

export default ForgotPasswordFormSended;
