import React from 'react';
import styled from 'styled-components';

interface PageContainerInterface {
  children: React.ReactElement
}

const PageContainer: React.FC<PageContainerInterface> = (props) => {
  return (
          <Wrapper>{props.children}</Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 40px;
  background-color: #E1E2E8;
  height: 100vh;
`;

export default PageContainer;
