import React, {useEffect} from 'react';
import Header from '../presentations/Header';
import FullScreenContainer from '../presentations/FullScreenContainer';
import LoginForm from '../containers/LoginForm';
import Cookies from 'js-cookie';
import store, {AppState} from '../stores';
import {AccessTokenType, loggedOut} from '../stores/authenticated';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const Login = () => {
  const {t} = useTranslation();
  const accessToken = useSelector((state: AppState) => state.authenticated.accessToken as AccessTokenType);
  const history = useHistory()

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${t('Login')}`;
    if (accessToken) {
      history.push('/')
    }
  }, []);

  return (
          <>
            <Header/>

            <FullScreenContainer centering={true}>
              <main>
                <LoginForm/>
              </main>
            </FullScreenContainer>
          </>
  );
};

export default Login;
