import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';
import {remove as _remove} from 'lodash-es';

export type UsersType = UserType[];

// Stateの初期状態
const initialState: UsersType = [];

// Sliceを生成する
const slice = createSlice({
  name    : 'users',
  initialState,
  reducers: {
    set   : (state, action: PayloadAction<UserType[]>) => {
      return action.payload;
    },
    insert: (state, action: PayloadAction<UserType>) => {
      return state.concat([action.payload]);
    },
    remove: (state, action: PayloadAction<UserType>) => {
      _remove(state, {id: action.payload.id});
      return state;
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set, insert, remove} = slice.actions;
