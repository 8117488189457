import React, {useEffect, useRef} from 'react';
import Modal from '../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {close} from '../stores/sending-talking-request-modal';
import SendingTalkingRequest from '../presentations/SendingTalkingRequest';
import {Websocket} from '../services/websocket';

const SendingTalkingRequestModal = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.sendingTalkingRequestModal.open);
  const data     = useSelector((state: AppState) => state.sendingTalkingRequestModal.data);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
    Websocket.socket?.emit('cancelTalkingRequest', {
      createRoomDto: data?.createRoomDto,
      callHistory  : data?.callHistory,
    });
  }

  return (
          <Modal ref={ref} closeButton={false}>
            <SendingTalkingRequest data={data} onClose={handleClose}/>
          </Modal>
  );
};

export default SendingTalkingRequestModal;
