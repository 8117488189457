import React from 'react';
import styled, {keyframes} from 'styled-components';

interface Spinner2Interface {

}

const Spinner2: React.FC<Spinner2Interface> = (props) => {
  return (
          <Icon className={'soi-spinner1'}/>
  );
}

const Animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Icon = styled.i`
  display: inline-block;
  animation: ${Animation} .75s infinite linear;
`;

export default Spinner2;
