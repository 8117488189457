import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TalkingRequestAcceptedInterface} from '../pages/Home';

export interface RejectMesssageModalInterface {
  open: boolean
  data: TalkingRequestAcceptedInterface | null
}

// Stateの初期状態
const initialState: RejectMesssageModalInterface = {
  open: false,
  data: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'reject-message-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<TalkingRequestAcceptedInterface>) => {
      return {...state, open: true, data: action.payload};
    },
    close: (state) => {
      return {...state, open: false, data: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
