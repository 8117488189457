import React, {useEffect, useRef} from 'react';
import Modal from '../presentations/Modal';
import ReceiveTalkingRequest from '../presentations/ReceiveTalkingRequest';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';

const ModalReceiveTalkingRequest = () => {
  const ref: any = useRef();
  const state    = useSelector((state: AppState) => state.modalReceiveTalkingRequest.state);

  useEffect(() => {
    if (state === 'open') {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [state]);

  return (
          <Modal ref={ref} closeButton={false}>
            <ReceiveTalkingRequest/>
          </Modal>
  );
};

export default ModalReceiveTalkingRequest;
