import React from 'react';
import styled from 'styled-components';
import Headline1 from './Headline1';

const MeetingListHeader = () => {
  return (
          <Wrapper>
            <Headline1 icon={'soi-meeting'} color={'#5590D3'}>Meeting</Headline1>
          </Wrapper>
  );
};

const Wrapper = styled.header`
  position: relative;
`;

export default MeetingListHeader;
