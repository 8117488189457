import React from 'react';
import styled from 'styled-components';

interface SystemManagerLayoutInterface {
  sidebar: React.ReactElement
  main: React.ReactElement
}

const SystemManagerLayout: React.FC<SystemManagerLayoutInterface> = (props) => {
  return (
          <Wrapper>
            <Sidebar>{props.sidebar}</Sidebar>
            <Main>{props.main}</Main>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 270px 1fr;
`;

const Sidebar = styled.div`
  background-color: #F9F9FA;
`;

const Main = styled.main`
  padding: 30px 0 0 40px;
  background-color: #fff;
  overflow: hidden;
`;

export default SystemManagerLayout;
