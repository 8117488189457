import * as React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../stores';
import Avatar from '../../presentations/Avatar';
import styled from 'styled-components';
import {fontSize} from '../utility/Mixin';

const RejectMessage: React.FC = () => {
  const guestUser = useSelector((state: AppState) => state.rejectMessageModal.data?.callHistory.guestUser);
  const message   = useSelector((state: AppState) => state.rejectMessageModal.data?.rejectMessage);

  return (
          <>
            {guestUser && <User>
              <Avatar user={guestUser} size={56}/>
              <Text>
                <Label><i className={'soi-message'}></i> メッセージ</Label>
                <Message>{message}</Message>
              </Text>
            </User>}
          </>
  );
}

const User = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-left: 10px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  color: #1AA872;
`;

const Message = styled.p`
  ${fontSize(18)};
  font-weight: bold;
`;

export default RejectMessage;

