import React, {createContext, useEffect, useRef, useState} from 'react';
import Popup from '../presentations/Popup';
import OutsideClickHandler from 'react-outside-click-handler';

interface PopupStoreInterface {
  children: React.ReactElement
}

export const PopupContext = createContext<any>({});

const PopupStoreContext: React.FC<PopupStoreInterface> = (props) => {
  const ref: any                    = useRef();
  const [popupState, setPopupState] = useState({
    open    : false,
    position: 'left-top',
    children: null,
    event   : null,
    design  : 'normal',
    width   : '200px',
  });

  const [popupSize, setPopupSize] = useState({width: 0, height: 0});

  useEffect(() => {
    if (popupState.open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [popupState.open]);

  useEffect(() => {
    // console.log(ref.current.offsetHeight);
    setPopupSize({
      width : ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    })
  }, [popupState.children]);

  const handleClose = () => {
    setPopupState((state: any) => {
      return {
        ...state,
        open    : false,
        children: null,
        event   : null,
        design  : 'normal'
      }
    });
  }

  const getPopupX = () => {
    try {
      let x = (popupState.event as any).pageX - 30;

      if (getWindowDistance().x < 0) {
        x = (popupState.event as any).pageX - popupSize.width + 30;
      }

      return x;
    } catch (e) {
      return 0;
    }
  }

  const getPopupY = () => {
    try {
      let y = (popupState.event as any).pageY + 20;

      console.log(getWindowDistance());

      if (getWindowDistance().y < 0) {
        y = (popupState.event as any).pageY - popupSize.height - 15;
      }

      return y;
    } catch (e) {
      return 0;
    }
  }

  const getPosition = () => {
    try {
      const windowDistance = getWindowDistance();

      if (windowDistance.x > 0 && windowDistance.y > 0) {
        return 'left-top';
      } else if (windowDistance.x < 0 && windowDistance.y > 0) {
        return 'right-top';
      } else if (windowDistance.x > 0 && windowDistance.y < 0) {
        return 'left-bottom';
      } else if (windowDistance.x < 0 && windowDistance.y < 0) {
        return 'right-bottom';
      }
    } catch (e) {
      return 'left-top';
    }
  }

  const getWindowDistance = () => {
    const x = (popupState.event as any).pageX - 30;
    const y = (popupState.event as any).pageY + 30;

    return {
      x: window.innerWidth - (x + popupSize.width),
      y: window.innerHeight - (y + popupSize.height),
    }
  }

  return (
          <PopupContext.Provider value={[popupState, setPopupState]}>
            {props.children}
            <OutsideClickHandler onOutsideClick={handleClose}>
              <Popup width={popupState.width} x={getPopupX()} y={getPopupY()} position={getPosition()} design={popupState.design} ref={ref}>
                <>{popupState.children}</>
              </Popup>
            </OutsideClickHandler>
          </PopupContext.Provider>
  );
}

export default PopupStoreContext;
