import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DepartmentType} from './departments';

export interface DepartmentCreatorModalInterface {
  open: boolean
  department: DepartmentType | null
}

// Stateの初期状態
const initialState: DepartmentCreatorModalInterface = {
  open      : false,
  department: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'department-creater-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<DepartmentType | null>) => {
      return {...state, open: true, department: action.payload};
    },
    close: (state) => {
      return {...state, open: false, department: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
