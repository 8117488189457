import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
import App from './App';
import {Provider} from 'react-redux';
import store from './stores';
import ServiceWorker from './services/service-worker';
import './i18n';

ServiceWorker.register();

if ('Notification' in window) {
  Notification.requestPermission();
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
