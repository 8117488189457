import React from 'react';
import styled from 'styled-components';

interface ModalFooterInterface {
  children: React.ReactElement | React.ReactNode
}

const ModalFooter: React.FC<ModalFooterInterface> = ({children}) => {
  return (
          <Wrapper>{children}</Wrapper>
  );
}


export const Wrapper = styled.footer`
  padding: 20px 40px;
  border-top: 1px solid #D5D5D5;
  text-align: right;
`;

export default ModalFooter;
