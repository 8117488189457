import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export interface UserProfileCreaterModalInterface {
  open: boolean
  user: UserType | null
}

// Stateの初期状態
const initialState: UserProfileCreaterModalInterface = {
  open: false,
  user: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'user-profile-creater-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<UserType | null>) => {
      return {...state, open: true, user: action.payload};
    },
    close: (state) => {
      return {...state, open: false};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
