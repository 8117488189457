import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface List1Interface {
  children: React.ReactElement
}

const SelectList: React.FC<List1Interface> = (props) => {
  return (
          <Ul>{props.children}</Ul>
  );
}

interface List1ItemInterface {
  children: React.ReactElement | React.ReactNode
  icon?: string
  onClick?: () => void
}

export const SelectListItem: React.FC<List1ItemInterface> = (props) => {
  return (
          <Li onClick={props.onClick}>
            {props.icon ? <Icon className={props.icon}/> : null}
            {props.children}
          </Li>
  );
}

const Ul = styled.ul`
  ${fontSize(12)}
  
  > :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
  > :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const Li = styled.li`
  padding: 1em 30px;
  background-color: #fff;
  cursor: pointer;
  
  &:hover {
    color: #fff;
    background-color: #4E6693;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid #D5D5D5;
  }
`;

const Icon = styled.i`
  margin-left: -.5em;
  margin-right: 1em;
`;

export default SelectList;
