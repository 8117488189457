import React, {useRef} from 'react';
import Dropdown, {DropdownMenu, DropdownMenuItem, DropdownTrigger} from './Dropdown';
import {map} from 'lodash-es';
import {SelectOptionInterface} from '../types/select-option.interface';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {select} from '../stores/user-status-filter';

const UserStateFilter: React.FC = () => {
  const filterList  = useSelector((state: AppState) => state.userStatusFilter.options);
  const selected    = useSelector((state: AppState) => state.userStatusFilter.selected);
  const dropdownRef = useRef<any>();

  const handleSelect = (filter: SelectOptionInterface) => {
    store.dispatch(select(filter))
    dropdownRef.current.close();
  }

  const filterDropdownMenu = map(filterList, (filter: SelectOptionInterface, index) => {
    const isSelected = filter.id === selected.id;

    return <DropdownMenuItem type={'button'} key={index} onClick={() => handleSelect(filter)} data-selected={isSelected}>
      {filter.name}
    </DropdownMenuItem>;
  });

  return (
          <Dropdown ref={dropdownRef}>
            <DropdownTrigger type={'button'}>
              {selected.name}
            </DropdownTrigger>
            <DropdownMenu>
              {filterDropdownMenu}
            </DropdownMenu>
          </Dropdown>
  );
};

export default UserStateFilter;
