import React from 'react';
import variables from '../themes/default/variables';
import HeaderIndicator from './HeaderIndicator';
import HeaderIndicatorBar from './HeaderIndicatorBar';
import HeaderIndicatorIcon from './HeaderIndicatorIcon';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {each} from 'lodash-es';

const HeaderTalking: React.FC = () => {
  const talkings = useSelector((state: AppState) => state.talkings);

  const getIconStyle = () => {
    const color = variables.header.talking.iconColor;
    return {color};
  }

  const getBarStyle = () => {
    const baseWidth                                                         = 'auto';
    const valueWidth                                                        = '100%';
    const {baseBackgroundColor, fontColor, iconColor, valueBackgroundColor} = variables.header.talking;

    return {baseWidth, baseBackgroundColor, valueWidth, valueBackgroundColor, fontColor, iconColor};
  }

  const getData = () => {
    return {
      value: countMember()
    }
  }

  const countMember = () => {
    let count = 0;
    each(talkings, talking => {
      if (talking.users.length) {
        count++;
      }
    });
    return count;
  }

  const getLabel = () => {
    const data = getData();
    return String(data.value);
  }

  return (
          <HeaderIndicator>
            <HeaderIndicatorIcon styleProps={getIconStyle()}><i className={'soi-talking'}></i></HeaderIndicatorIcon>
            <HeaderIndicatorBar styleProps={getBarStyle()} label={getLabel()}/>
          </HeaderIndicator>
  );
};

export default HeaderTalking;
