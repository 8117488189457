import React, {useEffect} from 'react';
import Header from '../presentations/Header';
import FullScreenContainer from '../presentations/FullScreenContainer';
import ResetPasswordFormComplete from '../presentations/ResetPasswordFormComplete';
import {useTranslation} from 'react-i18next';

const ResetPasswordComplete = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${t('Password Reset')}`;
  }, []);

  return (
          <>
            <Header/>

            <FullScreenContainer centering={true}>
              <main>
                <ResetPasswordFormComplete/>
              </main>
            </FullScreenContainer>
          </>
  );
};

export default ResetPasswordComplete;
