import React, {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/room-share-modal';
import RoomShare from './RoomShare';

const RoomShareModal = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.roomShareModal.open);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <RoomShare onClose={handleClose}/>
          </Modal>
  );
};

export default RoomShareModal;
