import React from 'react';
import Button from './Button';
import Spinner2 from './Spinner2';
import styled from 'styled-components';
import {FormInput} from './Form/FormInput';
import Padding from './Padding';
import FormSelectPreview from './Form/FormSelectPreview';
import {GetSystemRepositoryInterface} from '../services/repository';
import FormErrorText from './Form/FormErrorText';

interface SystemManagerSettingFormFieldsetInterface {
  sending: boolean
  register: any
  errors: any
  data: GetSystemRepositoryInterface
  disabled: {
    usersLimit   : boolean
    meetingsLimit: boolean
  }
}

const SystemManagerSettingFormFieldset: React.FC<SystemManagerSettingFormFieldsetInterface> = (props) => {

  return (
          <>
            <Field>
              <FieldLabel>システム名</FieldLabel>
              <FieldInput>
                {props.errors.systemName && <FormErrorText text="必須入力です"/>}
                <FormInput name={'systemName'} register={props.register({required: true})} error={props.errors.system} size={'l'}/>
              </FieldInput>
            </Field>

            <Field>
              <FieldLabel>ユーザーの上限</FieldLabel>
              <FieldInput>
                {props.errors.usersLimit && <FormErrorText text="必須入力です"/>}
                <FormInput name={'usersLimit'} register={props.register()} disabled={props.disabled.usersLimit} size={'s'}/>
              </FieldInput>
            </Field>

            <Field>
              <FieldLabel>ミーティングに参加できるユーザーの上限</FieldLabel>
              <FieldInput>
                {props.errors.meetingsLimit && <FormErrorText text="必須入力です"/>}
                <FormInput name={'meetingsLimit'} register={props.register()} disabled={props.disabled.meetingsLimit} size={'s'}/>
              </FieldInput>
            </Field>

            <Field>
              <FieldLabel>背景画像</FieldLabel>
              <FieldInput>
                <FormSelectPreview name={'bgImage'} register={props.register()} preview={props.data.bgImage}/>
                <Padding padding={'5px 0 0 0'}><small>※4MBまでの画像がアップロードできます</small></Padding>
              </FieldInput>
            </Field>

            <Action>
              <Button type={'submit'} color={'secondary'} fontSize={12} disabled={props.sending}>
                {props.sending ? <Spinner2/> : null} 変更を保存
              </Button>
            </Action>
          </>
  );
};

const Field = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const FieldLabel = styled.div`
  //padding-top: .85em;
  padding-right: 1em;
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  font-weight: bold;
`;

const FieldInput = styled.div`
  flex: 1;
`;

const Action = styled.div`
  margin-top: 30px;
`;

export default SystemManagerSettingFormFieldset;
