import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type MyProfileSwitchType = 'VIEW' | 'EDIT';

export interface MyProfileSwitchInterface {
  flg: MyProfileSwitchType
}

// Stateの初期状態
const initialState: MyProfileSwitchInterface = {
  flg: 'VIEW',
};

// Sliceを生成する
const slice = createSlice({
  name    : 'my-profile-switch',
  initialState,
  reducers: {
    change: (state, action: PayloadAction<MyProfileSwitchType>) => {
      return {...state, flg: action.payload};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {change} = slice.actions;
