import {createSlice} from '@reduxjs/toolkit';

export interface ModalChangePasswordInterface {
  state: 'open' | 'close' | ''
}

// Stateの初期状態
const initialState: ModalChangePasswordInterface = {
  state: '',
};

// Sliceを生成する
const slice = createSlice({
  name    : 'modal-change-password',
  initialState,
  reducers: {
    open : (state) => {
      return {...state, state: 'open'};
    },
    close: (state) => {
      return {...state, state: 'close'};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
