import {Redirect, Route, RouteProps} from 'react-router';
import React from 'react';
import Authentication from './services/authentication';
import {useSelector} from 'react-redux';
import {AccessTokenType, UserType} from './stores/authenticated';
import {AppState} from './stores';

const AdminRoute: React.FC<RouteProps> = props => {
  const user           = useSelector((state: AppState) => state.authenticated.user as UserType);
  const accessToken    = useSelector((state: AppState) => state.authenticated.accessToken as AccessTokenType);
  const authentication = new Authentication();

  const isAuthenticated = () => {
    return authentication.isAdminValid(accessToken, user);
  }

  return (
          <Route
                  render={innerProps =>
                          isAuthenticated() ? (
                                  <Route {...props} />
                          ) : (
                                  <Redirect
                                          to={{
                                            pathname: '/login',
                                            state   : {from: innerProps.location}
                                          }}
                                  />
                          )
                  }
          />
  )
}

export default AdminRoute;
