import React, {useEffect, useState} from 'react';
import PageContainer from '../presentations/PageContainer';
import SystemManagerHeader from '../presentations/SystemManagerHeader';
import SystemManagerMenu from '../presentations/SystemManagerMenu';
import SystemManagerLayout from '../presentations/SystemmanagerLayout';
import Headline2 from '../presentations/Headline2';
import SystemManagerSettingFormFieldset from '../presentations/SystemManagerSettingFormFieldset';
import {useForm} from 'react-hook-form';
import Repository, {
  GetSystemRepositoryInterface,
  UpdateDepartmentRepositoryInterface,
  UpdateSystemRepositoryInterface
} from '../services/repository';
import Padding from '../presentations/Padding';
import Spinner1 from '../presentations/Spinner1';
import {AxiosError} from 'axios';
import AlertApiErrorMessage from '../presentations/AlertApiErrorMessage';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {UserType} from '../stores/authenticated';
import {find} from 'lodash-es';
import AlertMessage from '../presentations/AlertMessage';

const SystemManagerSetting: React.FC = () => {
  const systemName = useSelector((state: AppState) => state.system.systemName);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${systemName}`;
  }, [systemName]);

  return (
          <PageContainer>
            <>
              <SystemManagerHeader/>
              <SystemManagerLayout sidebar={<SystemManagerMenu/>} main={<SystemManagerSettingMainBody/>}/>
            </>
          </PageContainer>
  );
};

export default SystemManagerSetting;

const SystemManagerSettingMainBody: React.FC = () => {
  const repository                                 = new Repository();
  const [data, setData]                            = useState<GetSystemRepositoryInterface | null>(null);
  const [sending, setSending]                      = useState<boolean>(false);
  const [alertMessage, setAlertMessage]            = useState<React.ReactElement | null>(null);
  const user                                       = useSelector((state: AppState) => state.authenticated.user as UserType);
  const {register, setValue, handleSubmit, errors} = useForm<any>({
    defaultValues: {
      systemName   : '',
      meetingsLimit: '',
      usersLimit   : '',
      bgImage      : null
    }
  });

  const isAdmin = () => {
    return !!find(user.roles, {name: 'admin'})
  }

  const disabled = {
    usersLimit   : !isAdmin(),
    meetingsLimit: !isAdmin()
  }

  useEffect(() => {
    repository.getSystem()
            .then(({data}) => {
              console.log(data);
              setData(data);
              setValue('systemName', data.systemName);
              setValue('meetingsLimit', data.meetingsLimit);
              setValue('usersLimit', data.usersLimit);
            })
            .catch(error => {
              console.log(error);
            });
  }, []);

  const onSubmit = (post: any) => {
    console.log(post);

    setSending(true);
    repository.updateSystem(post)
            .then(({data}) => {
              console.log(data);
              setAlertMessage(<AlertMessage level={'success'} body={'更新しました。'}/>);
            })
            .catch(({response}: AxiosError) => {
              console.log(response);
              setSending(false);
              setAlertMessage(<AlertApiErrorMessage response={response} title={<p>更新に失敗しました。</p>}/>);
            })
            .finally(() => {
              setSending(false);
            });
  }

  return (
          <Padding padding={'0 40px 0 0'}>
            <>
              <h2><Headline2 children={'システム設定'}/></h2>
              {data ? <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {alertMessage}
                  <SystemManagerSettingFormFieldset register={register} errors={errors} sending={sending} data={data}
                                                    disabled={disabled}/>
                </form>
              </> : <Spinner1/>}
            </>
          </Padding>
  );
};

