import * as React from 'react';
import {SelectOptionInterface} from '../../types/select-option.interface';
import {map} from 'lodash-es';
import {useSelector} from 'react-redux';
import {AppState} from '../../stores';
import {FormSelect} from '../../presentations/Form/FormSelect';

interface MyProfileEditFieldsetInterface {
  name: string
  register: any
  error: any
}

const FormDepartmentSelect: React.FC<MyProfileEditFieldsetInterface> = (props) => {
  const departments: SelectOptionInterface[] = map(useSelector((state: AppState) => state.departments), department => {
    return {
      id  : String(department.id),
      name: department.title,
    }
  });

  departments.unshift({
    id  : '',
    name: '選択してください',
  });

  return (
          <FormSelect options={departments}
                      name={props.name}
                      register={props.register}
                      error={props.error}/>
  );
}

export default FormDepartmentSelect;

