import styled from 'styled-components';
import React from 'react';

interface PaddingInterface {
  children?: React.ReactElement
  padding: string
}

const Padding: React.FC<PaddingInterface> = (props) => {
  return (<Wrapper padding={props.padding}>{props.children}</Wrapper>);
}

const Wrapper = styled.div<{ padding: string }>`
  padding: ${props => props.padding}
`;

export default Padding;
