import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export interface ReceiveInvitationRequestInterface {
  sendInvitationDto: { guestUserId: number, hostUserId: number }
  callHistory: any
  hostUser: UserType
  guestUser: UserType
}

export interface ReceiveInvitationRequestModalInterface {
  open: boolean
  data: ReceiveInvitationRequestInterface | null
}

// Stateの初期状態
const initialState: ReceiveInvitationRequestModalInterface = {
  open: false,
  data: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'sending-receive-request-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<ReceiveInvitationRequestInterface>) => {
      return {...state, open: true, data: action.payload};
    },
    close: (state) => {
      return {...state, open: false, data: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
