import React from 'react';
import styled from 'styled-components';
import {map} from 'lodash-es';

interface GridInterface {
  children: React.ReactElement[]
  columnGap?: string
  rowGap?: string
  columnRepeat?: string
}

const Grid: React.FC<GridInterface> = (props) => {
  const createCell = () => {
    return map(props.children as React.ReactElement[], (child, index) => {
      return <Cell key={index}>{child}</Cell>;
    })
  }

  return (
          <Wrapper columnGap={props.columnGap} rowGap={props.rowGap} columnRepeat={props.columnRepeat}>{createCell()}</Wrapper>
  );
}

Grid.defaultProps = {
  columnGap   : '0',
  rowGap      : '0',
  columnRepeat: 'repeat(2, 1fr)',
}

const Wrapper = styled.div<{ columnGap?: string, rowGap?: string, columnRepeat?: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.columnRepeat};
  column-gap: ${(props) => props.columnGap};
  row-gap: ${(props) => props.rowGap};
`;

const Cell = styled.div``;

export default Grid;
