import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface Headline2Interface {
  children: React.ReactNode
}

const Headline2: React.FC<Headline2Interface> = (props) => {
  return (
          <Wrapper>{props.children}</Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 1.5em;
  padding-bottom: .5em;
  border-bottom: 1px solid #E1E2E8;
  color: #262F40;
  font-weight: bold;
  line-height: 1.3;
  ${fontSize(20)}
`;

export default Headline2;
