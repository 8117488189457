import React from 'react';
import styled from 'styled-components';

interface ModalBodyInterface {
  children: React.ReactElement | React.ReactNode
  scroll?: boolean
}

const ModalBody: React.FC<ModalBodyInterface> = (props) => {
  return (
          <Wrapper data-scroll={props.scroll}>{props.children}</Wrapper>
  );
}

ModalBody.defaultProps = {
  scroll: true
}

export const Wrapper = styled.div`
  max-height: calc(100vh - 220px);

  &[data-scroll='true']{
    overflow-y: auto;
  }
`;

export default ModalBody;
