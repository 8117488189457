import React from 'react';
import styled from 'styled-components';

interface TableSimpleInterface {
  children: React.ReactElement
}

const TableSimple: React.FC<TableSimpleInterface> = (props) => {
  return (
          <Table>
            {props.children}
          </Table>
  );
}

const Table = styled.table`
  width: 100%;

  th, td {
    padding: 1.1em 1em;
    text-align: left;
    font-weight: normal;
  }
  
  thead {
    border-bottom: 1px solid #E1E2E8;  
  }
  
  tbody {
    tr {
      &:hover {
        background-color: #F9F9FA;
      }    
    }
  }
`;

export default TableSimple;
