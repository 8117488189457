import * as React from 'react';
import UserProfileLayout from '../../presentations/UserProfileLyaout';
import FormField from '../../presentations/Form/FormField';
import FormErrorText from '../../presentations/Form/FormErrorText';
import {FormInput} from '../../presentations/Form/FormInput';
import Grid from '../../presentations/Grid';
import {SelectOptionInterface} from '../../types/select-option.interface';
import {map} from 'lodash-es';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import FormDepartmentSelect from '../../presentations/Form/FormDepartmentSelect';
import {UserType} from '../../stores/authenticated';
import MyProfileAvatarSelectPreview from './MyProfileAvatarSelectPreview';
import {open as openMyProfileAvatarSelectModal} from '../../stores/my-profile-avatar-select-modal';
import {
  setCurrent as setCurrentMyProfileAvatarSelect,
  setPreview as setPreviewMyProfileAvatarSelect
} from '../../stores/my-profile-avatar-select';

interface MyProfileEditFieldsetInterface {
  register: any
  errors: any
  user: UserType
  previewAvatar: string
}

const MyProfileEditFieldset: React.FC<MyProfileEditFieldsetInterface> = (props) => {
  const departments: SelectOptionInterface[] = map(useSelector((state: AppState) => state.departments), department => {
    return {
      id  : String(department.id),
      name: department.title,
    }
  });

  departments.unshift({
    id  : '',
    name: '選択してください',
  });

  const handleSelectAvatar = () => {
    store.dispatch(setPreviewMyProfileAvatarSelect(props.previewAvatar));
    store.dispatch(setCurrentMyProfileAvatarSelect(props.previewAvatar));
    store.dispatch(openMyProfileAvatarSelectModal());
  }

  const column1 = () => {
    return <MyProfileAvatarSelectPreview avatar={props.previewAvatar} user={props.user} onClick={handleSelectAvatar}/>
  }

  const column2 = () => {
    return <>
      <FormField label={'表示名'}>
        {(props.errors.firstName || props.errors.lastName) && <FormErrorText text="必須入力です"/>}
        <Grid columnGap={'10px'}>
          <>
            <FormInput name={'firstName'}
                       register={props.register({require: true})}
                       error={props.errors.firstName}/>
          </>
          <>
            <FormInput name={'lastName'}
                       register={props.register({require: true})}
                       error={props.errors.lastName}/>
          </>
        </Grid>
      </FormField>

      <FormField label={'部署'}>
        {props.errors.departmentId && <FormErrorText text="必須入力です"/>}
        <FormDepartmentSelect name={'departmentId'}
                              register={props.register({require: true})}
                              error={props.errors.departmentId}/>
      </FormField>

      <FormField label={'電話番号'}>
        {props.errors.phone && <FormErrorText text="必須入力です"/>}
        <FormInput name={'phone'}
                   register={props.register({require: true})}
                   error={props.errors.phone}/>
      </FormField>

      <FormField label={'メールアドレス'}>
        {props.errors.email && <FormErrorText text="必須入力です"/>}
        <FormInput name={'email'}
                   register={props.register({require: true})}
                   error={props.errors.email}/>
      </FormField>
    </>
  }

  return (
          <UserProfileLayout column1={column1()} column2={column2()}/>
  );
}

export default MyProfileEditFieldset;

