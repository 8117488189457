import React, {useEffect, useRef, useState} from 'react';
import store, {AppState} from '../stores';
import {close} from '../stores/modal-confirm-user-profile-remove';
import {useSelector} from 'react-redux';
import Modal from '../presentations/Modal';
import Button from '../presentations/Button';
import ModalConfirmAction from '../presentations/ModalConfirmAction';
import ModalConfirmBody from '../presentations/ModalConfirmBody';
import Repository from '../services/repository';
import {UserType} from '../stores/authenticated';
import {AxiosError, AxiosResponse} from 'axios';
import {remove} from '../stores/users';
import {Websocket} from '../services/websocket';
import Spinner2 from '../presentations/Spinner2';

const UserProfileRemoveConfirmModal: React.FC = () => {
  const repository            = new Repository();
  const ref                   = useRef<any>();
  const open                  = useSelector((state: AppState) => state.modalConfirmUserProfileRemove.open);
  const user                  = useSelector((state: AppState) => state.modalConfirmUserProfileRemove.user as UserType);
  const [sending, setSending] = useState<boolean>(false);

  useEffect(() => {
    setSending(false);

    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  const handleRemove = () => {
    console.log('handleRemove', user);
    setSending(true);
    repository.removeUser(user.id as number)
            .then((response: AxiosResponse<any>) => {
              console.log(response);
              store.dispatch(remove(user));
              Websocket.socket?.emit('getInfo', {});
              handleClose();
            })
            .catch((error: AxiosError) => {
              console.log(error);
              setSending(false);
            });
  }

  const getName = () => {
    return `${user?.firstName} ${user?.lastName}`
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <>
              <ModalConfirmBody>
                {getName()}を削除しますか？
              </ModalConfirmBody>
              <ModalConfirmAction>
                <>
                  <Button width={'130px'} margin={'0 10px 0 0'} onClick={handleClose}>キャンセル</Button>
                  <Button width={'130px'} color={'secondary'} onClick={handleRemove}>
                    {sending ? <Spinner2/> : null} 削除
                  </Button>
                </>
              </ModalConfirmAction>
            </>
          </Modal>
  );
}

export default UserProfileRemoveConfirmModal;
