import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type AppStatusType = {
  init: boolean
  totalUsers: number
  activeMeetings: number
  meetingsLimit: number
  usersLimit: number
  presentUsers: number
  absentUsers: number
}

// Stateの初期状態
const initialState: AppStatusType = {
  init          : false,
  totalUsers    : 0,
  activeMeetings: 0,
  meetingsLimit : 0,
  usersLimit    : 0,
  presentUsers  : 0,
  absentUsers   : 0,
};

// Sliceを生成する
const slice = createSlice({
  name    : 'appStatus',
  initialState,
  reducers: {
    appInitialize: (state) => {
      return {...state, init: true,};
    },
    initState : (state, action: PayloadAction<AppStatusType>) => {
      return {
        ...state,
        init          : action.payload.init,
        totalUsers    : action.payload.totalUsers,
        activeMeetings: action.payload.activeMeetings,
        meetingsLimit : action.payload.meetingsLimit,
        usersLimit    : action.payload.usersLimit,
        presentUsers  : action.payload.presentUsers,
        absentUsers   : action.payload.absentUsers,
      };
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {initState, appInitialize} = slice.actions;
