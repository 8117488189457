import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import React from 'react';

interface ForgotPasswordFormReadTextInterface {
  children: React.ReactNode
}

const ForgotPasswordFormReadText: React.FC<ForgotPasswordFormReadTextInterface> = (props) => {
  return (
          <Wrapper>{props.children}</Wrapper>
  );
}

const Wrapper = styled.p`
  margin-bottom: 20px;
  ${fontSize(12)};
  line-height: 1.75;
`;

export default ForgotPasswordFormReadText;
