import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import Cookies from 'js-cookie';
import {Websocket} from '../../services/websocket';
import {close} from '../../stores/sending-talking-request-modal';
import SendingTalkingRequest from '../../presentations/SendingTalkingRequest';

const RoomConnecting = () => {
  const connected          = useSelector((state: AppState) => state.receiver.connected);
  const data               = JSON.parse(localStorage.getItem('sendingTalkingRequest') as string);
  const sendTalkingRequest = new URLSearchParams(document.location.search).get('sendTalkingRequest');

  useEffect(() => {
    Cookies.remove('roomCloseSendTalkingRequest');
  }, []);

  // 着信・発信
  useEffect(() => {
    // 拒否した
    Websocket.socket?.off('talkingRequestRejected');
    Websocket.socket?.on('talkingRequestRejected', () => {
      window.close();
    });

    // 発信を中止した
    Websocket.socket?.off('talkingRequestCancelled');
    Websocket.socket?.on('talkingRequestCancelled', () => {
      window.close();
    });
  }, [connected]);

  const handleReject = () => {
    store.dispatch(close());
    Websocket.socket?.emit('cancelTalkingRequest', {
      createRoomDto: data?.createRoomDto,
      callHistory  : data?.callHistory,
    });
  }

  return (
          <Wrapper>
            {
              sendTalkingRequest ?
                      <Card><SendingTalkingRequest data={data} onClose={handleReject} sound={false}/></Card> : <>接続中...</>
            }
          </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 550px;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .32);
`;

export default RoomConnecting;
