import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../../components/utility/Mixin';

const FormErrorText = (props: { text: string }) => {
  return (
          <ErrorText>{props.text}</ErrorText>
  );
};

// style
const ErrorText = styled.div`
  margin-bottom: 2px;
  color: #EA4949;
  ${fontSize(10)};
  text-align: left;
`;

export default FormErrorText;
