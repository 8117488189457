import React, {useEffect, useRef} from 'react';
import Modal from '../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {close} from '../stores/department-creator-modal';
import {DepartmentType} from '../stores/departments';
import DepartmentUpdater from './DepartmentUpdater';
import DepartmentCreator from './DepartmentCreator';

const customStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
    zIndex         : 1000,
  },
  content: {
    padding     : '0',
    width       : 'calc(100% - 30px)',
    maxWidth    : '350px',
    height      : 'auto',
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    transform   : 'translate(-50%, -50%)',
    border      : 'none',
    borderRadius: '6px',
    boxShadow   : '0 4px 20px rgba(0, 0, 0, .32)',
  }
}

const DepartmentCreaterModal: React.FC = () => {
  const ref        = useRef<any>();
  const open       = useSelector((state: AppState) => state.departmentCreatorModal.open);
  const department = useSelector((state: AppState) => state.departmentCreatorModal.department as DepartmentType);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} style={customStyle} onClose={handleClose}>
            {department ? <DepartmentUpdater department={department} onClose={handleClose}/> : <DepartmentCreator onClose={handleClose}/>}
          </Modal>
  )
}

export default DepartmentCreaterModal;
