import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type FilterDepartmentType = {
  id: number
  title: string
}

export interface DepartmentFilterInterface {
  options: FilterDepartmentType[]
  selected: FilterDepartmentType
}

// Stateの初期状態
const initialState: DepartmentFilterInterface = {
  options : [],
  selected: {
    id   : 0,
    title: 'All',
  }
};

// Sliceを生成する
const slice = createSlice({
  name    : 'department-filter',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<FilterDepartmentType[]>) => {
      return {...state, options: action.payload};
    },
    select    : (state, action: PayloadAction<FilterDepartmentType>) => {
      return {...state, selected: action.payload};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set, select} = slice.actions;
