import React, {useEffect} from 'react';
import ModalHeaderSimple from '../../presentations/ModalHeaderSimple';
import styled from 'styled-components';
import Button from '../../presentations/Button';
import Spinner1 from '../../presentations/Spinner1';
import Avatar from '../../presentations/Avatar';
import {fontSize} from '../utility/Mixin';
import {Sound} from '../../services/sound';
import {ReceiveInvitationRequestInterface} from '../../stores/receive-invitation-request-modal';
import {SelectOptionInterface} from '../../types/select-option.interface';
import {useForm} from 'react-hook-form';
import {FormSelect} from '../../presentations/Form/FormSelect';
import {find} from 'lodash-es';

interface SendingTalkingRequestInterface {
  onClose: (rejectMessage: string) => void
  onAccept: () => void
  data?: ReceiveInvitationRequestInterface | null
}

const ReceiveInvitationRequest: React.FC<SendingTalkingRequestInterface> = (props) => {
  const {register, getValues} = useForm<{ messageId: string }>({
    defaultValues: {
      messageId: '1',
    }
  });

  const getName = () => {
    return `${props.data?.hostUser.firstName} ${props.data?.hostUser.lastName}`;
  }

  useEffect(() => {
    Sound.incoming.play();

    return () => {
      Sound.incoming.stop();
    }
  }, []);

  const handleClose = () => {
    props.onClose(find(voteMessage, {id: getValues('messageId')})?.name as string);
  }

  const voteMessage: SelectOptionInterface[] = [
    {
      id  : '1',
      name: '応答できません',
    },
    {
      id  : '2',
      name: 'あとでかけ直します',
    },
  ];

  return (
          <>
            <ModalHeaderSimple>招待</ModalHeaderSimple>
            <Loading><Spinner1/></Loading>
            <Body>
              <User>
                {props.data?.hostUser && <Avatar user={props.data.hostUser} size={56}/>}
                <Text>
                  {getName()}さんから招待されています<br/>
                  <Sub>応答すると通話が開始されます。</Sub>
                </Text>
              </User>
              <Veto>
                <VetoLabel><i className={'soi-message'}></i> 拒否時に送信されるメッセージ</VetoLabel>
                <FormSelect name={'messageId'} register={register()} options={voteMessage} width={'auto'}/>
              </Veto>
            </Body>
            <Action>
              <Button type={'button'} color={'danger'} onClick={handleClose}>拒否</Button>
              <Button type={'button'} color={'primary'} icon={'soi-video'} margin={'0 0 0 10px'}
                      onClick={props.onAccept}>応答する</Button>
            </Action>
          </>
  )
}

const Loading = styled.div`
  padding-bottom: 30px;
  text-align: center;
`;

const Body = styled.div`
  padding: 0 20px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin-left: 10px;
  ${fontSize(18)};
  font-weight: 700;
`;

const Sub = styled.small`
  margin-top: 10px;
  display: block;
  ${fontSize(12)};
  font-weight: 400;
`;

const Action = styled.div`
  margin-top: 25px;
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

const Veto = styled.div`
  margin-top: 20px;
  padding-left: 66px;
  max-width: 306px;
  font-size: 12px;
  font-weight: normal;
`;

const VetoLabel = styled.div`
  margin-bottom: 5px;
  color: #EA4949;
`;

export default ReceiveInvitationRequest;
