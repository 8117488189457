import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export type TalkingType = {
  id: number
  hostUserId: number
  title: string
  link: string
  isLocked: false
  startsAt: string
  endsAt: string
  users: UserType[];
};

export type TalkingsType = TalkingType[];

// Stateの初期状態
const initialState: TalkingsType = [];

// Sliceを生成する
const slice = createSlice({
  name    : 'talkings',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<TalkingsType>) => {
      return action.payload;
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {set} = slice.actions;
