import React, {useContext, useEffect} from 'react';
import PageContainer from '../presentations/PageContainer';
import SystemManagerHeader from '../presentations/SystemManagerHeader';
import SystemManagerMenu from '../presentations/SystemManagerMenu';
import SystemManagerLayout from '../presentations/SystemmanagerLayout';
import styled from 'styled-components';
import Button from '../presentations/Button';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import TableSimple from '../presentations/Table/TableSimple';
import {each, orderBy} from 'lodash-es';
import SystemManagerMainLayout from '../presentations/SystemManagerMainLayout';
import {PopupContext} from '../containers/PopupStoreContext';
import SystemManagerRecordAction from '../presentations/SystemManagerRecordAction';
import SelectList, {SelectListItem} from '../presentations/SelectList';
import {open as openDepartmentCreatorModal} from '../stores/department-creator-modal';
import {open as openDepartmentRemoverModal} from '../stores/department-remover-modal';
import {DepartmentType} from '../stores/departments';
import DepartmentCreaterModal from '../containers/DepartmentCreaterModal';
import DepartmentRemoverModal from '../containers/DepartmentRemoverModal';

const SystemManagerDepartments: React.FC = () => {
  const systemName = useSelector((state: AppState) => state.system.systemName);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${systemName}`;
  }, [systemName]);

  return (
          <PageContainer>
            <>
              <SystemManagerHeader/>
              <SystemManagerLayout sidebar={<SystemManagerMenu/>} main={<SystemManagerUserMain/>}/>
              <DepartmentCreaterModal/>
              <DepartmentRemoverModal/>
            </>
          </PageContainer>
  );
};

export default SystemManagerDepartments;

const SystemManagerUserMain: React.FC = () => {
  return (<SystemManagerMainLayout header={<SystemManagerUserMainHeader/>} body={<SystemManagerUserMainBody/>}/>);
}

const SystemManagerUserMainHeader: React.FC = () => {
  const handleOpen = () => {
    store.dispatch(openDepartmentCreatorModal(null));
  }

  return (
          <>
            <Button icon={'soi-plus'} fontSize={14} onClick={handleOpen}>部署を作成</Button>
          </>
  )
}

const SystemManagerUserMainBody: React.FC = () => {
  const departments             = orderBy(useSelector((state: AppState) => state.departments), ['id'], ['asc']);
  const [, setPopupState] = useContext(PopupContext);

  const handleAction = (event: React.MouseEvent<HTMLButtonElement>, department: DepartmentType) => {
    event.persist();

    const list = <SelectList>
      <>
        <SelectListItem onClick={() => handleEditDepartment(department)} icon={'soi-edit'}>編集</SelectListItem>
        <SelectListItem onClick={() => handleRemoveDepartment(department)} icon={'soi-delete'}>削除</SelectListItem>
      </>
    </SelectList>;

    setPopupState(() => {
      return {
        open    : true,
        children: <SystemManagerRecordAction list={list}/>,
        event   : event,
        design  : 'simple',
        width   : 'auto'
      }
    });
  }

  const handleEditDepartment = (department: DepartmentType) => {
    store.dispatch(openDepartmentCreatorModal(department));
  }

  const handleRemoveDepartment = (department: DepartmentType) => {
    store.dispatch(openDepartmentRemoverModal(department));
  }

  const createRows = () => {
    const rows: React.ReactElement[] = [];
    each(departments, (department, index) => {
      rows.push(<tr key={index}>
        <td>
          <Edit type={'button'} onClick={(event) => handleAction(event, department)}>{department.title}</Edit>
        </td>
        <td>{department.users?.length}人</td>
      </tr>);
    });

    return rows;
  }

  return (
          <TableSimple>
            <>
              <colgroup>
                <col width={250} span={1}/>
              </colgroup>
              <thead>
              <tr>
                <th>部署名</th>
                <th>人数</th>
              </tr>
              </thead>
              <tbody>
              {createRows()}
              </tbody>
            </>
          </TableSimple>
  );
};

const Edit = styled.button`
  text-decoration: underline;
`;
