import React from 'react';
import LoginFormLayout from '../presentations/Login/LoginFormLayout';
import {useHistory} from 'react-router';
import Button from '../presentations/Button';
import ForgotPasswordFormReadText from '../presentations/ForgotPasswordReadText';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const ResetPasswordFormComplete: React.FC = () => {
  const {handleSubmit} = useForm();
  const history        = useHistory();
  const {t}            = useTranslation();

  const onSubmit = (): void => {
    history.push('login');
  };

  return (
          <LoginFormLayout title={t('Reconfiguration complete.')} onSubmit={handleSubmit(onSubmit)}>
            <ForgotPasswordFormReadText>
              {t('You have successfully reset your password.')}<br/>
              {t('Please login with your new password.')}
            </ForgotPasswordFormReadText>

            <Button type={'submit'} width={'100%'} color={'secondary'} fontSize={14}>{t('Go to login page')}</Button>
          </LoginFormLayout>
  );
};

export default ResetPasswordFormComplete;
