import React, {useEffect} from 'react';
import {Websocket} from './services/websocket';
import {useSelector} from 'react-redux';
import store, {AppState} from './stores';
import ModalReceiveTalkingRequest from './presentations/ModalReceiveTalkingRequest';
import {TalkingRequestAcceptedInterface} from './pages/Home';
import {
  clearData,
  close as closeModalReceiveTalkingRequest,
  open as openModalReceiveTalkingRequest,
  ReceiveTalkingRequestInterface,
  setData
} from './stores/modal-receive-talking-request';
import {
  close as closeSendingTalkingRequestModal,
  open as openSendingTalkingRequestModal,
  SendingTalkingRequestInterface
} from './stores/sending-talking-request-modal';
import {
  open as openSendingInvitationRequestModal,
  close as closeSendingInvitationRequestModal,
  SendingInvitationRequestInterface,
} from './stores/sending-invitation-request-modal';
import {
  open as openReceiveInvitationRequestModal, ReceiveInvitationRequestInterface,
  close as closeReceiveInvitationRequestModal
} from './stores/receive-invitation-request-modal';
import {
  open as openMissedCallModal
} from './stores/missed-call-modal';
import {
  open as openRejectMessageModal
} from './stores/reject-message-modal';
import {TalkingType} from './stores/talkings';
import SendingTalkingRequestModal from './containers/SendingTalkingRequestModal';
import SendingInvitationRequestModal from './components/SendingInvitationRequest/SendingInvitationRequestModal';
import ReceiveInvitationRequestModal from './components/ReceiveInvitationRequest/ReceiveInvitationRequestModal';
import {find} from 'lodash-es';
import MissedCallModal from './components/MissedCall/MissedCallModal';
import RejectMessageModal from './components/RejectMessage/RejectMessageModal';

interface ReceiverInterface {
  children?: React.ReactElement | null
}

const Receiver: React.FC<ReceiverInterface> = (props) => {
  const userId    = useSelector((state: AppState) => state.authenticated.userId as number);
  const connected = useSelector((state: AppState) => state.receiver.connected);

  // 着信・発信
  useEffect(() => {
    // 発信した
    Websocket.socket?.off('sendingTalkingRequest');
    Websocket.socket?.on('sendingTalkingRequest', (payload: SendingTalkingRequestInterface) => {
      console.log(`sendingTalkingRequest`, payload);
      store.dispatch(openSendingTalkingRequestModal(payload));
      localStorage.setItem('sendingTalkingRequest', JSON.stringify(payload));
      window.open(`/room-connecting?sendTalkingRequest=1`, '_room');
    });

    // 発信を中止した
    Websocket.socket?.off('talkingRequestCancelled');
    Websocket.socket?.on('talkingRequestCancelled', (payload: any) => {
      console.log(`talkingRequestCancelled`, payload);
      store.dispatch(clearData());
      store.dispatch(closeModalReceiveTalkingRequest());
      store.dispatch(closeSendingTalkingRequestModal());

      // 不在着信メッセージを表示
      if (payload.callHistory.guestUser.id === userId) {
        store.dispatch(openMissedCallModal());
      }
    });

    // 着信した
    Websocket.socket?.off('receiveTalkingRequest');
    Websocket.socket?.on('receiveTalkingRequest', (payload: ReceiveTalkingRequestInterface) => {
      console.log(`receiveTalkingRequest`, payload);
      store.dispatch(setData(payload));
      store.dispatch(openModalReceiveTalkingRequest());

      const body = `${payload.hostUser.firstName} ${payload.hostUser.lastName}さんから着信`;
      const data = {
        url: `${window.location.protocol}//${window.location.host}/`
      }

      if ('Notification' in window) {
        Notification.requestPermission()?.then(() => {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification('Smart-O', {body, data});
          });
        });
      }
    });

    // 応答した
    Websocket.socket?.off('talkingRequestAccepted');
    Websocket.socket?.on('talkingRequestAccepted', ({room}: TalkingRequestAcceptedInterface) => {
      console.log('talkingRequestAccepted', room)
      store.dispatch(clearData());
      store.dispatch(closeModalReceiveTalkingRequest());
      store.dispatch(closeSendingTalkingRequestModal());
      window.open(`/room?title=${room.title}&roomId=${room.id}`, '_room');
    });

    // 拒否した
    Websocket.socket?.off('talkingRequestRejected');
    Websocket.socket?.on('talkingRequestRejected', (payload: TalkingRequestAcceptedInterface) => {
      console.log('talkingRequestRejected', payload)
      store.dispatch(clearData());
      store.dispatch(closeModalReceiveTalkingRequest());
      store.dispatch(closeSendingTalkingRequestModal());

      // 発信者なら
      if (payload.callHistory.hostUser.id === userId) {
        store.dispatch(openRejectMessageModal(payload));
      }
    });
  }, [connected]);

  // 招待の発信・着信
  useEffect(()=>{
    // 招待を発信した
    Websocket.socket?.off('sendingInvitationRequest');
    Websocket.socket?.on('sendingInvitationRequest', (payload: SendingInvitationRequestInterface) => {
      console.log(`sendingInvitationRequest`, payload);
      store.dispatch(openSendingInvitationRequestModal(payload));
    });

    // 招待を中止した
    Websocket.socket?.off('invitationRequestCancelled');
    Websocket.socket?.on('invitationRequestCancelled', (payload: any) => {
      console.log(`invitationRequestCancelled`, payload);
      store.dispatch(closeSendingInvitationRequestModal());
      store.dispatch(closeReceiveInvitationRequestModal());
    });

    // 招待が着信した
    Websocket.socket?.off('receiveInvitationRequest');
    Websocket.socket?.on('receiveInvitationRequest', (payload: ReceiveInvitationRequestInterface) => {
      console.log(`receiveInvitationRequest`, payload);
      store.dispatch(openReceiveInvitationRequestModal(payload));

      const body = `${payload.hostUser.firstName} ${payload.hostUser.lastName}さんから招待`;
      const data = {
        url: `${window.location.protocol}//${window.location.host}/`
      }

      if ('Notification' in window) {
        Notification.requestPermission()?.then(() => {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification('Smart-O', {body, data});
          });
        });
      }
    });

    // 招待に応答した
    Websocket.socket?.off('invitationRequestAccepted');
    Websocket.socket?.on('invitationRequestAccepted', ({room}: TalkingRequestAcceptedInterface) => {
      console.log('invitationRequestAccepted', room)
      store.dispatch(closeSendingInvitationRequestModal());
      store.dispatch(closeReceiveInvitationRequestModal());

      // 着信側
      if (room.hostUserId !== userId) {
        window.open(`/room?title=${room.title}&roomId=${room.id}`, '_room');
      }
    });

    // 招待を拒否した
    Websocket.socket?.off('invitationRequestRejected');
    Websocket.socket?.on('invitationRequestRejected', (payload: TalkingRequestAcceptedInterface) => {
      console.log('invitationRequestRejected', payload)
      store.dispatch(closeSendingInvitationRequestModal());
      store.dispatch(closeReceiveInvitationRequestModal());

      // 発信者なら
      if (payload.callHistory.hostUser.id === userId) {
        store.dispatch(openRejectMessageModal(payload));
      }
    });
  }, [connected]);

  // 部屋作成
  useEffect(()=>{
    Websocket.socket?.off('roomCreated');
    Websocket.socket?.on('roomCreated', ({room}: { room: TalkingType }) => {
      console.log('roomCreated', room);
      window.open(`${process.env.REACT_APP_DIR}room?title=${room.title}&roomId=${room.id}&hostUserId=${userId}`, '_room');
    });
  }, [connected]);

  return (
          <>
            {props.children}
            {connected && <>
              <SendingTalkingRequestModal/>
              <ModalReceiveTalkingRequest/>
              <SendingInvitationRequestModal/>
              <ReceiveInvitationRequestModal/>
              <MissedCallModal/>
              <RejectMessageModal/>
            </>}
          </>
  );
}

export default Receiver;
