import React, {ForwardRefExoticComponent, useImperativeHandle, useRef} from 'react';
import styled from 'styled-components';
import {fontSize} from '../../components/utility/Mixin';

interface FormInputProps {
  type?: 'text' | 'password' | 'hidden' | 'file';
  name?: string;
  value?: string;
  placeholder?: string;
  register?: any;
  error?: any;
  readonly?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'flex' | 's' | 'm' | 'l' | 'xl'
  ref?: any
}

export const FormInput: ForwardRefExoticComponent<FormInputProps> = React.forwardRef((props, ref) => {
  let inputRef = useRef<any>();

  const hasError = (error: any) => {
    return error !== 'false';
  }

  const handleSetRef = (ref: any) => {
    inputRef = ref;
    if(props.register){
      props.register(ref);
    }
  }

  useImperativeHandle(ref, () => {
    return {
      input: inputRef
    };
  });

  return (
          <>
            <Input type={props.type}
                   name={props.name}
                   placeholder={props.placeholder}
                   ref={handleSetRef}
                   data-error={hasError(props.error)}
                   data-size={props.size}
                   onChange={props.onChange}
                   readOnly={props.readonly}
                   disabled={props.disabled}
            />
          </>
  );
});

// Propsのデフォルト値
FormInput.defaultProps = {
  type       : 'text',
  name       : '',
  value      : '',
  placeholder: '',
  error      : 'false',
  readonly   : false,
  size       : 'flex',
};

const Input = styled.input`
  padding: 3px 10px 0;
  max-width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #D5D5D5;
  border-radius: 6px;
  ${fontSize(14)};
  text-align: left;
  ::placeholder {
    color: rgba(38, 47, 64, 0.3);
  }
  &[data-error="true"]{
    border: 2px solid #EA4949;
  }
  &[disabled]{
    background-color: rgba(0, 0, 0, .1);
  }
  &[data-size="flex"]{
    width: 100%;
  }
  &[data-size="s"]{
    width: 100px;
  }
  &[data-size="m"]{
    width: 200px;
  }
  &[data-size="l"]{
    width: 300px;
  }
  &[data-size="xl"]{
    width: 400px;
  }
`;
