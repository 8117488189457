import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface RoomPasswordInterface {
  isLocked: boolean
  password: string
}

// Stateの初期状態
const initialState: RoomPasswordInterface = {
  isLocked: false,
  password: ''
};

// Sliceを生成する
const slice = createSlice({
  name    : 'room-password',
  initialState,
  reducers: {
    lock  : (state, action: PayloadAction<string>) => {
      return {...state, isLocked: true, password: action.payload};
    },
    unlock: (state) => {
      return {...state, isLocked: false, password: ''};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {lock, unlock} = slice.actions;
