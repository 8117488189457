import axios, {AxiosResponse} from 'axios';
import {InfoType} from '../App';
import Cookies from 'js-cookie';
import {UserType} from '../stores/authenticated';
import {UsersType} from '../stores/users';
import {each} from 'lodash-es';
import {RoomHistory} from '../components/History/TalkingHistory';
import {DepartmentType} from '../stores/departments';
import {TalkingType} from '../stores/talkings';
import {RoleType} from '../stores/roles';

export interface ChangePasswordRepositoryInterface {
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

export interface RecoverPasswordRepositoryInterface {
  email: string
}

export interface ResetPasswordRepositoryInterface {
  newPassword: string
  newPasswordConfirm: string
  resetPasswordToken: string
}

export interface SignupRepositoryInterface {
  username: string
  firstName: string
  lastName: string
  departmentId: number
  email: string
  password: string
  phone: string
  roles: string[]
}

export interface UpdateUserRepositoryInterface {
  username: string
  firstName: string
  lastName: string
  departmentId: number
  email: string
  phone: string
  roles: string[]
  avatar?: FileList
}

export interface CreateDepartmentRepositoryInterface {
  title: string
  memberLimit: number
}

export interface UpdateDepartmentRepositoryInterface extends CreateDepartmentRepositoryInterface {
}

export interface RoomLockRepositoryInterface {
  isLocked: boolean
}

export interface GetSystemRepositoryInterface {
  systemName: string
  meetingsLimit: number
  usersLimit: number
  bgImage: string
}

export interface UpdateSystemRepositoryInterface {
  systemName: string
  bgImage?: FileList
}

export interface OutgoingCallsRepositoryInterface {
  id:number
  hostUser: UserType
  guestUser: UserType
  datetime: string
  responseStatus: string
}

export default class Repository {
  apiUrl: string;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL as string;
  }

  getAuthorizationHeader() {
    return {Authorization: `Bearer ${Cookies.get('accessToken') as string}`};
  }

  getInfo(): Promise<AxiosResponse<InfoType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/getInfo`, {headers});
  }

  getUser(userId: number): Promise<AxiosResponse<UserType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/users/${userId}`, {headers});
  }

  getUsers(): Promise<AxiosResponse<UsersType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/users`, {headers});
  }

  updateUser(userId: number, post: UpdateUserRepositoryInterface): Promise<AxiosResponse<UserType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    const formData = new FormData();
    each(post, (value: any, key) => {
      if (key === 'roles') {
        each(value, (val) => {
          formData.append(`${key}[]`, val);
        });
      } else if (value.length && (key === 'avatar' || key === 'bgImage')) {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value as any);
      }
    });

    return axios.patch(`${this.apiUrl}/users/${userId}`, formData, {headers});
  }

  updateUser2(userId: number, post: FormData): Promise<AxiosResponse<UserType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.patch(`${this.apiUrl}/users/${userId}`, post, {headers});
  }

  removeUser(userId: number) {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.delete(`${this.apiUrl}/users/${userId}`, {headers});
  }

  createDepartment(post: CreateDepartmentRepositoryInterface): Promise<AxiosResponse<DepartmentType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.post(`${this.apiUrl}/departments`, post, {headers});
  }

  updateDepartment(departmentId: number, post: UpdateDepartmentRepositoryInterface): Promise<AxiosResponse<DepartmentType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.patch(`${this.apiUrl}/departments/${departmentId}`, post, {headers});
  }

  removeDepartment(departmentId: number): Promise<AxiosResponse<any>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.delete(`${this.apiUrl}/departments/${departmentId}`, {headers});
  }

  getRoles(): Promise<AxiosResponse<RoleType[]>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/roles`, {headers});
  }

  changePassword(userId: number, post: ChangePasswordRepositoryInterface): Promise<AxiosResponse<UserType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.patch(`${this.apiUrl}/users/${userId}/changePassword`, post, {headers});
  }

  changePrivacy(userId: number, privacyStatus: boolean): Promise<AxiosResponse<UserType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.patch(`${this.apiUrl}/users/${userId}/changePrivacy`, {privacyStatus}, {headers});
  }

  getRoomHistories(userId: number): Promise<AxiosResponse<RoomHistory[]>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/${userId}/roomHistories`, {headers});
  }

  recoverPassword(post: RecoverPasswordRepositoryInterface): Promise<AxiosResponse<any>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.post(`${this.apiUrl}/auth/recoverPassword`, post, {headers});
  }

  resetPassword(post: ResetPasswordRepositoryInterface): Promise<AxiosResponse<any>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.post(`${this.apiUrl}/auth/resetPassword`, post, {headers});
  }

  signup(post: SignupRepositoryInterface): Promise<AxiosResponse<any>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.post(`${this.apiUrl}/auth/signup`, post, {headers});
  }

  roomLock(roomId: any, post: RoomLockRepositoryInterface): Promise<AxiosResponse<TalkingType>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.patch(`${this.apiUrl}/room/${roomId}/lock`, post, {headers});
  }

  getSystem(): Promise<AxiosResponse<GetSystemRepositoryInterface>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/system`, {headers});
  }

  updateSystem(post: UpdateSystemRepositoryInterface): Promise<AxiosResponse<GetSystemRepositoryInterface>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    const formData = new FormData();
    each(post, (value: any, key) => {
      if (value.length && key === 'bgImage') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value as any);
      }
    });

    return axios.patch(`${this.apiUrl}/system`, formData, {headers});
  }

  getOutgoingCalls(userId: number): Promise<AxiosResponse<OutgoingCallsRepositoryInterface[]>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/${userId}/outgoingCalls`, {headers});
  }

  getIncomingCalls(userId: number): Promise<AxiosResponse<OutgoingCallsRepositoryInterface[]>> {
    const headers = {
      ...this.getAuthorizationHeader()
    }

    return axios.get(`${this.apiUrl}/${userId}/incomingCalls`, {headers});
  }
}
