import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import Repository from '../../services/repository';
import {AxiosResponse} from 'axios';
import {updateUser, UserType} from '../../stores/authenticated';
import Loader from '../Loader';
import styled from 'styled-components';
import Button from '../../presentations/Button';
import {change} from '../../stores/my-profile-switch';
import Spinner2 from '../../presentations/Spinner2';
import {useForm} from 'react-hook-form';
import MyProfileEditFieldset from './MyProfileEditFieldset';
import AlertMessage from '../../presentations/AlertMessage';
import {
  setCurrent as setCurrentMyProfileAvatarSelect,
  setSelect as setSelectMyProfileAvatarSelect,
} from '../../stores/my-profile-avatar-select';
import {each} from 'lodash-es';
import {Image} from '../../services/image';

export interface MyProfileEditInterface {
  firstName: string
  lastName: string
  departmentId: number
  email: string
  phone: string
}

const MyProfileEdit: React.FC = () => {
  const [loaded, setLoaded]              = useState<boolean>(true);
  const [sending, setSending]            = useState<boolean>(false);
  const [alert, setAlert]                = useState<any>(null);
  const userId                           = useSelector((state: AppState) => state.authenticated.userId as number);
  const user                             = useSelector((state: AppState) => state.authenticated.user as UserType);
  const avatarCurrent                    = useSelector((state: AppState) => state.myProfileAvatarSelect.current);
  const avatarSelect                     = useSelector((state: AppState) => state.myProfileAvatarSelect.select);
  const {register, handleSubmit, errors} = useForm<MyProfileEditInterface>({
    defaultValues: {
      firstName   : user.firstName,
      lastName    : user.lastName,
      departmentId: user.department.id,
      email       : user.email,
      phone       : user.phone,
    }
  });

  useEffect(() => {
    store.dispatch(setSelectMyProfileAvatarSelect(''));
  }, []);

  useEffect(() => {
    const repository = new Repository();
    repository.getUser(userId)
            .then(({data}: AxiosResponse<UserType>) => {
              store.dispatch(setCurrentMyProfileAvatarSelect(data.avatar));
              store.dispatch(updateUser(data));
              setLoaded(false);
            })
            .catch(error => {

            });
  }, [userId]);

  const handleCancel = () => {
    store.dispatch(change('VIEW'));
  }

  const onSubmit = (post: MyProfileEditInterface) => {
    setSending(true);

    const formData = new FormData();
    each(post, (value: any, key) => {
      formData.append(key, value);
    });

    if (avatarSelect) {
      Image.toBlob(avatarSelect)
              .then((blob: Blob) => {
                formData.append('avatar', blob);
                send(formData);
              })
              .catch(() => {
                setSending(false);
                setAlert(<AlertMessage level={'error'} body={'更新に失敗しました'}/>);
              });
    } else {
      if(!avatarCurrent){
        formData.append('deleteAvatar', '1');
      }

      send(formData);
    }
  }

  const send = (formData: FormData) => {
    const repository = new Repository();
    repository.updateUser2(userId as number, formData)
            .then(({data}: AxiosResponse<UserType>) => {
              store.dispatch(setSelectMyProfileAvatarSelect(''));
              store.dispatch(setCurrentMyProfileAvatarSelect(data.avatar));
              store.dispatch(updateUser(data));
              setAlert(<AlertMessage level={'success'} body={'更新しました'}/>);
            })
            .catch(error => {
              console.log(error);
              setAlert(<AlertMessage level={'error'} body={'更新に失敗しました'}/>);
            })
            .finally(() => {
              setSending(false);
            });
  }

  const getPreviewAvatar = () => {
    return avatarSelect || avatarCurrent;
  }

  return (
          <>
            {loaded ? <Loader/> :
                    <form noValidate method={'post'} onSubmit={handleSubmit(onSubmit)}>
                      {alert}
                      <MyProfileEditFieldset register={register} errors={errors} user={user} previewAvatar={getPreviewAvatar()}/>
                      <Action>
                        <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} onClick={handleCancel}>
                          キャンセル
                        </Button>
                        <Button type={'submit'} color={'secondary'} width={'130px'}>
                          {sending ? <Spinner2/> : null} 変更を保存
                        </Button>
                      </Action>
                    </form>}
          </>
  );
}

const Action = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export default MyProfileEdit;

