import React from 'react';
import styled from 'styled-components';
import DepartmentList from './DepartmentList';
import MeetingListHeader from './MeetingListHeader';
import TalkingListHeader from './TalkingListHeader';
import DepartmentListHeader from './DepartmentListHeader';
import TalkingList from './TalkingList';
import MeetingList from './MeetingList';

const Office = () => {
  return (
          <>
            <Wrapper>
              <Meeting>
                <MeetingListHeader/>
                <MeetingList/>
              </Meeting>
              <Talking>
                <TalkingListHeader/>
                <TalkingList/>
              </Talking>
              <Department>
                <DepartmentListHeader/>
                <DepartmentList/>
              </Department>
            </Wrapper>
          </>
  );
};

// style
const Wrapper = styled.main`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
`;

const Group = styled.section`
  margin: 0 5px;
  padding: 6px 10px 15px;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #F7FAFC;
  border-radius: 6px;
  position: relative;
`;

const Meeting = styled(Group)`
  min-width: 250px;
  //max-width: 500px;
  flex: 1
`;

const Talking = styled(Group)`
  min-width: 250px;
  max-width: 250px;
  width: 250px;
`;

const Department = styled(Group)`
  min-width: 250px;
  flex: 2
`;

export default Office;
