import {Howl, Howler} from 'howler';

// @ts-ignore
import Outgoing from '../assets/sound/outgoing.mp3';

// @ts-ignore
import Incoming from '../assets/sound/incoming.mp3';

export class Sound {
  static outgoing: Howl;
  static incoming: Howl;

  constructor() {
  }

  static init() {
    this.outgoing = new Howl({
      src     : [Outgoing],
      autoplay: false,
      loop    : true,
      volume  : 0.5,
    });

    this.incoming = new Howl({
      src     : [Incoming],
      autoplay: false,
      loop    : true,
      volume  : 0.5,
    });
  }
}
