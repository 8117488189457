import Repository from './repository';
import {InfoType} from '../App';
import {AxiosResponse} from 'axios';
import store from '../stores';
import {AccessTokenType, loggedIn, LoggedInPayloadActionInterface, UserType} from '../stores/authenticated';
import {initState} from '../stores/app-status';
import {set as setUser} from '../stores/users';
import {set as setDepartment} from '../stores/departments';
import {set as setMeeting} from '../stores/meetings';
import {set as setTalking} from '../stores/talkings';
import {set as setOptions} from '../stores/department-filter';
import {SystemType, set as setSystem} from '../stores/system';

export interface AppInitializeInterface {
  accessToken: AccessTokenType;
  userId: number;
  user: UserType;
  info: InfoType;
  system: SystemType;
}

export class AppInitializer {
  static defaultInfo = {
    talking     : [],
    meeting     : [],
    departments : [],
    privacy     : [],
    totalUsers  : 0,
    presentUsers: 0,
    absentUsers : 0,
  };

  static dataLoad(): Promise<AxiosResponse[]> {
    const repository = new Repository();
    return Promise.all([
      repository.getInfo(),
      repository.getSystem(),
    ]);
  }

  static loggedIn(data: AppInitializeInterface) {
    const state: LoggedInPayloadActionInterface = {
      user         : data.user,
      userId       : data.userId,
      accessToken  : data.accessToken as string,
      privacyStatus: false
    };

    store.dispatch(setSystem(data.system));
    store.dispatch(loggedIn(state));
    this.initAppState(data.info);
  }

  static initAppState(info: InfoType) {
    store.dispatch(setUser(info.users));
    store.dispatch(setDepartment(info.departments));
    store.dispatch(setOptions(info.departments));
    store.dispatch(setMeeting(info.meetings));
    store.dispatch(setTalking(info.talkings));
    store.dispatch(initState({
      init          : true,
      totalUsers    : info.totalUsers,
      activeMeetings: info.activeMeetings,
      meetingsLimit : info.meetingsLimit,
      usersLimit    : info.usersLimit,
      presentUsers  : info.presentUsers,
      absentUsers   : info.absentUsers,
    }));
  }
}
