import React, {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import {close} from '../../stores/sending-invitation-request-modal';
import {Websocket} from '../../services/websocket';
import SendingInvitationRequest from './SendingInvitationRequest';

const SendingInvitationRequestModal = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.sendingInvitationRequestModal.open);
  const data     = useSelector((state: AppState) => state.sendingInvitationRequestModal.data);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
    Websocket.socket?.emit('cancelInvitationRequest', {
      sendInvitationDto: data?.sendInvitationDto,
      callHistory      : data?.callHistory
    });
  }

  return (
          <Modal ref={ref} closeButton={false}>
            <SendingInvitationRequest data={data} onClose={handleClose}/>
          </Modal>
  );
};

export default SendingInvitationRequestModal;
