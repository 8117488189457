import React, {useEffect, useRef} from 'react';
import Modal from '../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {close} from '../stores/modal-create-room-now';
import CreateRoomNow from './CreateRoomNow';

const ModalCreateRoomNow = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.modalCreateRoomNow.open);

  useEffect(() => {
    if (open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  return (
          <Modal ref={ref} closeButton={false}>
            <CreateRoomNow onClose={handleClose}/>
          </Modal>
  );
};

export default ModalCreateRoomNow;
