import React, {useEffect} from 'react';
import Header from '../presentations/Header';
import Office from '../presentations/Office';
import styled from 'styled-components';
import Receiver from '../Receiver';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {AccessTokenType, UserType} from '../stores/authenticated';

export interface TalkingRequestAcceptedInterface {
  room: {
    id: number
    hostUserId: number
    title: string
    link: string
    isLocked: boolean
    startsAt: string
    endsAt: string
    users: UserType[]
  },
  callHistory: any
  rejectMessage: string
}

const Home = () => {
  const systemName = useSelector((state: AppState) => state.system.systemName);
  const bgImage    = useSelector((state: AppState) => state.system.bgImage);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} - ${systemName}`;
  }, [systemName]);

  return (
          <Receiver>
            <Wrapper bgImage={bgImage}>
              <Header/>
              <Office/>
            </Wrapper>
          </Receiver>
  );
};

const Wrapper = styled.div<{ bgImage: string }>`
  padding-top: 40px;
  background-color: #E1E2E8;
  background-image: url(${props => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover; 
  height: 100%;
  overflow: hidden;
`;

export default Home;
