import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import systemReducer, {SystemType} from './system';
import appStateReducer, {AppStatusType} from './app-status';
import authenticatedReducer, {AuthenticatedType} from './authenticated';
import usersReducer, {UsersType} from './users';
import departmentsReducer, {DepartmentsType} from './departments';
import rolesReducer, {RoleType} from './roles';
import meetingsReducer, {MeetingsType} from './meetings';
import talkingsReducer, {TalkingsType} from './talkings';
import userMenuReducer, {UserMenuInterface} from './user-menu';
import departmentFilterReducer, {DepartmentFilterInterface} from './department-filter';
import userStatusFilterReducer, {UserStatusFilterInterface} from './user-status-filter';
import modalMeetingReducer, {ModalMeetingInterface} from './modal-meeting';
import modalChangePasswordReducer, {ModalChangePasswordInterface} from './modal-change-password';
import modalReceiveTalkingRequestReducer, {ModalReceiveTalkingRequestInterface} from './modal-receive-talking-request';
import modalCreateRoomNowReducer, {ModalCreateRoomNowInterface} from './modal-create-room-now';
import modalConfirmUserProfileRemoveReducer, {ModalConfirmUserProfileRemoveInterface} from './modal-confirm-user-profile-remove';
import modalEditUserProfileReducer, {UserProfileCreaterModalInterface} from './user-profile-creater-modal';
import departmentCreatorModalReducer, {DepartmentCreatorModalInterface} from './department-creator-modal';
import departmentRemoverModalReducer, {DepartmentRemoverModalInterface} from './department-remover-modal';
import sendingTalkingRequestModalReducer, {SendingTalkingRequestModalInterface} from './sending-talking-request-modal';
import sendingInvitationRequestModalReducer, {SendingInvitationRequestModalInterface} from './sending-invitation-request-modal';
import receiveInvitationRequestModalReducer, {ReceiveInvitationRequestModalInterface} from './receive-invitation-request-modal';
import roomShareModalReducer, {RoomShareModalInterface} from './room-share-modal';
import roomPasswordReducer, {RoomPasswordInterface} from './room-password';
import roomPasswordModalReducer, {RoomPasswordModalInterface} from './room-password-modal';
import myProfileModalReducer, {MyProfileModalInterface} from './my-profile-modal';
import myProfileSwitchReducer, {MyProfileSwitchInterface} from './my-profile-switch';
import myProfileAvatarSelectModalReducer, {MyProfileAvatarSelectModalInterface} from './my-profile-avatar-select-modal';
import myProfileAvatarSelectReducer, {MyProfileAvatarSelectInterface} from './my-profile-avatar-select';
import receiverReducer, {ReceiverInterface} from './receiver';
import historyModalReducer, {HistoryModalInterface} from './history-modal';
import missedCallModalReducer, {MissedCallModalInterface} from './missed-call-modal';
import rejectMessageModalReducer, {RejectMesssageModalInterface} from './reject-message-modal';

export type AppState = {
  system: SystemType,
  appState: AppStatusType,
  authenticated: AuthenticatedType,
  users: UsersType,
  departments: DepartmentsType,
  roles: RoleType[],
  meetings: MeetingsType,
  talkings: TalkingsType,
  userMenu: UserMenuInterface,
  departmentFilter: DepartmentFilterInterface,
  userStatusFilter: UserStatusFilterInterface,
  modalMeeting: ModalMeetingInterface,
  modalChangePassword: ModalChangePasswordInterface,
  modalReceiveTalkingRequest: ModalReceiveTalkingRequestInterface,
  modalCreateRoomNow: ModalCreateRoomNowInterface,
  modalConfirmUserProfileRemove: ModalConfirmUserProfileRemoveInterface,
  userProfileCreaterModal: UserProfileCreaterModalInterface,
  departmentCreatorModal: DepartmentCreatorModalInterface,
  departmentRemoverModal: DepartmentRemoverModalInterface,
  sendingTalkingRequestModal: SendingTalkingRequestModalInterface,
  sendingInvitationRequestModal: SendingInvitationRequestModalInterface,
  receiveInvitationRequestModal: ReceiveInvitationRequestModalInterface,
  roomShareModal: RoomShareModalInterface,
  roomPassword: RoomPasswordInterface,
  roomPasswordModal: RoomPasswordModalInterface,
  myProfileModal: MyProfileModalInterface,
  myProfileSwitch: MyProfileSwitchInterface,
  myProfileAvatarSelectModal: MyProfileAvatarSelectModalInterface,
  myProfileAvatarSelect: MyProfileAvatarSelectInterface,
  receiver: ReceiverInterface,
  historyModal: HistoryModalInterface,
  missedCallModal: MissedCallModalInterface,
  rejectMessageModal: RejectMesssageModalInterface,
}

const reducer = combineReducers<AppState>({
  system                       : systemReducer,
  appState                     : appStateReducer,
  authenticated                : authenticatedReducer,
  users                        : usersReducer,
  departments                  : departmentsReducer,
  roles                        : rolesReducer,
  meetings                     : meetingsReducer,
  talkings                     : talkingsReducer,
  userMenu                     : userMenuReducer,
  departmentFilter             : departmentFilterReducer,
  userStatusFilter             : userStatusFilterReducer,
  modalMeeting                 : modalMeetingReducer,
  modalChangePassword          : modalChangePasswordReducer,
  modalReceiveTalkingRequest   : modalReceiveTalkingRequestReducer,
  modalCreateRoomNow           : modalCreateRoomNowReducer,
  modalConfirmUserProfileRemove: modalConfirmUserProfileRemoveReducer,
  userProfileCreaterModal      : modalEditUserProfileReducer,
  departmentCreatorModal       : departmentCreatorModalReducer,
  departmentRemoverModal       : departmentRemoverModalReducer,
  sendingTalkingRequestModal   : sendingTalkingRequestModalReducer,
  sendingInvitationRequestModal: sendingInvitationRequestModalReducer,
  receiveInvitationRequestModal: receiveInvitationRequestModalReducer,
  roomShareModal               : roomShareModalReducer,
  roomPassword                 : roomPasswordReducer,
  roomPasswordModal            : roomPasswordModalReducer,
  myProfileModal               : myProfileModalReducer,
  myProfileSwitch              : myProfileSwitchReducer,
  myProfileAvatarSelectModal   : myProfileAvatarSelectModalReducer,
  myProfileAvatarSelect        : myProfileAvatarSelectReducer,
  receiver                     : receiverReducer,
  historyModal                 : historyModalReducer,
  missedCallModal              : missedCallModalReducer,
  rejectMessageModal           : rejectMessageModalReducer,
});

const store = configureStore({reducer});

export default store;
