import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface MyProfileAvatarSelectInterface {
  current: string
  preview: string
  select: string
}

// Stateの初期状態
const initialState: MyProfileAvatarSelectInterface = {
  current: '',
  preview: '',
  select : '',
};

// Sliceを生成する
const slice = createSlice({
  name    : 'my-profile-avatar-select',
  initialState,
  reducers: {
    setCurrent: (state, action: PayloadAction<string>) => {
      return {...state, current: action.payload};
    },
    setPreview: (state, action: PayloadAction<string>) => {
      return {...state, preview: action.payload};
    },
    setSelect : (state, action: PayloadAction<string>) => {
      return {...state, select: action.payload};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {setCurrent, setPreview, setSelect} = slice.actions;
