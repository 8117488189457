import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import DepartmentFilter from './DepartmentFilter';
import UserStateFilter from './UserStateFilter';

const DepartmentListHeader = () => {
  return (
          <Wrapper>
            <FilterWrapper>
              <DepartmentFilter/>
            </FilterWrapper>
            <FilterWrapper>
              <UserStateFilter/>
            </FilterWrapper>
          </Wrapper>
  );
};

const Wrapper         = styled.header`
  margin-bottom: 10px;
  padding-bottom: 6px;
  background: #F7FAFC;
  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  font-family: "Roboto";
  ${fontSize(14)};
  font-weight: 700;

 &::after {
  content: '';
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
  position: absolute;
  left: 0;
  bottom: 0;
 }
`;

const FilterWrapper = styled.div`
  margin-right: 30px;
`;

export default DepartmentListHeader;
