import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalHeader from '../../presentations/ModalHeader';
import ModalBody from '../../presentations/ModalBody';
import {AxiosResponse} from 'axios';
import {UserType} from '../../stores/authenticated';
import Repository from '../../services/repository';
import {useSelector} from 'react-redux';
import {AppState} from '../../stores';
import {map, orderBy} from 'lodash-es';
import TalkingHistoryBody from './TalkingHistoryBody';
import Padding from '../../presentations/Padding';
import Loader from '../Loader';
import {HistoryModalTabType} from '../../stores/history-modal';

export type RoomHistory = {
  id: number,
  responseStatus: boolean
  roomTitle: string
  startsAt: string
  user: UserType
  guests: [UserType]
}

export interface HistoryInterface {
  id: number
  user: UserType
  direction?: 'incoming' | 'outgoing'
  datetime: string
  responseStatus: 0 | 1 | 2
}

interface TalkingHistoryInterface {
  tab?: HistoryModalTabType
}

const TalkingHistory: React.FC<TalkingHistoryInterface> = (props) => {
  const userId                    = useSelector((state: AppState) => state.authenticated.userId as number);
  const [histories, setHistories] = useState<HistoryInterface[] | null>(null);

  useEffect(() => {
    const repository = new Repository();

    Promise.all([
      repository.getIncomingCalls(userId),
      repository.getOutgoingCalls(userId)
    ])
            .then((response: AxiosResponse[]) => {

              const incomingCalls: HistoryInterface[] = map(response[0].data, data => {
                const user      = data.hostUser;
                const direction = 'incoming';
                delete data.guestUser;
                delete data.hostUser;

                const newData: HistoryInterface = {...data, user, direction}
                return newData;
              });

              const outgoingCalls: HistoryInterface[] = map(response[1].data, data => {
                const user      = data.guestUser;
                const direction = 'outgoing';
                delete data.guestUser;
                delete data.hostUser;

                const newData: HistoryInterface = {...data, user, direction}
                return newData;
              });

              let histories = incomingCalls.concat(outgoingCalls);

              histories = orderBy(histories, ['datetime'], ['desc']);
              console.log(histories);

              setHistories(histories);
            })
            .catch((error: any) => {

            });
  }, []);

  return (
          <Wrapper>
            <ModalHeader><h3>履歴</h3></ModalHeader>
            <ModalBody scroll={false}>
              {histories ? <TalkingHistoryBody histories={histories} tab={props.tab}/> : <Loader/>}
            </ModalBody>
          </Wrapper>
  );
}

TalkingHistory.defaultProps = {
  tab: 'ALL'
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default TalkingHistory;
