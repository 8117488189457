import React from 'react';
import Logo from './Logo';
import HeaderMeeting from './HeaderMeeting';
import styled from 'styled-components';
import mediaQuery from 'styled-media-query';
import Authentication from '../services/authentication';
import UserGroup from './UserGroup';
import HeaderTalking from './HeaderTalking';
import HeaderPrivacy from './HeaderPrivacy';
import {useSelector} from 'react-redux';
import {AccessTokenType} from '../stores/authenticated';
import {AppState} from '../stores';

const Header = () => {
  const accessToken    = useSelector((state: AppState) => state.authenticated.accessToken as AccessTokenType);
  const authentication = new Authentication();

  const isAuthenticated = () => {
    return authentication.isValid(accessToken);
  }

  return (
          <Wrapper>
            <Left>
              <Logo width={104}/>
            </Left>
            {isAuthenticated() &&
            <>
              <Center>
                <HeaderMeeting/>
                <HeaderTalking/>
                <HeaderPrivacy/>
              </Center>
              <Right>
                <UserGroup/>
              </Right>
            </>
            }
          </Wrapper>
  );
};

// style
const Wrapper = styled.header`
    padding: 0 14px;
    width: 100%;
    height: 40px;
    background: #262F40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    color: #fff;
`;

const Left = styled.div`
  min-width: 104px;
  ${mediaQuery.lessThan('medium')`
    width: 23px;
    min-width: 23px;
    overflow: hidden;
    img {
      min-width: 104px;
    }
  `}
`;

const Center = styled.div`
    display: flex;
    align-items: center;
`;

const Right = styled.div`
`;

export default Header;
