import styled from 'styled-components';
import mediaQuery from 'styled-media-query';
import {fontSize} from '../../components/utility/Mixin';
import React from 'react';
import Logo from '../Logo';

interface LoginFormLayoutProps {
  children: any;
  title: string;
  onSubmit?: (e: React.FormEvent) => void;
}

const LoginFormLayout = (props: LoginFormLayoutProps) => {
  return (
          <Wrapper>
            <Header>
              <h1><Logo/></h1>
            </Header>

            <Form noValidate method={'post'} onSubmit={props.onSubmit}>
              <FormHeader>{props.title}</FormHeader>
              <FormBody>
                {props.children}
              </FormBody>
            </Form>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${mediaQuery.lessThan('medium')`
    width: 100vw;
  `}
`;

const Header = styled.header`
  margin-bottom: 24px;
`;

const Form = styled.form`
  width: 440px;
  background-color: #fff;
  border: 2px solid #D5D5D5;
  border-radius: 6px;
  
  ${mediaQuery.lessThan('medium')`
    width: 100%;
  `}
`

const FormBody = styled.div`
  padding: 20px 20px 40px;
`;

const FormHeader = styled.div`
  padding: 23px 20px 22px;
  background-color: #F7FAFC;
  border-radius: 6px 6px 0 0;
  color: #262F40;
  ${fontSize(20)};
  font-weight: bold;
`;

export default LoginFormLayout;
