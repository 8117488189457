import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import Button from '../presentations/Button';
import FormErrorText from './Form/FormErrorText';
import AlertMessage, {AlertMessageInterface} from './AlertMessage';
import {FormInput} from './Form/FormInput';
import Spinner2 from './Spinner2';

interface ChangePasswordFormInterface {
  register: any
  errors: any
  message: AlertMessageInterface
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  sending: boolean
}

const ChangePasswordForm: React.FC<ChangePasswordFormInterface> = (props) => {
  return (
          <Wrapper>
            <AlertMessage {...props.message}/>
            <FormField>
              <dt>現在のパスワード</dt>
              <dd>
                {props.errors.oldPassword && <FormErrorText text="必須入力です"/>}
                <FormInput name={'oldPassword'}
                           type={'password'}
                           register={props.register({required: true})}
                           error={props.errors.oldPassword}
                />
              </dd>

              <dt>新しいパスワード</dt>
              <dd>
                {props.errors.newPassword && <FormErrorText text="必須入力です"/>}
                <FormInput name={'newPassword'}
                           type={'password'}
                           register={props.register({required: true})}
                           error={props.errors.newPassword}
                />
                <small>大小英数字を含めた6〜20文字を設定してください</small>
              </dd>

              <dt>新しいパスワード（確認）</dt>
              <dd>
                {props.errors.newPasswordConfirm && <FormErrorText text="必須入力です"/>}
                <FormInput name={'newPasswordConfirm'}
                           type={'password'}
                           register={props.register({required: true})}
                           error={props.errors.newPasswordConfirm}
                />
              </dd>
            </FormField>

            <Action>
              <Button type={'button'} width={'130px'} margin={'0 10px 0 0'} onClick={props.onCancel}>閉じる</Button>
              <Button type={'submit'} color={'secondary'} width={'130px'}>
                {props.sending ? <Spinner2/> : null} 変更
              </Button>
            </Action>
          </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 30px 40px 40px;
`;

const FormField = styled.dl`
  margin-bottom: 30px;

  dt {
    margin-bottom: 10px;
    ${fontSize(12)};
    font-weight: 700;
  }
  
  dd {
    ${fontSize(14)};
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
`;

const Action = styled.div`
  text-align: center;
`;

export default ChangePasswordForm;
