import * as React from 'react';
import {useEffect, useRef} from 'react';
import Modal from '../../presentations/Modal';
import {useSelector} from 'react-redux';
import store, {AppState} from '../../stores';
import ModalHeader from '../../presentations/ModalHeader';
import MyProfileView from './MyProfileView';
import {close} from '../../stores/my-profile-modal';
import {change} from '../../stores/my-profile-switch';
import MyProfileEdit from './MyProfileEdit';
import Padding from '../../presentations/Padding';
import ModalBody from '../../presentations/ModalBody';

const MyProfileModal: React.FC = () => {
  const ref: any = useRef();
  const open     = useSelector((state: AppState) => state.myProfileModal.open);
  const flg      = useSelector((state: AppState) => state.myProfileSwitch.flg);

  useEffect(() => {
    if (open) {
      ref.current.open();
      store.dispatch(change('VIEW'));
    } else {
      ref.current.close();
    }
  }, [open]);

  const handleClose = () => {
    store.dispatch(close());
  }

  const render = () => {
    if (flg === 'VIEW') {
      return <MyProfileView/>;
    } else {
      return <MyProfileEdit/>;
    }
  }

  return (
          <Modal ref={ref} onClose={handleClose}>
            <>
              <ModalHeader><h3>プロフィール</h3></ModalHeader>
              <ModalBody>
                <Padding padding={'30px 40px 40px'}>
                  {render()}
                </Padding>
              </ModalBody>
            </>
          </Modal>
  );
}

export default MyProfileModal;

