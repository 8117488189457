import React, {useRef} from 'react';
import styled from 'styled-components';
import Dropdown, {DropdownMenu, DropdownMenuItem, DropdownTrigger} from './Dropdown';
import {useSelector} from 'react-redux';
import store, {AppState} from '../stores';
import {FilterDepartmentType, select} from '../stores/department-filter';
import {map} from 'lodash-es';

const DepartmentFilter = () => {
  let departments: FilterDepartmentType[] = [{
    id   : 0,
    title: 'All',
  }];
  departments                       = departments.concat(useSelector((state: AppState) => state.departments));
  const selected                    = useSelector((state: AppState) => state.departmentFilter.selected);
  const dropdownRef                 = useRef<any>();

  const handleSelect = (department: FilterDepartmentType) => {
    store.dispatch(select(department))
    dropdownRef.current.close();
  }

  const filterDropdownMenu = map(departments, (department: FilterDepartmentType, index) => {
    const isSelected = department.id === selected.id;

    return (
            <DropdownMenuItem type={'button'} key={index} onClick={() => handleSelect(department)}
                              data-selected={isSelected}>
              {department.title}
            </DropdownMenuItem>
    );
  });

  return (
          <Dropdown ref={dropdownRef}>
            <DropdownTrigger type={'button'}>
              <Icon className={'soi-users'}></Icon>
              {selected?.title}
            </DropdownTrigger>
            <DropdownMenu>
              {filterDropdownMenu}
            </DropdownMenu>
          </Dropdown>
  );
};

const Icon = styled.i`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DepartmentFilter;
