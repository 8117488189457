import React from 'react';
import styled from 'styled-components';
import nl2br from 'react-nl2br';

export interface AlertMessageInterface {
  level?: 'success' | 'error'
  body?: any
}

const AlertMessage: React.FC<AlertMessageInterface> = (props) => {
  return (
          <>
            {props.body ? <Wrapper data-level={props.level}>{nl2br(props.body)}</Wrapper> : null}
          </>
  );
}

const Wrapper = styled.div`
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid #ccc;
  
  &[data-level="error"] {
    background-color: #ffeded;
    border-color: #e9aeae;
  }
`;

export default AlertMessage;
