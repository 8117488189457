import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import {fontSize} from '../components/utility/Mixin';
import {Websocket} from '../services/websocket';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {each} from 'lodash-es';
import {DepartmentType} from '../stores/departments';

interface SystemManagerMenuInterface {
  match?: any
}

const SystemManagerMenu: React.FC<SystemManagerMenuInterface> = (props) => {
  const users       = useSelector((state: AppState) => state.users);
  const departments = useSelector((state: AppState) => state.departments);
  const location    = useLocation();

  useEffect(() => {
    Websocket.socket?.emit('getInfo', {});
  }, []);

  const isUserManagerActive = () => {
    return location.pathname.indexOf('/system-manager/users') > -1;
  }

  const [userManagerOpen, setUserManagerOpen] = useState(isUserManagerActive());

  const isActive = (pathname: string) => {
    return String(location.pathname.indexOf(pathname) > -1);
  }

  const handleToggleUserManager = () => {
    setUserManagerOpen(!userManagerOpen);
  }

  const createUsersNavi = () => {
    let list = [<UsersListItem label={'全てのユーザー'} icon={'soi-user'} count={users.length} key={0}/>];

    each(departments, (department, index) => {
      index++;
      list.push(<UsersListItem label={department.title} icon={'soi-users'}
                               department={department}
                               count={department.users.length}
                               key={index}/>);
    });

    return list;
  }

  return (
          <Wrapper>
            <Navi>
              <ul>
                <ListParent data-open={userManagerOpen}>
                  <Link to={'#'} onClick={handleToggleUserManager}>
                    <Icon className={'soi-user'}/>
                    ユーザー管理
                  </Link>
                  <ul>
                    {createUsersNavi()}
                  </ul>
                </ListParent>
                <li data-is-active={isActive('/system-manager/departments')}>
                  <Link to={'/system-manager/departments'}>
                    <Icon className={'soi-edit'}/>
                    部署を編集
                  </Link>
                </li>
                <li data-is-active={isActive('/system-manager/setting')}>
                  <Link to={'/system-manager/setting'}>
                    <Icon className={'soi-setting'}/>
                    システム設定
                  </Link>
                </li>
              </ul>
            </Navi>
          </Wrapper>
  );
};

const Wrapper = styled.aside`
`;

const Navi = styled.nav`
  li {
    a {
      padding: 1.2em 4em 1.2em 2.1em;
      display: block;
      position: relative;
      border-bottom: 1px solid #E1E2E8;
      color: #262F40;
      text-decoration: none;
      
      &:hover {
        background-color: #E2E5EA;
        font-weight: normal;
      }
    }
    
    li a {
      padding-left: 4.5em;
    }

    &[data-is-active='true'] {
      > a {
        background-color: #4E6693 !important;
        color: #fff;
      }
    }
  }
`;

const ListParent = styled.li`
  > a {
    &::after {
      position: absolute;
      top: 1.2em;
      right: 22px;
      font-family: 'smart-o';
      content: '\\e913';
      //transition: 300ms transform ease-out;
    }
  }

  &[data-open='true'] {
    > a::after {
      transform: rotate(180deg);
    }
  }
    
  &[data-open='false'] {
    > ul {
      display: none;
    }
  }
`

const Icon = styled.i`
  margin-right: 20px;
`;

const Count = styled.span`
  position: absolute;
  top: 1.5em;
  right: 22px;
  ${fontSize(12)}
`;

export default SystemManagerMenu;


interface UsersListItemInterface {
  label: string
  icon: string
  count: number
  department?: DepartmentType
}

const UsersListItem: React.FC<UsersListItemInterface> = (props) => {
  const location = useLocation();

  const createLink = () => {
    let link = `/system-manager/users`;

    if (props.department) {
      link += `/${props.department.id}`
    }

    return link;
  }

  const isActive = (pathname: string) => {
    return String(location.pathname === pathname);
  }

  return (
          <li data-is-active={isActive(createLink())}>
            <Link to={createLink()}>
              <Icon className={props.icon}/>
              {props.label}
              <Count>{props.count}</Count>
            </Link>
          </li>
  );
}
