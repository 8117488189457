import React, {useEffect} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import Home from './pages/Home';
import Authentication from './services/authentication';
import {useSelector} from 'react-redux';
import {AccessTokenType, UserType} from './stores/authenticated';
import {AppInitializer} from './services/app-initializer';
import {AxiosResponse} from 'axios';
import store, {AppState} from './stores';
import {DepartmentType} from './stores/departments';
import {MeetingType} from './stores/meetings';
import {TalkingType} from './stores/talkings';
import {appInitialize} from './stores/app-status';
import {find} from 'lodash-es';
import Logout from './pages/Logout';
import Room from './components/Room/Room';
import Cookies from 'js-cookie';
import ModalChangePassword from './presentations/ModalChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordSended from './pages/ForgotPasswordSended';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordComplete from './pages/ResetPasswordComplete';
import PopupStoreContext from './containers/PopupStoreContext';
import ModalCreateRoomNow from './presentations/ModalCreateRoomNow';
import AdminRoute from './AdminRoute';
import SystemManagerUsers from './pages/SystemManagerUsers';
import SystemManagerDepartments from './pages/SystemManagerDepartments';
import SystemManagerSetting from './pages/SystemManagerSetting';
import {Sound} from './services/sound';
import WebSocket from './WebSocket';
import {SystemType} from './stores/system';
import PageContainer from './presentations/PageContainer';
import MyProfileModal from './components/MyProfile/MyProfileModal';
import MyProfileAvatarSelectModal from './components/MyProfile/MyProfileAvatarSelectModal';
import HistoryModal from './components/History/HistoryModal';
import RoomConnecting from './components/Room/RoomConnecting';

export type InfoType = {
  talkings: TalkingType[];
  meetings: MeetingType[];
  departments: DepartmentType[];
  users: UserType[];
  privacy: any[];
  totalUsers: number;
  presentUsers: number;
  absentUsers: number;
  activeMeetings: number;
  meetingsLimit: number;
  usersLimit: number;
}

const App: React.FC = () => {
  const init           = useSelector((state: AppState) => state.appState.init);
  const accessToken    = useSelector((state: AppState) => state.authenticated.accessToken as AccessTokenType);
  const userId         = useSelector((state: AppState) => state.authenticated.userId as number);
  const authentication = new Authentication();

  const isAuthenticated = () => {
    console.log(accessToken);
    return authentication.isValid(accessToken);
  }

  const initialize = () => {
    console.log('initialize');

    if (isAuthenticated()) {
      AppInitializer.dataLoad()
              .then((response: AxiosResponse<InfoType | SystemType>[]) => {
                const info   = response[0] as AxiosResponse<InfoType>;
                const system = response[1] as AxiosResponse<SystemType>;

                AppInitializer.loggedIn({
                  accessToken,
                  userId,
                  user: find(info.data.users as UserType[], {id: userId}) as UserType,
                  info: info.data,
                  system: system.data
                });
              })
              .catch((error: any) => {
                // TODO: エラーハンドリング
                console.log(error);
                Cookies.remove('accessToken');
                Cookies.remove('userId');
                window.location.href = process.env.REACT_APP_DIR as string;
              });
    } else {
      store.dispatch(appInitialize());
    }

    Sound.init();
  }

  useEffect(initialize, []);

  return (
          <>
            {init ? <WebSocket>
              <>
                <PopupStoreContext>
                  <BrowserRouter basename={process.env.REACT_APP_DIR}>
                    <Switch>
                      <Route path="/login" exact component={Login}/>
                      <Route path="/logout" exact component={Logout}/>
                      <Route path="/forgot-password" exact component={ForgotPassword}/>
                      <Route path="/forgot-password-sended" exact component={ForgotPasswordSended}/>
                      <Route path="/reset-password" exact component={ResetPassword}/>
                      <Route path="/reset-password-complete" exact component={ResetPasswordComplete}/>
                      <Route path="/room" exact component={Room}/>
                      <Route path="/room-connecting" exact component={RoomConnecting}/>
                      <PrivateRoute path="/" exact component={Home}/>
                      <AdminRoute path="/system-manager" exact render={() => <Redirect to={'/system-manager/users'}/>}/>
                      <AdminRoute path="/system-manager/users" exact component={SystemManagerUsers}/>
                      <AdminRoute path="/system-manager/users/:departmentId" exact component={SystemManagerUsers}/>
                      <AdminRoute path="/system-manager/departments" exact component={SystemManagerDepartments}/>
                      <AdminRoute path="/system-manager/setting" exact component={SystemManagerSetting}/>
                    </Switch>
                  </BrowserRouter>
                </PopupStoreContext>
                <MyProfileModal/>
                <MyProfileAvatarSelectModal/>
                <ModalChangePassword/>
                <ModalCreateRoomNow/>
                <HistoryModal/>
              </>
            </WebSocket> : null}
          </>
  );
};

export default App;
