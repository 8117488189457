import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const LoginFormForgotPassword = () => {
  return (
          <Wrapper>
            <Link to={'forgot-password'}>パスワードをお忘れの場合</Link>
          </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 18px;
  text-align: right;
`;

export default LoginFormForgotPassword;
