import React, {useContext} from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import {UserType} from '../stores/authenticated';
import Avatar from './Avatar';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import Calling from '../containers/Calling';
import {PopupContext} from '../containers/PopupStoreContext';

interface UserListItemInterface {
  user: UserType
  isCanCall?: boolean
}

const UserListItem: React.FC<UserListItemInterface> = ({user, isCanCall}) => {
  const userId            = useSelector((state: AppState) => state.authenticated.userId as number);
  const [, setPopupState] = useContext(PopupContext);

  const getStatus = (): string => {
    if (user.privacyStatus) {
      return 'privacy';
    } else if (user.isActive) {
      return 'online';
    } else {
      return 'offline';
    }
  }

  const isMe = () => {
    return user.id === userId;
  }

  const getName = () => {
    return `${user.firstName} ${user.lastName}`;
  }

  const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isCanCall && !isMe()) {
      event.persist();
      // console.log('handleOpenPopup', user);
      // console.log('handleOpenPopup', event, event.pageX, event.pageY);

      const children = <>
        <PopupUser>
          <Avatar user={user} size={30} showStatus={false}/>
          <PopupUsername>{getName()}</PopupUsername>
        </PopupUser>
        <Calling toUser={user}/>
      </>;

      setPopupState(() => {
        return {
          open : true,
          children,
          event: event
        }
      });
    }
  }

  return (
          <Wrapper title={user.username}>
            <User type={'button'} data-status={getStatus()} data-is-me={isMe()} onClick={handleOpenPopup}>
              <Avatar user={user} showStatus={true} isMe={isMe()} size={50}/>
              <Name>
                <div>{user.firstName}</div> <div>{user.lastName}</div>
              </Name>
            </User>
          </Wrapper>
  );
}

UserListItem.defaultProps = {
  isCanCall: false
}

const Wrapper = styled.div`
  position: relative;
`;

const User = styled.button`
  width: 100%;
  text-align: center;
  //&[data-is-me='true'] {
  //  pointer-events: none;
  //}
`;

const Name = styled.div`
  margin-top: 4px;
  padding: .35em .5em .3em;
  width: 100%;
  background-color: #272C41;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  ${fontSize(11)};
  overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  
  > div {
    word-break: break-all;
    display: inline-block;
  }
`;

const PopupUser = styled.div`
  margin-bottom: 10px;
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
  align-items: center;
  ${fontSize(12)};
`;

const PopupUsername = styled.div`
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.3;
`;

export default UserListItem;
