import React from 'react';
import styled from 'styled-components';
import mediaQuery from 'styled-media-query';
import {fontSize} from '../components/utility/Mixin';

type HeaderIndicatorBarStylePropsInterface = {
  styleProps: {
    baseBackgroundColor: string;
    valueBackgroundColor: string;
    baseWidth: string;
    valueWidth: string;
    fontColor: string;
    iconColor: string;
  }
}

interface HeaderIndicatorBarInterface extends HeaderIndicatorBarStylePropsInterface {
  label: string;
}

const HeaderIndicatorBar: React.FC<HeaderIndicatorBarInterface> = props => {
  return (
          <BarGroup styleProps={props.styleProps}>
            <Bar styleProps={props.styleProps}/>
            <Text styleProps={props.styleProps}>{props.label}</Text>
          </BarGroup>
  );
};

const BarGroup = styled.div<HeaderIndicatorBarStylePropsInterface>`
  position: relative;

  ${mediaQuery.greaterThan<HeaderIndicatorBarStylePropsInterface>('medium')`
    padding: 0 10px;
    height: 18px;
    border-radius: 9px;
    width: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.baseWidth};
    background-color: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.baseBackgroundColor};
  `}
`;

const Bar = styled.div<HeaderIndicatorBarStylePropsInterface>`
  display: none;
  ${mediaQuery.greaterThan<HeaderIndicatorBarStylePropsInterface>('medium')`
    border-radius: 9px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    background-color: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.valueBackgroundColor};
    width: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.valueWidth};
  `}
`;

const Text = styled.div<HeaderIndicatorBarStylePropsInterface>`
  position: relative;
  z-index: 2;
  ${fontSize(12)}
  font-family: 'Roboto';
  font-weight: bold;
  line-height: 18px;
  color: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.fontColor};
  ${mediaQuery.lessThan<HeaderIndicatorBarStylePropsInterface>('medium')`
    color: ${(props: HeaderIndicatorBarStylePropsInterface) => props.styleProps.iconColor};
  `}
`;

export default HeaderIndicatorBar;
