import React from 'react';
import styled from 'styled-components';

interface SystemManagerMainLayoutInterface {
  header?: React.ReactElement
  body: React.ReactElement
}

const SystemManagerMainLayout: React.FC<SystemManagerMainLayoutInterface> = (props) => {
  return (
          <Wrapper>
            <Action>
              {props.header}
            </Action>
            <Body>
              {props.body}
            </Body>
          </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Action = styled.div`
  margin-bottom: 20px;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
`;

export default SystemManagerMainLayout
