import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface RoomPasswordModalInterface {
  open: boolean
  participantId: string
  moderatorId: string
}

// Stateの初期状態
const initialState: RoomPasswordModalInterface = {
  open         : false,
  participantId: '',
  moderatorId  : '',
};

// Sliceを生成する
const slice = createSlice({
  name    : 'room-password-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<string[]>) => {
      const [participantId, moderatorId] = action.payload;
      return {...state, open: true, participantId, moderatorId};
    },
    close: (state) => {
      return {...state, open: false, participantId: '', moderatorId: ''};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
