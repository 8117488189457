import styled from 'styled-components';
import React from 'react';

const FullScreenContainer = (props: { children: React.ReactNode, centering: boolean }) => {
  return (<Wrapper data-centering={props.centering}>{props.children}</Wrapper>);
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  
  &[data-centering="true"]{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default FullScreenContainer
