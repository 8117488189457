import React, {useEffect} from 'react';
import {Websocket} from './services/websocket';
import {AppInitializer} from './services/app-initializer';
import {useSelector} from 'react-redux';
import store, {AppState} from './stores';
import {AccessTokenType, updateUser, UserType} from './stores/authenticated';
import {connected, disconnected} from './stores/receiver';
import {InfoType} from './App';
import {find} from 'lodash-es';

interface WebSocketInterface {
  children?: React.ReactElement | null
}

const WebSocket: React.FC<WebSocketInterface> = (props) => {
  const accessToken = useSelector((state: AppState) => state.authenticated.accessToken as AccessTokenType);
  const userId      = useSelector((state: AppState) => state.authenticated.userId as number);

  useEffect(() => {
      const socket = Websocket.connect(accessToken);

      socket.off('connect');
      socket.on('connect', () => {
        console.log('websocket connected');
        socket.emit('getInfo', {});
        store.dispatch(connected());
      });

      socket.off('disconnect');
      socket.on('disconnect', () => {
        console.log('websocket disconnect');
        store.dispatch(disconnected());
      });

      socket.off('getInfo');
      socket.on('getInfo', ({data}: { data: InfoType }) => {
        console.log('websocket getInfo', data);
        AppInitializer.initAppState(data);
        store.dispatch(updateUser(find(data.users, {id: userId}) as UserType));
      });

      socket.off('error');
      socket.on('error', (response: any) => {
        console.log('websocket error', response);
      });
  }, [accessToken, userId]);

  return (
          <>
            {props.children}
          </>
  );
}

export default WebSocket;
