import React, {useState} from 'react';
import LoginFormUsernameField from '../presentations/Login/LoginFormUsernameField';
import LoginFormForgotPassword from '../presentations/Login/LoginFormForgotPassword';
import LoginFormLayout from '../presentations/Login/LoginFormLayout';
import LoginFormPasswordField from '../presentations/Login/LoginFormPasswordField';
import axios, {AxiosResponse} from 'axios';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import FormErrorText from '../presentations/Form/FormErrorText';
import Cookies from 'js-cookie';
import Authentication from '../services/authentication';
import {AppInitializer} from '../services/app-initializer';
import {UserType} from '../stores/authenticated';
import {InfoType} from '../App';
import Button from '../presentations/Button';
import Spinner2 from '../presentations/Spinner2';
import {SystemType} from '../stores/system';
import {useTranslation} from 'react-i18next';

type loginResponse = {
  accessToken: string;
  user: UserType;
}

type loginFormValues = {
  loginId: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const {register, handleSubmit, errors} = useForm<loginFormValues>();
  const [errorMessage, setErrorMessage]  = useState();
  const [sending, setSending]            = useState(false);
  const history                          = useHistory();
  const authentication                   = new Authentication();
  const {t}                              = useTranslation();

  const onSubmit = (post: loginFormValues): void => {
    setSending(true);

    axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`, post)
            .then(({data}: AxiosResponse<loginResponse>) => {
              console.log(data);
              const {user, accessToken} = data;

              const jwtParse = authentication.parseJwt(accessToken);
              const expires  = (jwtParse.exp - Date.now() / 1000) / 60 / 60 / 24;

              const userId = (user.id as number).toString();
              let secure   = true;
              const SameSite = 'Strict';

              if (process.env.NODE_ENV === 'development') {
                secure = false;
              }

              Cookies.set('accessToken', accessToken, {expires, secure, SameSite});
              Cookies.set('userId', userId, {expires, secure, SameSite});

              initialize(accessToken, user);

            })
            .catch(error => {
              // TODO: エラーハンドリング
              console.log(error);
              setSending(false);
              setErrorMessage(t('Wrong email address/username or password'));
            });
  };

  const initialize = (accessToken: string, user: UserType): void => {
    AppInitializer.dataLoad()
            .then((response: AxiosResponse<InfoType | SystemType>[]) => {
              const info   = response[0] as AxiosResponse<InfoType>;
              const system = response[1] as AxiosResponse<SystemType>;

              console.log(info,system);

              AppInitializer.loggedIn({
                accessToken,
                userId: user.id as number,
                user  : user as UserType,
                info  : info.data,
                system: system.data
              });
              history.push('/');

            })
            .catch((error: any) => {
              // TODO: エラーハンドリング
              console.log(error);
              setSending(false);
              setErrorMessage(t('A problem has occurred. Please try again in a few minutes.'));
            });
  }

  return (
          <LoginFormLayout title={t('Login')} onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && <FormErrorText text={errorMessage}/>}
            <LoginFormUsernameField register={register({required: true})} error={errors.loginId}/>
            <LoginFormPasswordField register={register({required: true})} error={errors.password}/>
            <Button type={'submit'} color={'secondary'} width={'100%'} fontSize={14}>
              {sending ? <Spinner2/> : null} {t('Login')}
            </Button>
            <LoginFormForgotPassword/>
          </LoginFormLayout>
  );
};

export default LoginForm;
