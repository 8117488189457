export function fontSize(size: number = 0, base: number = 16) {
  const rem = size / base;
  if (size) {
    return `
        font-size: ${size}px;
        font-size: ${rem}rem;
    `;
  } else {
    return '';
  }
}
