import React from 'react';
import variables from '../themes/default/variables';
import HeaderIndicator from './HeaderIndicator';
import HeaderIndicatorBar from './HeaderIndicatorBar';
import HeaderIndicatorIcon from './HeaderIndicatorIcon';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {filter} from 'lodash-es';

const HeaderPrivacy: React.FC = () => {
  const users = useSelector((state: AppState) => state.users);

  const getIconStyle = () => {
    const color = variables.header.privacy.iconColor;
    return {color};
  }

  const getBarStyle = () => {
    const baseWidth                                                         = 'auto';
    const valueWidth                                                        = '100%';
    const {baseBackgroundColor, fontColor, iconColor, valueBackgroundColor} = variables.header.privacy;

    return {baseWidth, baseBackgroundColor, valueWidth, valueBackgroundColor, fontColor, iconColor};
  }

  const getData = () => {
    return {
      value: filter(users, {privacyStatus: true}).length
    }
  }

  const getLabel = () => {
    const data = getData();
    return String(data.value);
  }

  return (
          <HeaderIndicator>
            <HeaderIndicatorIcon styleProps={getIconStyle()}><i className={'soi-private'}></i></HeaderIndicatorIcon>
            <HeaderIndicatorBar styleProps={getBarStyle()} label={getLabel()}/>
          </HeaderIndicator>
  );
};

export default HeaderPrivacy;
