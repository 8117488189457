import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type HistoryModalTabType = 'ALL' | 'MISSED';

export interface HistoryModalInterface {
  open: boolean
  tab: HistoryModalTabType
}

// Stateの初期状態
const initialState: HistoryModalInterface = {
  open: false,
  tab : 'ALL'
};

// Sliceを生成する
const slice = createSlice({
  name    : 'history-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<HistoryModalTabType>) => {
      return {...state, open: true, tab: action.payload};
    },
    close: (state) => {
      return {...state, open: false, tab: 'ALL'};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
