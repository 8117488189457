import * as React from 'react';
import store, {AppState} from '../../stores';
import {useSelector} from 'react-redux';
import UserProfileLayout from '../../presentations/UserProfileLyaout';
import MyProfileAvatarSelectPreview from './MyProfileAvatarSelectPreview';
import {map} from 'lodash-es';
import styled from 'styled-components';
import {fontSize} from '../utility/Mixin';
import {
  setPreview as setPreviewMyProfileAvatarSelect,
  setSelect as setSelectMyProfileAvatarSelect,
} from '../../stores/my-profile-avatar-select';
import {ChangeEvent} from 'react';
// @ts-ignore
import loadImage from 'blueimp-load-image';
import {UserType} from '../../stores/authenticated';

const MyProfileAvatarSelect: React.FC = () => {
  const user    = useSelector((state: AppState) => state.authenticated.user as UserType);
  const preview    = useSelector((state: AppState) => state.myProfileAvatarSelect.preview);
  const avatarList = ['user_01.png', 'user_02.png', 'user_03.png', 'user_04.png', 'user_05.png', 'user_06.png', 'user_07.png', 'user_08.png', 'user_09.png', 'user_10.png', 'user_11.png', 'user_12.png', 'user_13.png', 'user_14.png', 'user_15.png'];

  const column1 = () => {
    return <PreviewWrapper>
      <MyProfileAvatarSelectPreview avatar={preview} user={user}/>
      <ClearAvatar type={'button'} onClick={handleClearAvatar}><i className={'soi-close'}></i></ClearAvatar>
    </PreviewWrapper>;
  }

  const column2 = () => {
    const avatars = map(avatarList, (avatar, index) => {
      const image = getAvatarPath(avatar);
      return <AvatarItem type={'button'} onClick={() => handleSelectAvatar(image)} key={++index}>
        <img src={image} alt={''}/>
      </AvatarItem>;
    });

    avatars.unshift(<AvatarSelect key={0}>
      アップロード
      <input type="file" onChange={handleInputAvatar}/>
    </AvatarSelect>)

    return <AvatarList>{avatars}</AvatarList>;
  }

  const getAvatarPath = (avatar: string) => {
    return `${process.env.REACT_APP_DIR}assets/avatar/${avatar}`;
  }

  const handleSelectAvatar = (avatar: string) => {
    store.dispatch(setPreviewMyProfileAvatarSelect(avatar));
  }

  const handleInputAvatar = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files?.length) {
      const options = {
        canvas  : true,
        maxWidth: 600,
      };

      loadImage(files[0], options).then((data: any) => {
        store.dispatch(setPreviewMyProfileAvatarSelect(data.image.toDataURL('image/jpeg')));
      }).catch((error: any) => {
        console.log(error);
      });
    }
  }

  const handleClearAvatar = () => {
    store.dispatch(setPreviewMyProfileAvatarSelect(''));
    store.dispatch(setSelectMyProfileAvatarSelect(''));
  }

  return (
          <UserProfileLayout column1={column1()} column2={column2()}/>
  );
}

const AvatarList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 90px);
  grid-row-gap: 20px;
  justify-content: space-between;
`;

const AvatarSelect = styled.label`
  padding-top: 60px;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  background-color: #4E6693;
  position: relative;
  color: #fff;
  text-align: center;
  ${fontSize(12)};
  font-weight: bold;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
  }

  &::before {
    width: 28px;
    height: 2px;
    top: calc(18px + 14px);
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    width: 2px;
    height: 28px;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  input {
    display: none;
  }
`;

const AvatarItem = styled.button`
  border-radius: 6px;
  overflow: hidden;
`;

const PreviewWrapper = styled.div`
  position: relative;
`;


const ClearAvatar = styled.button`
  width: 30px;
  height: 30px;
  background-color: #262F40;
  border: 1px solid #D5D5D5;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export default MyProfileAvatarSelect;

