import React from 'react';
import AlertMessage, {AlertMessageInterface} from './AlertMessage';
import {isArray, map, get} from 'lodash-es';

export interface AlertApiErrorMessageInterface {
  title: React.ReactElement
  response: any
}

const AlertApiErrorMessage: React.FC<AlertApiErrorMessageInterface> = (props) => {
  const message: AlertMessageInterface = {
    level: 'error',
    body : ''
  };

  if (isArray(props.response.data.message)) {
    const errorItems = map(props.response.data.message, (message, index) => {
      let body = get(message, 'constraints.matches');
      if (!body) {
        body = message;
      }
      return <li key={index}>{body}</li>
    });
    message.body     = <>
      {props.title}
      <ul style={{marginTop: 10}}>{errorItems}</ul>
    </>;

  } else {
    message.body = props.response.data.message;
  }

  return (
          <AlertMessage {...message}/>
  );
}

export default AlertApiErrorMessage;
