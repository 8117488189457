import {UserType} from '../stores/authenticated';
import {find} from 'lodash-es';

export default class Authentication {
  parseJwt(jwt: string) {
    let parse = null;
    if (jwt) {
      try {
        const base64Url = jwt.split('.')[1];
        const base64    = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        parse           = JSON.parse(decodeURIComponent(escape(window.atob(base64))))
      } catch (e) {
        console.log(e);
      }
    }

    return parse;
  }

  isValid(jwt: string): boolean {
    let invalid = false;
    const parse = this.parseJwt(jwt);

    if (parse) {
      invalid = !!(parse && Date.now() < parse.exp * 1000);
      console.log(invalid, Date.now(), parse.exp, parse);
    }

    return invalid;
  }

  isAdminValid(jwt: string, user: UserType): boolean {
    return this.isValid(jwt) && (!!find(user.roles, {name: 'admin'}) || !!find(user.roles, {name: 'moderator'}));
  }
}
