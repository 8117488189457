import React, {useEffect} from 'react';
import ModalHeaderSimple from './ModalHeaderSimple';
import Avatar from './Avatar';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import Button from './Button';
import {Websocket} from '../services/websocket';
import store, {AppState} from '../stores';
import {useSelector} from 'react-redux';
import {Sound} from '../services/sound';
import {clearData, close as closeModalReceiveTalkingRequest} from '../stores/modal-receive-talking-request';
import {FormSelect} from './Form/FormSelect';
import {SelectOptionInterface} from '../types/select-option.interface';
import {useForm} from 'react-hook-form';
import {find} from 'lodash-es';

const ReceiveTalkingRequest: React.FC = () => {
  const hostUser              = useSelector((state: AppState) => state.modalReceiveTalkingRequest.data?.hostUser);
  const createRoomDto         = useSelector((state: AppState) => state.modalReceiveTalkingRequest.data?.createRoomDto);
  const callHistory           = useSelector((state: AppState) => state.modalReceiveTalkingRequest.data?.callHistory);
  const {register, getValues} = useForm<{ messageId: string }>({
    defaultValues: {
      messageId: '1',
    }
  });

  const getName = () => {
    return `${hostUser?.firstName} ${hostUser?.lastName}`;
  }

  const handleClose = () => {
    console.log(getValues());

    store.dispatch(clearData());
    store.dispatch(closeModalReceiveTalkingRequest());
    Websocket.socket?.emit('talkingResponse', {
      createRoomDto,
      callHistory,
      talkingRequestAccepted: false,
      rejectMessage         : find(voteMessage, {id: getValues('messageId')})?.name
    });
  }

  const handleTalkingRequestAccepted = () => {
    store.dispatch(clearData());
    store.dispatch(closeModalReceiveTalkingRequest());
    Websocket.socket?.emit('talkingResponse', {
      createRoomDto,
      callHistory,
      talkingRequestAccepted: true
    });
    window.open(`/room-in-connect`, '_room');
  }

  useEffect(() => {
    Sound.incoming.play();

    return () => {
      Sound.incoming.stop();
    }
  }, []);

  const voteMessage: SelectOptionInterface[] = [
    {
      id  : '1',
      name: '応答できません',
    },
    {
      id  : '2',
      name: 'あとでかけ直します',
    },
  ];

  return (
          <>
            <ModalHeaderSimple>ビデオ通話 着信中</ModalHeaderSimple>
            {hostUser ? <>
              <Body>
                <User>
                  <Avatar user={hostUser} size={56}/>
                  <Text>
                    {getName()}さんから話しかけられています。<br/>
                    <Sub>応答すると通話が開始されます。</Sub>
                  </Text>
                </User>
                <Veto>
                  <VetoLabel><i className={'soi-message'}></i> 拒否時に送信されるメッセージ</VetoLabel>
                  <FormSelect name={'messageId'} register={register()} options={voteMessage} width={'auto'}/>
                </Veto>
              </Body>
              <Action>
                <Button type={'button'} color={'danger'} onClick={handleClose}>拒否</Button>
                <Button type={'button'} color={'primary'} icon={'soi-video'} margin={'0 0 0 10px'}
                        onClick={handleTalkingRequestAccepted}>応答する</Button>
              </Action>
            </> : false}
          </>
  )
}

const Body = styled.div`
  padding: 0 20px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin-left: 10px;
  ${fontSize(18)};
  font-weight: 700;
`;

const Sub = styled.small`
  margin-top: 10px;
  display: block;
  ${fontSize(12)};
  font-weight: 400;
`;

const Action = styled.div`
  margin-top: 25px;
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

const Veto = styled.div`
  margin-top: 20px;
  padding-left: 66px;
  max-width: 306px;
  font-size: 12px;
  font-weight: normal;
`;

const VetoLabel = styled.div`
  margin-bottom: 5px;
  color: #EA4949;
`;

export default ReceiveTalkingRequest;
