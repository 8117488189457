import React from 'react';
import styled from 'styled-components';

interface ModalFooterInterface {
  children: React.ReactElement | React.ReactNode
}

const ModalFooterSimple: React.FC<ModalFooterInterface> = ({children}) => {
  return (
          <Wrapper>{children}</Wrapper>
  );
}


export const Wrapper = styled.footer`
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-end;
`;

export default ModalFooterSimple;
