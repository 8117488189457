import * as React from 'react';

const MissedCall: React.FC = () => {
  return (
          <>
            不在着信があります
          </>
  );
}

export default MissedCall;

