import {FormFieldset} from '../Form/FormFieldset';
import {FormInput} from '../Form/FormInput';
import React from 'react';
import FormErrorText from '../Form/FormErrorText';
import {FieldError} from 'react-hook-form';

interface LoginFormPasswordFieldProps {
  register: any;
  error: FieldError | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginFormPasswordField = (props: LoginFormPasswordFieldProps) => {
  return (
          <FormFieldset>
            {props.error && <FormErrorText text="このフィールドを入力してください"/>}
            <FormInput name={'password'}
                       type={'password'}
                       placeholder="パスワード"
                       register={props.register} onChange={props.onChange}
                       error={props.error}
            />
          </FormFieldset>
  );
}

export default LoginFormPasswordField;
