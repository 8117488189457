import React from 'react';
import styled from 'styled-components';

interface SystemManagerRecordActionInterface {
  list: React.ReactElement
}

const SystemManagerRecordAction: React.FC<SystemManagerRecordActionInterface> = (props) => {
  return (
          <Wrapper>{props.list}</Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default SystemManagerRecordAction
