import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface ButtonInterface {
  children: React.ReactNode
  type?: 'button' | 'submit'
  color?: 'primary' | 'secondary' | 'danger'
  width?: string
  margin?: string
  fontSize?: number
  icon?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: React.FC<ButtonInterface> = (props: ButtonInterface) => {
  return (
          <Wrapper type={props.type} data-color={props.color} width={props.width} fontSize={props.fontSize} margin={props.margin} onClick={props.onClick} disabled={props.disabled} data-size={props.fontSize}>
            {props.icon ? <Icon className={props.icon}/> : null}
            {props.children}
          </Wrapper>
  );
}

Button.defaultProps = {
  type: 'button',
  margin: '0',
  disabled: false,
}

const Wrapper = styled.button<{ width?: string, fontSize?: number, margin?: string }>`
  margin: ${(props) => props.margin};
  margin: ${(props) => props?.margin};
  padding: .8em 1.5em;
  min-width: 6.75em;
  max-width: 100%;
  width: ${(props) => props.width};
  background-color: #F9F9FA;
  border-radius: calc(1em + .96em);
  border: 1px solid #D5D5D5;
  display: inline-block;
  align-items: baseline;
  justify-content: center;
  font-size: 1em;
  ${(props) => fontSize(props?.fontSize)};
  text-align: center;
  font-weight: bold;
  
  &:hover {
    background-color: #fff;
  }

  &[data-size='12']{
    padding: .96em 1.5em;
  }
  
  &[data-color='primary']{
    background-color: #1AA872;
    border: none;
    color: #fff;

    &:hover{
      background-color: #32C38C;
    }
    
    &[disabled]{
      pointer-events: none;
      opacity: .3;
    }
  }

  &[data-color='danger']{
    background-color: #CC2A2A;
    border: none;
    color: #fff;

    &:hover{
      background-color: #E54141;
    }
  }

  &[data-color='secondary']{
    background-color: #4E6693;
    border: none;
    color: #fff;

    &:hover{
      background-color: #7890BE;
    }
  }
`;

const Icon = styled.i`
  margin-right: .75em;
  //font-size: .8em;
`;

export default Button;
