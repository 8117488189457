import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';
import Avatar from '../presentations/Avatar';
import {UserType} from '../stores/authenticated';
import UserProfileLayout from '../presentations/UserProfileLyaout';

interface UserProfileViewInterface {
  user: UserType
}

const UserProfileView: React.FC<UserProfileViewInterface> = ({user}) => {
  const column1 = () => {
    return <Avatar user={user} size={120} showStatus={false}/>
  }

  const column2 = () => {
    return <InformationColumn>
      <Information>
        <dt>表示名</dt>
        <dd>{user.firstName}{user.lastName}</dd>

        <dt>部署</dt>
        <dd>{user.department.title}</dd>

        <dt>メールアドレス</dt>
        <dd>{user.email}</dd>

        <dt>電話番号</dt>
        <dd>{user.phone}</dd>
      </Information>
    </InformationColumn>
  }

  return (
          <UserProfileLayout column1={column1()} column2={column2()}/>
  );
}

const InformationColumn = styled.div`
  flex: 1;
`;

const Information = styled.dl`
  margin-bottom: 30px;

  dt {
    margin-bottom: 10px;
    ${fontSize(12)};
    font-weight: 700;
  }
  
  dd {
    ${fontSize(14)};
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
`;

export default UserProfileView;
