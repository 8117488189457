import React, {useState} from 'react';
import LoginFormLayout from '../presentations/Login/LoginFormLayout';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import FormErrorText from '../presentations/Form/FormErrorText';
import {FormFieldset} from '../presentations/Form/FormFieldset';
import {FormInput} from '../presentations/Form/FormInput';
import Button from '../presentations/Button';
import Repository, {ResetPasswordRepositoryInterface} from '../services/repository';
import ForgotPasswordFormReadText from '../presentations/ForgotPasswordReadText';
import {AxiosError, AxiosResponse} from 'axios';
import Spinner2 from '../presentations/Spinner2';
import AlertUnknownErrorMessage from '../components/Alert/AlertUnknownErrorMessage';
import AlertValidationErrorMessage from '../components/Alert/AlertValidationErrorMessage';
import {useTranslation} from 'react-i18next';

const ResetPasswordForm: React.FC = () => {
  const {register, handleSubmit, errors} = useForm<ResetPasswordRepositoryInterface>({
    defaultValues: {
      newPassword       : '',
      newPasswordConfirm: '',
      resetPasswordToken: new URLSearchParams(document.location.search).get('token') as string
    }
  });
  const [alertMessage, setAlertMessage]  = useState<React.ReactElement>();
  const [sending, setSending]            = useState(false);
  const history                          = useHistory();
  const repository                       = new Repository();
  const {t}                              = useTranslation();

  const onSubmit = (post: ResetPasswordRepositoryInterface): void => {
    setSending(true);

    console.log(post);

    repository.resetPassword(post)
            .then(({data}: AxiosResponse<any>) => {
              console.log(data);
              history.push('reset-password-complete');
            })
            .catch((error: AxiosError<any>) => {
              if (error.response?.status === 400 && error.response.data.message.length) {
                setAlertMessage(<AlertValidationErrorMessage message={error.response.data.message}/>);

              } else {
                setAlertMessage(<AlertUnknownErrorMessage/>);

              }

              setSending(false);
            });
  };

  return (
          <LoginFormLayout title={t('Password Reset')} onSubmit={handleSubmit(onSubmit)}>
            <ForgotPasswordFormReadText>
              {t('Reset your password.')}<br/>
              {t('Please enter your new password.')}
            </ForgotPasswordFormReadText>
            {alertMessage}

            <FormInput name={'resetPasswordToken'} type={'hidden'} register={register()}/>

            <FormFieldset>
              {errors.newPassword && <FormErrorText text={t('Please enter this field.')}/>}
              <FormInput name={'newPassword'}
                         type={'password'}
                         placeholder={t('New Password')}
                         register={register({required: true})}
                         error={errors.newPassword}
              />
              <small>{t('Please enter 6-20 characters, including large and small alphanumeric characters.')}</small>
            </FormFieldset>

            <FormFieldset>
              {errors.newPasswordConfirm && <FormErrorText text={t('Please enter this field.')}/>}
              <FormInput name={'newPasswordConfirm'}
                         type={'password'}
                         placeholder={t('New Password (Confirmation)')}
                         register={register({required: true})}
                         error={errors.newPasswordConfirm}
              />
            </FormFieldset>

            <Button type={'submit'} width={'100%'} color={'secondary'} fontSize={14} disabled={sending}>
              {sending ? <Spinner2/> : null} {t('Resetting')}
            </Button>
          </LoginFormLayout>
  );
};

export default ResetPasswordForm;
