import React from 'react';
import variables from '../themes/default/variables';
import HeaderIndicator from './HeaderIndicator';
import HeaderIndicatorBar from './HeaderIndicatorBar';
import HeaderIndicatorIcon from './HeaderIndicatorIcon';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {each} from 'lodash-es';

const HeaderMeeting: React.FC = () => {
  const meetings      = useSelector((state: AppState) => state.meetings);
  const meetingsLimit = useSelector((state: AppState) => state.appState.meetingsLimit);

  const getIconStyle = () => {
    const color = variables.header.meeting.iconColor;
    return {color};
  }

  const getBarStyle = () => {
    const data                                        = getData();
    const baseWidth                                   = '184px';
    const valueWidth                                  = `${data.value / data.max * 100}%`;
    const {baseBackgroundColor, fontColor, iconColor} = variables.header.meeting;

    let valueBackgroundColor = variables.header.meeting.valueBackgroundColor;
    if (data.value === data.max) {
      valueBackgroundColor = variables.header.meeting.maxValueBackgroundColor;
    }

    return {baseWidth, baseBackgroundColor, valueWidth, valueBackgroundColor, fontColor, iconColor};
  }

  const getData = () => {
    return {
      max  : countMaxMember(),
      value: countMember(),
    }
  }

  const countMaxMember = () => {
    return meetingsLimit;
  }

  const countMember = () => {
    let count = 0;
    each(meetings, meeting => {
      count += meeting.users.length;
    });
    return count;
  }

  const getLabel = () => {
    const data = getData();
    return `${data.value}/${data.max}`;
  }

  return (
          <HeaderIndicator>
            <HeaderIndicatorIcon styleProps={getIconStyle()}><i className={'soi-meeting'}></i></HeaderIndicatorIcon>
            <HeaderIndicatorBar styleProps={getBarStyle()} label={getLabel()}/>
          </HeaderIndicator>
  );
};

export default HeaderMeeting;
