import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export interface SendingTalkingRequestInterface {
  createRoomDto: { guestUserId: number, hostUserId: number }
  hostUser: UserType
  guestUser: UserType
  callHistory: any
}

export interface SendingTalkingRequestModalInterface {
  open: boolean
  data: SendingTalkingRequestInterface | null
}

// Stateの初期状態
const initialState: SendingTalkingRequestModalInterface = {
  open: false,
  data: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'sending-talking-request-modal',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<SendingTalkingRequestInterface>) => {
      return {...state, open: true, data: action.payload};
    },
    close: (state) => {
      return {...state, open: false, data: null};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
