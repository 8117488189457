import React from 'react';
import styled from 'styled-components';
import {fontSize} from '../components/utility/Mixin';

interface ModalHeaderInterface {
  children: React.ReactElement | React.ReactNode
}

const ModalHeader: React.FC<ModalHeaderInterface> = ({children}) => {
  return (
          <Wrapper>{children}</Wrapper>
  );
}


export const Wrapper = styled.header`
  padding: 25px 20px 20px;
  background-color: #F7FAFC;
  border-bottom: 1px solid #D5D5D5;
  ${fontSize(20)};
  font-weight: 700;
`;

export default ModalHeader;
