import React from 'react';
import styled from 'styled-components';

interface UserProfileLayoutInterface {
  column1?: React.ReactElement
  column2: React.ReactElement
}

const UserProfileLayout: React.FC<UserProfileLayoutInterface> = (props) => {
  return (
          <Columns>
            {props.column1 ? <Column1>{props.column1}</Column1> : null}
            <Column2>{props.column2}</Column2>
          </Columns>
  );
}

const Columns = styled.div`
  display: flex;
`;

const Column1 = styled.div`
  margin-right: 40px;
`;

const Column2 = styled.div`
  flex: 1;
`;

export default UserProfileLayout;
