import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserType} from './authenticated';

export interface ModalConfirmUserProfileRemoveInterface {
  open: boolean
  user: UserType | null
}

// Stateの初期状態
const initialState: ModalConfirmUserProfileRemoveInterface = {
  open: false,
  user: null
};

// Sliceを生成する
const slice = createSlice({
  name    : 'modal-confirm-user-profile-remove',
  initialState,
  reducers: {
    open : (state, action: PayloadAction<UserType>) => {
      return {...state, open: true, user: action.payload};
    },
    close: (state) => {
      return {...state, open: false};
    },
  }
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const {open, close} = slice.actions;
