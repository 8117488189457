import React from 'react';
import styled from 'styled-components';
import UserList from './UserList';
import {useSelector} from 'react-redux';
import {AppState} from '../stores';
import {cloneDeep, filter, remove} from 'lodash-es';
import {UserType} from '../stores/authenticated';

const DepartmentList: React.FC = () => {
  const departmentFilter = useSelector((state: AppState) => state.departmentFilter.selected);
  const userStatusFilter = useSelector((state: AppState) => state.userStatusFilter.selected);
  const userId           = useSelector((state: AppState) => state.authenticated.userId as number);
  let users              = cloneDeep(useSelector((state: AppState) => state.users));

  const me = remove(users, {id: userId}) as UserType[];
  users = me.concat(users);

  users = filter(users, user => {
    return !user.room;
  });

  if (departmentFilter.id) {
    // console.log(departmentFilter);
    users = filter(users, user => {
      return Number(user.department.id) === Number(departmentFilter.id);
    });
  }

  if (userStatusFilter.id !== 'all') {
    // console.log(userStatusFilter);
    users = filter(users, user => {
      if (userStatusFilter.id === 'login' && user.isActive) {
        return true;
      } else if (userStatusFilter.id === 'logout' && !user.isActive) {
        return true;
      } else if (userStatusFilter.id === 'privacy' && user.privacyStatus) {
        return true;
      } else {
        return false;
      }
    });
  }

  return (
          <Wrapper>
            <UserList users={users} isCanCall={true}/>
          </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 10px;
  overflow-y: auto;
  flex: 1;
`;

export default DepartmentList;
