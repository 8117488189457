import React from 'react';
import styled from 'styled-components';
import {map} from 'lodash-es';
import {UserType} from '../stores/authenticated';
import UserListItem from './UserListItem';

interface UserListInterface {
  users: UserType[]
  isCanCall?: boolean
}

const UserList: React.FC<UserListInterface> = ({users, isCanCall}) => {
  const list = map(users, (user, index) => {
    return (
            <li key={index}>
              <UserListItem user={user} isCanCall={isCanCall}/>
            </li>
    )
  });

  return (
          <Wrapper>
            {list}
          </Wrapper>
  );
}

UserList.defaultProps = {
  isCanCall: false
}

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  column-gap: 10px;
  row-gap: 20px;
  justify-content: center;
`;

export default UserList;
